import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  groupsCleanUp,
  logoutUser,
  preferencesCleanUp,
  wavesCleanUp,
  tagsCleanUp,
  organizationsCleanUp,
  reportsCleanUp,
} from '../../store/actions';

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preferencesCleanUp());
    dispatch(wavesCleanUp());
    dispatch(organizationsCleanUp());
    dispatch(groupsCleanUp());
    dispatch(tagsCleanUp());
    dispatch(reportsCleanUp());
    dispatch(logoutUser(props.history));
  });

  return <></>;
};

export default withRouter(Logout);
