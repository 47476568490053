import { createReducer } from 'redux-act';

import {
  GROUPS_CLEAN_UP,
  GROUPS_FETCH_FAIL,
  GROUPS_FETCH_INIT,
  GROUPS_FETCH_SUCCESS,
} from '../../actions/groups';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const groupsReducer = createReducer(
  {
    [GROUPS_FETCH_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GROUPS_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.groups,
      loading: false,
      error: null,
    }),
    [GROUPS_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [GROUPS_CLEAN_UP]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default groupsReducer;
