import { createAction } from 'redux-act';

import Collections from '../../../helpers/enums/collections';
import { collection } from '../../../helpers/utils/firebase';

export const DASHBOARDS_FETCH_INIT = createAction('DASHBOARDS_FETCH_INIT');
export const DASHBOARDS_FETCH_SUCCESS = createAction(
  'DASHBOARDS_FETCH_SUCCESS'
);
export const DASHBOARDS_FETCH_FAIL = createAction('DASHBOARDS_FETCH_FAIL');
export const DASHBOARDS_CLEAR_STATE = createAction('DASHBOARDS_CLEAR_STATE');

export const fetchDashboards = (wavesId) => {
  return async (dispatch) => {
    dispatch(DASHBOARDS_FETCH_INIT());
    const dashboards = [];

    try {
      const queryResult = await collection(Collections.DASHBOARD)
        .where('waveId', 'in', wavesId)
        .get();

      queryResult.forEach((dashboardDoc) => {
        const dashboardData = dashboardDoc.data();

        const dashboard = {
          id: dashboardDoc.id,
          ...dashboardData,
        };

        dashboards.push(dashboard);
      });
    } catch (error) {
      return dispatch(DASHBOARDS_FETCH_FAIL({ error }));
    }

    return dispatch(DASHBOARDS_FETCH_SUCCESS({ dashboards }));
  };
};

export const dashboardsClearState = () => (dispatch) =>
  dispatch(DASHBOARDS_CLEAR_STATE());
