import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Row, Col, BreadcrumbItem, Badge, Spinner } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import DownloadChartDropdown from '../DownloadChartDropdown';
import { DownloadOptions, getDownloadChartFileName } from '../../helpers/utils';
import classNames from 'classnames';
import classes from './Breadcrumb.module.scss';

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
};

const Breadcrumb = ({
  t,
  title,
  componentRef,
  displayGroupSelected = true,
}) => {
  const { wave, group, loadingWaves, demographic } = useSelector(
    (state) => ({
      wave: state.preferences.wave,
      group: state.preferences.group,
      demographic: state.preferences.demographic,
      loadingWaves: state.waves.loading,
    }),
    shallowEqual
  );

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('breadcrumb.dashboard'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName, componentRef]
  );

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex flex-column flex-sm-row align-items-center justify-content-between font-size-20">
          <div className="d-flex flex-column flex-sm-row align-items-center mb-1 mb-sm-0">
            <h4 className="mb-2 mb-sm-0 mr-sm-3 font-size-18">{title}</h4>
            {loadingWaves ? (
              <Spinner
                className={classNames('mr-2 font-size-12', classes.spinner)}
                color="success"
              />
            ) : (
              <Badge color="success" className="font-size-15">
                {wave && (
                  <>
                    <span className="font-weight-bold">
                      {wave.name || `${t('breadcrumb.wave')} ${wave.index}`}
                    </span>
                    <span className="font-weight-lighter">{` ${t(
                      'breadcrumb.from'
                    )} ${t('formattedDate', {
                      date: new Date(wave.startingOn),
                    })}
              `}</span>
                  </>
                )}
              </Badge>
            )}
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <span data-tip="wave">
                  {loadingWaves ? (
                    <Spinner
                      className={classNames(
                        'mr-2 font-size-12',
                        classes.spinner
                      )}
                      color="success"
                    />
                  ) : (
                    <Badge color="success" className="font-size-15">
                      {wave && `${t('breadcrumb.wave')} ${wave.index}`}
                    </Badge>
                  )}
                </span>
              </BreadcrumbItem>
              {displayGroupSelected && (
                <BreadcrumbItem>
                  <span data-tip="group">
                    {loadingWaves ? (
                      <Spinner
                        className={classNames(
                          'mr-2 font-size-12',
                          classes.spinner
                        )}
                        color="primary"
                      />
                    ) : (
                      <Badge color="primary" className="font-size-15">
                        {group ? group.displayName : t('breadcrumb.overall')}
                      </Badge>
                    )}
                  </span>
                </BreadcrumbItem>
              )}
              {demographic?.tagId && displayGroupSelected && (
                <>
                  <BreadcrumbItem>
                    <span data-tip="demographic">
                      {loadingWaves ? (
                        <Spinner
                          className={classNames(
                            'mr-2 font-size-12',
                            classes.spinner
                          )}
                          color="primary"
                        />
                      ) : (
                        <Badge color="primary" className="font-size-15">
                          {demographic.tagName}
                        </Badge>
                      )}
                    </span>
                    <ReactTooltip />
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <span data-tip="choice">
                      {loadingWaves ? (
                        <Spinner
                          className={classNames(
                            'mr-2 font-size-12',
                            classes.spinner
                          )}
                          color="primary"
                        />
                      ) : (
                        <Badge color="primary" className="font-size-15">
                          {demographic.choice}
                        </Badge>
                      )}
                    </span>
                    <ReactTooltip />
                  </BreadcrumbItem>
                </>
              )}
              <DownloadChartDropdown
                t={t}
                PNGData={PNGData}
                optionsEnabled={optionsEnabled}
              />
            </ol>
          </div>
        </div>
      </Col>
      <ReactTooltip />
    </Row>
  );
};

export default withNamespaces()(Breadcrumb);
