import { createAction } from 'redux-act';
import { getDaysBetweenDates } from '../../../helpers/functions/functions_helper';

export const REPORTS_FETCH_INIT = createAction('REPORTS_FETCH_INIT');
export const REPORTS_FETCH_FAIL = createAction('REPORTS_FETCH_FAIL');
export const REPORTS_FETCH_SUCCESS = createAction('REPORTS_FETCH_SUCCESS');
export const REPORTS_CLEAN_UP = createAction('REPORTS_CLEAN_UP');

export const fetchReports = (organization, wave, group) => {
  return async (dispatch) => {
    dispatch(REPORTS_FETCH_INIT());

    const getRandomInt = (min, max) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    const totalDays = getDaysBetweenDates(
      wave.startingOn,
      wave.until || new Date()
    );

    let reports = {
      hits: [],
      responses: [],
      invalid: [],
    };

    try {
      for (let index = 0; index <= totalDays; index++) {
        reports.responses.push(getRandomInt(0, 120));
        reports.hits.push(getRandomInt(0, 50));
        reports.invalid.push(getRandomInt(0, 20));
      }
    } catch (error) {
      return dispatch(REPORTS_FETCH_FAIL({ error }));
    }

    return dispatch(
      REPORTS_FETCH_SUCCESS({
        reports,
        totalResponses: getRandomInt(100, 400),
      })
    );
  };
};

export const reportsCleanUp = () => (dispatch) => dispatch(REPORTS_CLEAN_UP());
