import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import parse from 'html-react-parser';

import glossary from './newContent.json';

const Glossary = (props) => {
  const [content, setContent] = useState({ left: [], right: [] });

  useEffect(() => {
    const right = [];
    const left = [];

    for (let index = 0; index < glossary.length; index++) {
      const element = glossary[index];

      index % 2 ? right.push(element) : left.push(element);

      setContent({ right, left });
    }
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col className="font-size-24 font-weight-bold mb-2">
            {props.t('glossary.title')}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="font-italic">
            Presage assesses risk associated with human error in the workplace
            by analyzing and monitoring nine proprietary psychosocial
            constructs. The following defines the behaviours (awareness) that we
            assess, each representing a different but significant part of the
            employee maintaining safe behaviour while working.
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            {content.left.map((element, key) => (
              <div key={key}>
                <Row>
                  <Col className="font-size-18 font-weight-bold mb-2">
                    {parse(element.title)}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-16">
                    {parse(element.ranges.low)}
                  </Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.medium)}</Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.high)}</Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.veryHigh)}</Col>
                </Row>
              </div>
            ))}
          </Col>
          <Col xl="6">
            {content.right.map((element, key) => (
              <div key={key}>
                <Row>
                  <Col className="font-size-18 font-weight-bold mb-2">
                    {parse(element.title)}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-16">
                    {parse(element.ranges.low)}
                  </Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.medium)}</Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.high)}</Col>
                </Row>
                <Row>
                  <Col>{parse(element.ranges.veryHigh)}</Col>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withNamespaces()(Glossary);
