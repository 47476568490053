import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';

import noData from '../../../assets/images/noData.png';
import Popovers from '../../../helpers/enums/popovers';
import ChartTypes from '../../../helpers/enums/chartTypes';
import {
  DownloadOptions,
  getDownloadChartFileName,
  getResumedNamesFromDashboardGroup,
  isFloat,
} from '../../../helpers/utils';
import ChartNames from '../../../helpers/enums/chartNames';
import Popover from '../../../components/Popover';
import ApexChart from '../../../components/ApexChart';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import classes from './SafetyAwareness.module.scss';

const initialChartOptions = {
  responsive: [
    {
      breakpoint: 1450,
      options: {
        chart: {
          height: '200',
        },
      },
    },
    {
      breakpoint: 8000,
      options: {
        chart: {
          height: '250',
        },
      },
    },
  ],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: '55%',
      },
      dataLabels: {
        showOn: 'always',
        value: {
          offsetY: 0,
          color: '#111',
          fontSize: '40px',
          show: true,
          formatter: (val) => val,
        },
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#34c38f'],
  },
  stroke: {
    lineCap: 'round',
    color: '#111',
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  labels: [''],
};

const chartOptionsEmpty = {
  ...initialChartOptions,
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: '55%',
        image: noData,
        imageWidth: 64,
        imageHeight: 64,
        imageClipped: false,
        imageOffsetY: -12,
      },
      dataLabels: {
        value: {
          offsetY: 22,
          color: '#111',
          fontSize: '18px',
          show: true,
        },
      },
    },
  },
};

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const SafetyAwarenessChart = forwardRef(
  ({ safetyAwareness, chartOptions, t }, ref) => (
    <div className="text-center mt-3" ref={ref}>
      {safetyAwareness !== null && (
        <ApexChart
          series={[safetyAwareness]}
          type={ChartTypes[ChartNames.RADIAL]}
          options={chartOptions}
        />
      )}
      {safetyAwareness === null && (
        <ApexChart
          series={['no data']}
          type={ChartTypes[ChartNames.RADIAL]}
          options={chartOptionsEmpty}
        />
      )}
      <h5 className="font-size-12 mb-3">
        {t('safetyAwarenessGraph.presageIndex')}
      </h5>
    </div>
  )
);

const SafetyAwareness = ({ t, wave }) => {
  const { safetyAwareness } = useSelector((state) => {
    const dashboard =
      wave && state.dashboards.data.find(({ waveId }) => waveId === wave.id);
    const group = state.preferences.group;
    const selectedDemographic = state.preferences.demographic;
    const groupId = group && group.id;

    let safetyAwareness = 0;

    if (dashboard) {
      const { Scores } = dashboard || {
        Scores: { Overall: { groups: [], demographics: {} } },
      };

      const {
        Overall: overall,
        Overall: { groups },
      } = Scores || { Overall: { groups: [], demographics: {} } };

      if (group) {
        const groupData = groups?.[groupId];

        if (groupData) {
          const groupSelectedDemographic =
            groupData?.demographics?.[selectedDemographic?.choice];

          const { PresageIndex: SafetyAwareness } = groupSelectedDemographic
            ? getResumedNamesFromDashboardGroup(groupSelectedDemographic)
            : getResumedNamesFromDashboardGroup(groupData);

          safetyAwareness = SafetyAwareness;
        }
      } else {
        const overallSelectedDemographic =
          overall?.demographics?.[selectedDemographic?.choice];

        const { PresageIndex: SafetyAwareness } = overallSelectedDemographic
          ? getResumedNamesFromDashboardGroup(overallSelectedDemographic)
          : getResumedNamesFromDashboardGroup(overall);

        safetyAwareness = SafetyAwareness;
      }
    }

    return {
      safetyAwareness,
    };
  }, shallowEqual);

  const [chartOptions, setChartOptions] = useState(initialChartOptions);

  useEffect(() => {
    if (isFloat(safetyAwareness)) {
      setChartOptions((prevState) => ({
        ...prevState,
        plotOptions: {
          ...prevState.plotOptions,
          radialBar: {
            ...prevState.plotOptions.radialBar,
            dataLabels: {
              ...prevState.plotOptions.radialBar.dataLabels,
              value: {
                ...prevState.plotOptions.radialBar.dataLabels.value,
                fontSize: '33px',
              },
            },
          },
        },
      }));
    }
  }, [safetyAwareness]);

  const componentRef = useRef();

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('safetyAwarenessGraph.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        { label: t('safetyAwarenessGraph.title'), key: 'safetyAwareness' },
      ],
      data: [
        {
          safetyAwareness,
        },
      ],
      fileName: `${downloadChartFileName}.csv`,
    }),
    [t, downloadChartFileName, safetyAwareness]
  );

  const canDownloadChart = useMemo(() => {
    const canDownload = safetyAwareness > 0;

    return canDownload;
  }, [safetyAwareness]);

  return (
    <Card className={(classnames('mini-stats-wid'), classes['card-height'])}>
      <CardBody>
        <div
          id={Popovers.SAFETYAWARENESS}
          className={classnames('d-flex flex-column', classes['cursor-help'])}
        >
          <span className={classes.title}>
            {t('safetyAwarenessGraph.title')}
          </span>
          <span
            className={classnames(
              'font-size-12 mb-xl-5',
              classes['opacity-75']
            )}
          >
            {t('safetyAwarenessGraph.subTitle')}
          </span>
        </div>
        <SafetyAwarenessChart
          safetyAwareness={safetyAwareness}
          chartOptions={chartOptions}
          t={t}
          ref={componentRef}
        />
        <div className={classes['download-icon']}>
          <DownloadChartDropdown
            t={t}
            canDownloadChart={canDownloadChart}
            PNGData={PNGData}
            CSVData={CSVData}
            optionsEnabled={optionsEnabled}
          />
        </div>
        <Popover
          target={Popovers.SAFETYAWARENESS}
          header={t('popoverSafetyAwareness.Header')}
          body={t('popoverSafetyAwareness.Body')}
        />
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(SafetyAwareness);
