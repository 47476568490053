import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const DEMOGRAPHICS_FETCH_INIT = createAction('DEMOGRAPHICS_FETCH_INIT');
export const DEMOGRAPHICS_FETCH_SUCCESS = createAction(
  'DEMOGRAPHICS_FETCH_SUCCESS'
);
export const DEMOGRAPHICS_FETCH_FAIL = createAction('DEMOGRAPHICS_FETCH_FAIL');
export const DEMOGRAPHICS_CLEAR_STATE = createAction(
  'DEMOGRAPHICS_CLEAR_STATE'
);

export const fetchDemographics = (wavesId = null) => {
  return async (dispatch) => {
    dispatch(DEMOGRAPHICS_FETCH_INIT());

    const demographics = [];

    try {
      let baseQuery = firebase.firestore().collection('demographics');

      if (wavesId) {
        baseQuery = baseQuery.where('waveId', 'in', wavesId);
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((demographicDoc) => {
        const demographicData = demographicDoc.data();

        const demographic = {
          id: demographicDoc.id,
          ...demographicData,
        };

        demographics.push(demographic);
      });
    } catch (error) {
      return dispatch(DEMOGRAPHICS_FETCH_FAIL({ error }));
    }

    return dispatch(DEMOGRAPHICS_FETCH_SUCCESS({ demographics }));
  };
};

export const demographicsClearState = () => (dispatch) =>
  dispatch(DEMOGRAPHICS_CLEAR_STATE());
