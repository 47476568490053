import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';

import translationGR from './locales/gr/translation.json';
import translationIT from './locales/it/translation.json';
import translationES from './locales/es/translation.json';
import translationEN from './locales/en/translation.json';
import translationKO from './locales/ko/translation.json';
import translationPT from './locales/pt/translation.json';
import translationZH from './locales/zh/translation.json';
import translationFR from './locales/fr/translation.json';
import translationYUE from './locales/yue/translation.json';

// the translations
const resources = {
  gr: {
    translation: translationGR,
  },
  it: {
    translation: translationIT,
  },
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
  pt: {
    translation: translationPT,
  },
  zh: {
    translation: translationZH,
  },
  fr: {
    translation: translationFR,
  },
  yue: {
    translation: translationYUE,
  },
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format(value, format) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
