import ChartNames from './chartNames';

const categories = [
  'Instruments and Equipment',
  'Knowing Procedures',
  'Knowing Limits',
  'Relying on Experience',
  'Gut Feeling',
  'Seeing Threats',
  'Adjusting to Threats',
  'Keeping Eachother Safe',
  'Company Support',
];

const categoriesMobile = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

const initialRadarOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radar: {
      offsetY: -80,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
    categories,
  },
  yaxis: {
    labels: { formatter: (value) => (value === null ? 'N/A' : value) },
  },
  colors: ['#556ee6'],
  responsive: [
    {
      breakpoint: 321,
      options: {
        plotOptions: {
          radar: {
            offsetY: -220,
          },
        },
      },
    },
    {
      breakpoint: 415,
      options: {
        plotOptions: {
          radar: {
            offsetY: -195,
          },
        },
      },
    },
    {
      breakpoint: 541,
      options: {
        plotOptions: {
          radar: {
            offsetY: -130,
          },
        },
        xaxis: {
          categories: categoriesMobile,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
      },
    },
  ],
});

const initialBarOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  colors: ['#556ee6'],
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories,
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 165,
      maxWidth: 165,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
});

const initialColumnOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
    },
  },
  colors: ['#556ee6'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
  },
  grid: {
    row: {
      colors: ['#fff', '#f2f2f2'],
    },
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 80,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
  xaxis: {
    labels: {
      rotate: -45,
      show: true,
      hideOverlappingLabels: false,
      rotateAlways: true,
      trim: false,
      minHeight: 150,
    },
    categories,
  },
  responsive: [
    {
      breakpoint: 540,
      options: {
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: false,
            rotateAlways: true,
            trim: false,
            minHeight: 180,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
      },
    },
  ],
});

const initialLineOptions = Object.freeze({
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  colors: ['#556ee6'],
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -45,
      hideOverlappingLabels: false,
      rotateAlways: true,
      trim: false,
      minHeight: 150,
    },
    categories,
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 110,
      maxWidth: 110,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
  responsive: [
    {
      breakpoint: 540,
      options: {
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: false,
            rotateAlways: true,
            trim: false,
            minHeight: 180,
          },
        },
      },
    },
  ],
});

const initialHeatMapOptions = Object.freeze({
  responsive: [
    {
      breakpoint: 380,
      options: {
        xaxis: {
          labels: {
            show: false,
            minHeight: 0,
            rotate: -45,
            rotateAlways: true,
          },
        },
        yaxis: {
          labels: {
            show: false,
            minHeight: 0,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
        legend: {
          markers: {
            width: 10,
            height: 10,
            radius: 2,
          },
        },
      },
    },
    {
      breakpoint: 768,
      options: {
        xaxis: {
          labels: {
            show: false,
            minHeight: 0,
            rotate: -45,
            rotateAlways: true,
          },
        },
        yaxis: {
          labels: {
            show: false,
            minHeight: 0,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
      },
    },
    {
      breakpoint: 1024,
      options: {
        xaxis: {
          labels: {
            show: true,
            minHeight: 200,
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
            minWidth: 200,
            maxWidth: 200,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
        legend: {
          markers: {
            width: 20,
            height: 10,
            radius: 2,
          },
        },
      },
    },
  ],
  xaxis: {
    labels: {
      show: true,
      style: {
        fontSize: '14px',
      },
      minHeight: 200,
      rotate: -45,
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      show: true,
      style: {
        fontSize: '14px',
      },
      minWidth: 250,
      maxWidth: 250,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
  legend: {
    markers: {
      width: 40,
      height: 20,
      radius: 2,
    },
    showForSingleSeries: true,
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '20px',
    },
    showDetails: false,
    formatter: (value, { w }) => {
      const showDetails = w?.config?.dataLabels?.showDetails;

      let finalValue = value === null ? 'N/A' : value;

      if (!showDetails && value !== null) {
        finalValue = undefined;
      }

      return finalValue;
    },
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 25,
            color: '#d0e1f1',
            name: '0-24',
          },
          {
            from: 25,
            to: 50,
            color: '#85b1d7',
            name: '25-49',
          },
          {
            from: 50,
            to: 75,
            color: '#4f7eaf',
            foreColor: '#fff',
            name: '50-74',
          },
          {
            from: 75,
            to: 100,
            color: '#23558b',
            foreColor: '#fff',
            name: '75-100',
          },
        ],
      },
    },
  },
});

const initialChartsOptions = Object.freeze({
  [ChartNames.RADAR]: initialRadarOptions,
  [ChartNames.BAR]: initialBarOptions,
  [ChartNames.COLUMN]: initialColumnOptions,
  [ChartNames.LINE]: initialLineOptions,
  [ChartNames.HEATMAP]: initialHeatMapOptions,
});

export default initialChartsOptions;
