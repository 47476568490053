import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Col } from 'reactstrap';

import MiniStatReport from './MiniStatReport';
import Popovers from '../../../helpers/enums/popovers';
import {
  dateBetweenPeriod,
  getChildrenGroupIds,
  getDaysBetweenDates,
  getGroupFromHierarchy,
} from '../../../helpers/functions/functions_helper';
import { setOrganization } from '../../../store/actions';

const Reports = ({ t, wave }) => {
  const {
    organization,
    organizations,
    processedResponses,
    totalResponses,
    expectedResponses,
  } = useSelector(
    ({
      preferences,
      responses,
      surveys,
      organizations: organizationsState,
    }) => {
      const group = preferences.group;
      const organization = preferences.organization;
      const organizations = organizationsState.data;
      const selectedWaveDates = preferences.datesFromWave;

      let totalResponses = responses.data;

      const totalResponsesLength = totalResponses.length;

      const processedResponses = surveys.data.reduce(
        (total, deployment) =>
          total + (deployment.totalResponses - deployment.unprocessedResponses),
        0
      );

      const expectedResponses = group
        ? group.expectedCount || '∞'
        : wave?.expectedResponses || '∞';

      if (group && wave) {
        const groupInHierarchy = getGroupFromHierarchy(wave.hierarchy, group);

        const groupIds = getChildrenGroupIds(groupInHierarchy);

        totalResponses = totalResponses.filter((response) =>
          response.groups.some((groupId) => groupIds.includes(groupId))
        );
      }

      if (selectedWaveDates) {
        const { startDate, endDate } = selectedWaveDates;
        totalResponses = totalResponses.filter(({ createdAt }) =>
          dateBetweenPeriod(createdAt, startDate, endDate)
        );
      }

      const fixedProcessedResponses =
        processedResponses > totalResponsesLength
          ? totalResponsesLength
          : processedResponses;

      return {
        group,
        organization,
        organizations,
        expectedResponses,
        totalResponses: totalResponsesLength,
        processedResponses: fixedProcessedResponses,
      };
    },
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organizations.length > 0 && organization) {
      const updatedOrganization = organizations.find(
        ({ id }) => id === organization.id
      );
      dispatch(setOrganization(updatedOrganization));
    }
  }, [dispatch, organizations, organization]);

  const getResponsesPerDay = useCallback(() => {
    let responsesPerDay = '-';

    if (wave) {
      const today = new Date();
      let diffDays = 0;
      const { startingOn, until } = wave;

      if (!until || until > today) {
        diffDays = getDaysBetweenDates(new Date(startingOn), today);
      } else {
        diffDays = getDaysBetweenDates(new Date(startingOn), new Date(until));
      }

      responsesPerDay =
        diffDays !== 0
          ? parseFloat(totalResponses / diffDays).toFixed(1)
          : totalResponses;
    }

    return responsesPerDay;
  }, [wave, totalResponses]);

  const reports = useMemo(
    () => [
      {
        title: t('dashboard.totalResponses'),
        iconClass: 'bx-copy-alt',
        description: totalResponses,
        popoverTarget: Popovers.TOTALRESPONSES,
        popoverHeader: t('popoverTotalResponses.Header'),
        popoverBody: t('popoverTotalResponses.Body'),
      },
      {
        title: t('dashboard.expectedResponses'),
        iconClass: 'bx-copy-alt',
        description: expectedResponses,
        popoverTarget: Popovers.EXPECTEDRESPONSES,
        popoverHeader: t('popoverExpectedResponses.Header'),
        popoverBody: t('popoverExpectedResponses.Body'),
      },
      {
        title: t('dashboard.processingThreshold'),
        iconClass: 'bx-purchase-tag-alt',
        description: organization?.processingThreshold,
        popoverTarget: Popovers.PROCESSINGTHRESHOLD,
        popoverHeader: t('popoverProcessingThreshold.Header'),
        popoverBody: t('popoverProcessingThreshold.Body'),
      },
      {
        title: t('dashboard.processedResponses'),
        iconClass: 'bx-copy-alt',
        description: processedResponses,
        popoverTarget: Popovers.PROCESSEDRESPONSES,
        popoverHeader: t('popoverProcessedResponses.Header'),
        popoverBody: t('popoverProcessedResponses.Body'),
      },
      {
        title: t('dashboard.incompleteOrInvalid'),
        iconClass: 'mdi mdi-percent',
        description: '-',
        popoverTarget: Popovers.INCOMPLETEORINVALID,
        popoverHeader: t('popoverIncompleteOrInvalid.Header'),
        popoverBody: t('popoverIncompleteOrInvalid.Body'),
      },
      {
        title: t('dashboard.responsesDay'),
        iconClass: 'mdi mdi-calendar-today',
        description: getResponsesPerDay(),
        popoverTarget: Popovers.RESPONSESDAY,
        popoverHeader: t('popoverResponsesDay.Header'),
        popoverBody: t('popoverResponsesDay.Body'),
      },
    ],
    [
      t,
      expectedResponses,
      totalResponses,
      organization,
      processedResponses,
      getResponsesPerDay,
    ]
  );

  return reports.map((report, key) => (
    <Col md="6" sm="6" lg="4" key={`_col_${key}`}>
      <MiniStatReport report={report} />
    </Col>
  ));
};

export default withNamespaces()(Reports);
