const ChartNames = Object.freeze({
  LINE: 'Line',
  PIE: 'Pie',
  RADIAL: 'Radial',
  BAR: 'Bar',
  AREA: 'Area',
  RADAR: 'Radar',
  HEATMAP: 'Heatmap',
  GAUGE: 'Gauge',
  ANGLE_CIRCLE: 'Angle Circle',
  TREEMAP: 'Treemap',
  LINE_COLUMN: 'Line-Column',
  COLUMN: 'Column',
});

export default ChartNames;
