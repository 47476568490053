import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const RESPONSES_FETCH_INIT = createAction('RESPONSES_FETCH_INIT');
export const RESPONSES_FETCH_SUCCESS = createAction('RESPONSES_FETCH_SUCCESS');
export const RESPONSES_FETCH_FAIL = createAction('RESPONSES_FETCH_FAIL');
export const RESPONSES_CLEAR_STATE = createAction('RESPONSES_CLEAR_STATE');

async function getResponsesWithRestApi(userToken, wavesId) {
  const url = `https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_PROJECTID}/databases/(default)/documents:runQuery`;

  const query = {
    structuredQuery: {
      where: {
        fieldFilter: {
          field: { fieldPath: 'waveId' },
          value: {
            arrayValue: {
              values: wavesId.map((waveId) => ({ stringValue: waveId })),
            },
          },
          op: 'IN',
        },
      },
      select: {
        fields: [
          { fieldPath: 'demographics' },
          { fieldPath: 'groups' },
          { fieldPath: 'createdAt' },
        ],
      },
      from: [{ collectionId: 'responses' }],
    },
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  });

  const data = await response.json();

  return data;
}

const mapFirestoreValue = (value) => {
  if (value.stringValue !== undefined) {
    return value.stringValue;
  }

  if (value.integerValue !== undefined) {
    return parseInt(value.integerValue, 10);
  }

  if (value.doubleValue !== undefined) {
    return parseFloat(value.doubleValue);
  }

  if (value.booleanValue !== undefined) {
    return value.booleanValue;
  }

  if (value.timestampValue !== undefined) {
    return new Date(value.timestampValue);
  }

  if (value.arrayValue !== undefined) {
    return value.arrayValue.values?.map(mapFirestoreValue) || [];
  }

  if (value.mapValue !== undefined) {
    const mappedObject = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, nestedValue] of Object.entries(value.mapValue.fields)) {
      mappedObject[key] = mapFirestoreValue(nestedValue);
    }
    return mappedObject;
  }

  return null;
};

export const fetchResponses = (wavesId = null) => {
  return async (dispatch) => {
    dispatch(RESPONSES_FETCH_INIT());

    const responses = [];

    const userToken = JSON.parse(localStorage.getItem('authUser'))
      ?.stsTokenManager?.accessToken;

    if (userToken) {
      try {
        const data = await getResponsesWithRestApi(userToken, wavesId);

        data.forEach((doc) => {
          const docData = doc?.document?.fields;
          const responseData = {};

          if (docData) {
            Object.entries(docData).forEach(([key, value]) => {
              responseData[key] = mapFirestoreValue(value);
            });

            responses.push({
              id: doc.document.name.split('/').pop(),
              ...responseData,
            });
          }
        });
      } catch (error) {
        console.error('Error Fetching Responses', error);
        return dispatch(RESPONSES_FETCH_FAIL({ error }));
      }

      return dispatch(RESPONSES_FETCH_SUCCESS({ responses }));
    }

    try {
      let baseQuery = collection(firebase.firestore(), 'responses');

      if (wavesId) {
        baseQuery = query(baseQuery, where('waveId', 'in', wavesId));
      }

      const queryResult = await getDocs(baseQuery);

      queryResult.forEach((responseDoc) => {
        const responseData = responseDoc.data();

        const response = {
          id: responseDoc.id,
          ...responseData,
          createdAt: responseData.createdAt.toDate(),
        };

        responses.push(response);
      });
    } catch (error) {
      return dispatch(RESPONSES_FETCH_FAIL({ error }));
    }

    return dispatch(RESPONSES_FETCH_SUCCESS({ responses }));
  };
};

export const responsesClearState = () => (dispatch) =>
  dispatch(RESPONSES_CLEAR_STATE());
