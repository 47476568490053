import React, { useCallback, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Col, Container, Row, Button } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';

import {
  fetchDemographics,
  fetchDeployments,
  fetchResponses,
  setDemographic,
  setGroup,
} from '../../store/actions';
import classes from './CompareWaves.module.scss';
import WavesDropdown from '../../components/WavesDropdown';
import ChartsDropdown from '../../components/ChartsDropdown';
import Reports from '../AllCharts/Reports';
import ResponseRate from '../AllCharts/ResponseRate';
import Segmentation from '../AllCharts/Segmentation';
import DashboardCharts from '../../helpers/enums/dashboardCharts';
import SurveyResponses from '../AllCharts/SurveyResponses';
import DemographicsBreakdown from '../AllCharts/DemographicsBreakdown';
import SafetyAwarenessBreakdown from '../AllCharts/SafetyAwarenessBreakdown';
import SafetyAwareness from '../AllCharts/SafetyAwareness';
import BelowThresholdGroup from '../AllCharts/BelowThresholdGroup';
import AreasOfRisks from '../AllCharts/AreasOfRisks';

const CompareWaves = (props) => {
  const {
    responses,
    loadingResponses,
    demographics,
    surveysHits,
  } = useSelector(
    (state) => ({
      responses: state.responses.data,
      loadingResponses: state.responses.loading,
      demographics: state.demographics.data,
      surveysHits: state.surveys.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [showCharts, setShowCharts] = useState({});
  const [waves, setWaves] = useState([]);
  const [wavesToCompare, setWavesToCompare] = useState([]);
  const [chartsToCompare, setChartsToCompare] = useState([]);

  useEffect(() => {
    dispatch(setGroup(null));
    dispatch(setDemographic(null));
  }, [dispatch]);

  const onCompareWaves = useCallback(() => {
    const chartsToShow = {};
    const waves = wavesToCompare.map(({ value: wave }) => wave);
    const wavesId = wavesToCompare.map(({ value: wave }) => wave.id);

    dispatch(fetchResponses(wavesId));
    dispatch(fetchDemographics(wavesId));
    dispatch(fetchDeployments(wavesId));

    chartsToCompare.forEach(({ value: chart }) => {
      chartsToShow[chart] = true;
    });

    setWaves(waves);
    setShowCharts(chartsToShow);
  }, [dispatch, chartsToCompare, wavesToCompare]);

  const cleanSelectedData = useCallback(() => {
    setWavesToCompare([]);
    setWaves([]);
    setChartsToCompare([]);
    setShowCharts({});
  }, []);

  return (
    <div className="page-content">
      <Container className="min-vh-100" fluid>
        <Row>
          <Col className="font-size-24 font-weight-bold mb-2">
            {props.t('compareWaves.title')}
          </Col>
        </Row>
        <Row className="font-size-14">
          <Col xl="5">
            <span className="mr-2">Select the waves to compare:</span>
            <WavesDropdown
              wavesToCompare={wavesToCompare}
              setWavesToCompare={setWavesToCompare}
              cleanSelectedData={cleanSelectedData}
            />
          </Col>
          <Col xl="5" className="mt-xl-0 mt-2">
            <span className="mr-2">Select the charts to show:</span>
            <ChartsDropdown
              chartsToCompare={chartsToCompare}
              setChartsToCompare={setChartsToCompare}
            />
          </Col>
          <Col xl="2" className="align-self-end mt-xl-0 mt-3">
            <Button
              className={classNames(
                'font-size-14',
                classes['button-compare-waves']
              )}
              color="primary"
              onClick={onCompareWaves}
              disabled={loadingResponses}
            >
              {loadingResponses ? (
                <ClipLoader color="white" size={20} />
              ) : (
                'Compare Waves'
              )}
            </Button>
          </Col>
        </Row>
        {showCharts[DashboardCharts.RESPONSE_RATE] && (
          <Row className="mt-4">
            {waves.map((wave, index) => {
              const waveResponses = responses.filter(
                ({ waveId }) => waveId === wave.id
              );

              return (
                <Col xl="4" key={wave.id}>
                  <div className="font-size-16 mb-1 font-weight-bold">
                    {wave.name || `Wave: ${index + 1}`}
                    <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                  </div>
                  <ResponseRate wave={wave} responses={waveResponses} />
                </Col>
              );
            })}
          </Row>
        )}
        {showCharts[DashboardCharts.SURVEY_RESPONSES] && (
          <Row className="mt-4">
            {waves.map((wave, index) => {
              const waveResponses = responses.filter(
                ({ waveId }) => waveId === wave.id
              );

              return (
                <Col xl="6" key={wave.id}>
                  <div className="font-size-16 mb-1 font-weight-bold">
                    {wave.name || `Wave: ${index + 1}`}
                    <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                  </div>
                  <SurveyResponses
                    wave={wave}
                    hits={surveysHits}
                    responses={waveResponses}
                  />
                </Col>
              );
            })}
          </Row>
        )}
        {showCharts[DashboardCharts.REPORTS] && (
          <Row className="mt-4">
            {waves.map((wave, index) => (
              <div key={wave.id}>
                <div className="font-size-16 font-weight-bold">
                  {wave.name || `Wave: ${index + 1}`}
                  <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                </div>
                <Row className="my-3">
                  <Reports wave={wave} />
                </Row>
              </div>
            ))}
          </Row>
        )}
        {showCharts[DashboardCharts.DEMOGRAPHICS_BREAKDOWN] && (
          <Row className="mt-4">
            {waves.map((wave, index) => {
              const waveResponses = responses.filter(
                ({ waveId }) => waveId === wave.id
              );

              const waveDemographics = demographics.filter(
                ({ waveId }) => waveId === wave.id
              );

              return (
                <Col xl="6" key={wave.id}>
                  <div className="font-size-16 mb-1 font-weight-bold">
                    {wave.name || `Wave: ${index + 1}`}
                    <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                  </div>
                  <DemographicsBreakdown
                    wave={wave}
                    responses={waveResponses}
                    demographics={waveDemographics}
                  />
                </Col>
              );
            })}
          </Row>
        )}
        {showCharts[DashboardCharts.SEGMENTATION] && (
          <Row className="mt-4">
            {waves.map((wave, index) => {
              const waveResponses = responses.filter(
                ({ waveId }) => waveId === wave.id
              );

              return (
                <Col xl="6" key={wave.id}>
                  <div className="font-size-16 mb-1 font-weight-bold">
                    {wave.name || `Wave: ${index + 1}`}
                    <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                  </div>
                  <Segmentation wave={wave} responses={waveResponses} />
                </Col>
              );
            })}
          </Row>
        )}
        {showCharts[DashboardCharts.SAFETY_AWARENESS_BREAKDOWN] && (
          <Row className="mt-4">
            {waves.map((wave, index) => (
              <Col xl="12" key={wave.id}>
                <div className="font-size-16 mb-1 font-weight-bold">
                  {wave.name || `Wave: ${index + 1}`}
                  <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                </div>
                <SafetyAwarenessBreakdown wave={wave} />
              </Col>
            ))}
          </Row>
        )}
        {showCharts[DashboardCharts.SAFETY_AWARENESS] && (
          <Row className="mt-4">
            {waves.map((wave, index) => (
              <Col xl="4" key={wave.id}>
                <div className="font-size-16 mb-1 font-weight-bold">
                  {wave.name || `Wave: ${index + 1}`}
                  <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                </div>
                <SafetyAwareness wave={wave} />
              </Col>
            ))}
          </Row>
        )}
        {showCharts[DashboardCharts.BELOW_THRESHOLD_GROUP] && (
          <Row className="mt-4">
            {waves.map((wave, index) => (
              <Col xl="4" key={wave.id}>
                <div className="font-size-16 mb-1 font-weight-bold">
                  {wave.name || `Wave: ${index + 1}`}
                  <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                </div>
                <BelowThresholdGroup wave={wave} />
              </Col>
            ))}
          </Row>
        )}
        {showCharts[DashboardCharts.AREAS_OF_RISKS] && (
          <Row className="mt-4">
            {waves.map((wave, index) => (
              <Col xl="12" key={wave.id}>
                <div className="font-size-16 mb-1 font-weight-bold">
                  {wave.name || `Wave: ${index + 1}`}
                  <i className="mdi mdi-arrow-bottom-right font-size-14 ml-1" />
                </div>
                <AreasOfRisks wave={wave} />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default withNamespaces()(CompareWaves);
