import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const TAGS_FETCH_INIT = createAction('TAGS_FETCH_INIT');
export const TAGS_FETCH_FAIL = createAction('TAGS_FETCH_FAIL');
export const TAGS_FETCH_SUCCESS = createAction('TAGS_FETCH_SUCCESS');
export const TAGS_CLEAN_UP = createAction('TAGS_CLEAN_UP');

export const fetchTags = (options = {}) => {
  return async (dispatch) => {
    dispatch(TAGS_FETCH_INIT());
    let tags = [];

    try {
      const tagsRef = firebase.firestore().collection('tags');
      let baseQuery = tagsRef;

      if (options.filterByActive) {
        const { filterByActive } = options;
        baseQuery = baseQuery.where('active', '==', filterByActive);
      }

      if (options.filterByType) {
        const { filterByType } = options;
        baseQuery = baseQuery.where('type', '==', filterByType);
      }

      if (options.filterByOrganizations) {
        const { filterByOrganizations } = options;
        baseQuery = baseQuery.where(
          'organizationName',
          'in',
          filterByOrganizations
        );
      }

      if (options.getById) {
        const { getById } = options;
        const tag = (await tagsRef.doc(getById).get()).data();
        tags.push({
          id: tag.id,
          ...tag,
          createdAt: tag.createdAt.toDate(),
        });
      } else {
        (await baseQuery.get()).forEach((tag) => {
          const data = tag.data();
          tags.push({
            id: tag.id,
            ...data,
            createdAt: data.createdAt.toDate(),
          });
        });
      }

      if (options.filterByTypes) {
        const { filterByTypes } = options;

        tags = tags.filter((tag) => filterByTypes.includes(tag.type));
      }
    } catch (error) {
      return dispatch(TAGS_FETCH_FAIL({ error }));
    }

    return dispatch(TAGS_FETCH_SUCCESS({ tags }));
  };
};

export const tagsCleanUp = () => (dispatch) => dispatch(TAGS_CLEAN_UP());
