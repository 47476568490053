import React, { useCallback } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DashboardCharts from '../../helpers/enums/dashboardCharts';

const animatedComponents = makeAnimated();

const chartsOptions = [
  {
    value: DashboardCharts.RESPONSE_RATE,
    label: DashboardCharts.RESPONSE_RATE,
  },
  {
    value: DashboardCharts.SURVEY_RESPONSES,
    label: DashboardCharts.SURVEY_RESPONSES,
  },
  { value: DashboardCharts.REPORTS, label: DashboardCharts.REPORTS },
  {
    value: DashboardCharts.DEMOGRAPHICS_BREAKDOWN,
    label: DashboardCharts.DEMOGRAPHICS_BREAKDOWN,
  },
  { value: DashboardCharts.SEGMENTATION, label: DashboardCharts.SEGMENTATION },
  {
    value: DashboardCharts.SAFETY_AWARENESS_BREAKDOWN,
    label: DashboardCharts.SAFETY_AWARENESS_BREAKDOWN,
  },
  {
    value: DashboardCharts.SAFETY_AWARENESS,
    label: DashboardCharts.SAFETY_AWARENESS,
  },
  {
    value: DashboardCharts.BELOW_THRESHOLD_GROUP,
    label: DashboardCharts.BELOW_THRESHOLD_GROUP,
  },
  {
    value: DashboardCharts.AREAS_OF_RISKS,
    label: DashboardCharts.AREAS_OF_RISKS,
  },
];

const ChartsDropdown = ({ chartsToCompare, setChartsToCompare }) => {
  const onChangeCharts = useCallback(
    (charts) => {
      setChartsToCompare(charts);
    },
    [setChartsToCompare]
  );

  return (
    <Select
      isMulti
      name="wavesToCompare"
      className="basic-multi-select"
      classNamePrefix="select"
      components={animatedComponents}
      options={chartsOptions}
      onChange={onChangeCharts}
      value={chartsToCompare}
    />
  );
};

export default ChartsDropdown;
