import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const SURVEYS_FETCH_DEPLOYMENTS_INIT = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_INIT'
);
export const SURVEYS_FETCH_DEPLOYMENTS_SUCCESS = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_SUCCESS'
);
export const SURVEYS_FETCH_DEPLOYMENTS_FAIL = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_FAIL'
);
export const SURVEYS_CLEAR_STATE = createAction('SURVEYS_CLEAR_STATE');

export const fetchDeployments = (wavesId = null) => {
  return async (dispatch) => {
    dispatch(SURVEYS_FETCH_DEPLOYMENTS_INIT());

    const deployments = [];

    try {
      let baseQuery = firebase.firestore().collectionGroup('deployments');

      if (wavesId) {
        baseQuery = baseQuery.where('waveId', 'in', wavesId);
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((deploymentDoc) => {
        const deploymentData = deploymentDoc.data();

        const deployment = {
          id: deploymentDoc.id,
          ...deploymentData,
          startDate: deploymentData.startDate.toDate(),
          endDate: deploymentData.endDate?.toDate() || null,
          lastTimeProcessed: deploymentData.lastTimeProcessed?.toDate() || null,
        };

        deployments.push(deployment);
      });
    } catch (error) {
      return dispatch(SURVEYS_FETCH_DEPLOYMENTS_FAIL({ error }));
    }

    return dispatch(SURVEYS_FETCH_DEPLOYMENTS_SUCCESS({ deployments }));
  };
};

export const surveysClearState = () => (dispatch) =>
  dispatch(SURVEYS_CLEAR_STATE());
