import { createReducer } from 'redux-act';

import {
  PREFERENCES_SET_ORGANIZATION,
  PREFERENCES_SET_WAVE,
  PREFERENCES_SET_GROUP,
  PREFERENCES_SET_DEMOGRAPHIC,
  PREFERENCES_CLEAN_UP,
  PREFERENCES_SET_LANGUAGE,
  PREFERENCES_SET_DATES_FROM_WAVES,
  PREFERENCES_CLEAN_UP_DATES_FROM_WAVES,
  PREFERENCES_CLEAN_UP_DEMOGRAPHIC,
} from '../../actions/preferences';

const initialState = {
  organization: null,
  wave: null,
  group: null,
  demographic: null,
  language: null,
  datesFromWave: null,
};

const preferences = createReducer(
  {
    [PREFERENCES_SET_ORGANIZATION]: (state, payload) => ({
      ...state,
      organization: payload.organization,
    }),
    [PREFERENCES_SET_WAVE]: (state, payload) => ({
      ...state,
      wave: payload.wave,
    }),
    [PREFERENCES_SET_GROUP]: (state, payload) => ({
      ...state,
      group: payload.group,
    }),
    [PREFERENCES_SET_DEMOGRAPHIC]: (state, payload) => ({
      ...state,
      demographic: payload.demographic,
    }),
    [PREFERENCES_CLEAN_UP]: (state) => ({
      ...state,
      organization: null,
      wave: null,
      group: null,
      demographic: null,
    }),
    [PREFERENCES_SET_LANGUAGE]: (state, payload) => ({
      ...state,
      language: payload.language,
    }),
    [PREFERENCES_SET_DATES_FROM_WAVES]: (state, payload) => ({
      ...state,
      datesFromWave: payload.datesFromWave,
    }),
    [PREFERENCES_CLEAN_UP_DATES_FROM_WAVES]: (state) => ({
      ...state,
      datesFromWave: null,
    }),
    [PREFERENCES_CLEAN_UP_DEMOGRAPHIC]: (state) => ({
      ...state,
      demographic: null,
    }),
  },
  initialState
);

export default preferences;
