import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = (props) => {
  return (
    <>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col md={6}>© {new Date().getFullYear()} Presage Group Inc.</Col>
            {/* <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
