import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';

import classes from './DemographicsDropdown.module.scss';
import './DemographicsDropdown.scss';

const DemographicsDropdown = ({
  demographics,
  selectedDemographicTag,
  setSelectedDemographicTag,
}) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (demographics.length > 0) {
      setSelectedDemographicTag(demographics[0]);
    }
  }, [demographics, setSelectedDemographicTag]);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => demographics.length > 0 && setMenu(!menu)}
      className="d-inline-block"
      id="demographics-dropdown"
    >
      <DropdownToggle
        className={classNames('btn waves-effect', classes['box-shadow-none'])}
        id="page-header-user-dropdown"
        tag="button"
      >
        <i className="fa fas fa-tag font-size-16 align-middle mr-1" />
        <span className="d-none d-sm-inline-block ml-2 mr-1">
          {selectedDemographicTag && selectedDemographicTag.tagName}
          {demographics.length === 0 && 'No options'}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu right>
        {demographics.map((demographic) => (
          <span
            key={demographic.id}
            className={classNames('dropdown-item', classes['cursor-pointer'])}
            onClick={() => {
              setSelectedDemographicTag(demographic);
              setMenu(!menu);
            }}
          >
            <i className="fa fas fa-tag font-size-16 align-middle mr-2 text-secondary" />
            {demographic.tagName}
          </span>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DemographicsDropdown;
