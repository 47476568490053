import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchOrganizations, toggleLeftmenu } from '../../store/actions';

import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import logo from '../../assets/images/presage.png';
import OrganizationDropdown from '../CommonForBoth/TopbarDropdown/OrganizationDropdown';
import Roles from '../../helpers/enums/roles';
import Paths from '../../routes/paths';

const Header = () => {
  const { leftMenu, organization, userData } = useSelector(
    (state) => ({
      leftMenu: state.Layout.leftMenu,
      organization: state.preferences.organization,
      userData: state.Login.userData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const filterInactives = true;
    if (userData.role !== Roles.ADMIN) {
      dispatch(fetchOrganizations(filterInactives, userData.organizationName));
    }
  }, [userData.role, userData.organizationName, dispatch]);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="d-none d-sm-block navbar-brand-box">
            <Link to={Paths.ROOT} className="logo">
              <span className="logo-sm">
                <img src={logo} alt="PresageGroup.com" height="33" />
              </span>
              <span className="logo-lg">
                <img src={logo} alt="PresageGroup.com" height="33" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={() => {
              dispatch(toggleLeftmenu(!leftMenu));
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          <div
            className="d-none d-sm-flex align-items-center font-size-20"
            style={{ color: '#475058e8' }}
          >
            {organization && organization.displayName}
          </div>
        </div>

        <div className="d-flex">
          <div className="d-none d-sm-block">
            {userData.role === Roles.ADMIN && <OrganizationDropdown />}
          </div>
          <LanguageDropdown />
          <ProfileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
