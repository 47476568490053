import { createReducer } from 'redux-act';

import { SHOW_TOAST, CLEAN_UP_TOAST } from '../../actions/toast';

const initialState = {
  icon: null,
  title: null,
};

const toast = createReducer(
  {
    [SHOW_TOAST]: (state, payload) => ({
      ...state,
      icon: payload.icon,
      title: payload.title,
    }),
    [CLEAN_UP_TOAST]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default toast;
