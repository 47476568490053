import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from 'reactstrap';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withRouter } from 'react-router-dom';
import { editProfile } from '../../store/actions';
import defaultLogo from '../../assets/images/user-default-logo.svg';

const UserProfile = () => {
  const { success, error, userData } = useSelector(
    (state) => ({
      success: state.Profile.success,
      error: state.Profile.error,
      userData: state.Login.userData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  function handleValidSubmit(event, values) {
    dispatch(editProfile(values));
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success && success ? (
                <Alert color="success">{success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="mr-3">
                      <img
                        src={userData.logoUrl || defaultLogo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{`${userData.firstName} ${userData.lastName}`}</h5>
                        <p className="mb-1">{`( ${userData.role} )`}</p>
                        <p className="mb-1">{userData.email}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="form-group">
                  <AvField
                    name="username"
                    label="Old Password"
                    value={null}
                    className="form-control"
                    placeholder="Enter old Password"
                    type="text"
                    required
                  />
                  <AvField
                    name="username"
                    label="New Password"
                    value={null}
                    className="form-control"
                    placeholder="Enter new Password"
                    type="text"
                    required
                  />
                  <AvField
                    name="username"
                    label="Repeat Password"
                    value={null}
                    className="form-control"
                    placeholder="Repeat Password"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={null} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Edit Password
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(UserProfile);
