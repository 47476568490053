import { createReducer } from 'redux-act';

import {
  WAVES_FETCH_INIT,
  WAVES_FETCH_SUCCESS,
  WAVES_FETCH_FAIL,
  WAVES_CLEAN_UP,
} from '../../actions/waves';

const initialState = {
  data: [],
  loading: false,
  error: null,
  fetched: false,
};

const wavesReducer = createReducer(
  {
    [WAVES_FETCH_INIT]: (state) => ({
      ...state,
      loading: true,
      error: null,
      fetched: false,
    }),
    [WAVES_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.waves,
      loading: false,
      error: null,
      fetched: true,
    }),
    [WAVES_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
      fetched: true,
    }),
    [WAVES_CLEAN_UP]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default wavesReducer;
