import React from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import './popover.scss';

const Popover = ({ placement, target, header, body }) => {
  return (
    <UncontrolledPopover
      popperClassName="body-styles"
      innerClassName="header-styles"
      trigger="legacy"
      placement={placement || 'bottom'}
      target={target}
      container={document.body}
    >
      <PopoverHeader className="popover-header">{header}</PopoverHeader>
      <PopoverBody className="popover-body">{body}</PopoverBody>
    </UncontrolledPopover>
  );
};

export default Popover;
