import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';
import { Table, Card, CardBody } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import Popovers from '../../../helpers/enums/popovers';
import {
  dateBetweenPeriod,
  getChildrenGroupIds,
  getGroupFromHierarchy,
} from '../../../helpers/functions/functions_helper';
import {
  checkUserIsDashboardAdmin,
  DownloadOptions,
  getDownloadChartFileName,
  TablesHeight,
} from '../../../helpers/utils';
import Popover from '../../../components/Popover';
import DemographicsDropdown from '../../../components/DemographicsDropdown';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';

import classes from './DemographicsBreakdown.module.scss';
import './DemographicsBreakdown.scss';

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const DemographicsBreakdown = ({ t, wave, responses, demographics }) => {
  const {
    totalResponses,
    // This field is selected from the demographics selector at the top
    selectedDemographic,
    dashboard,
    userData,
  } = useSelector(({ preferences, dashboards, Login }) => {
    const group = preferences.group;
    const selectedDemographic = preferences.demographic;
    const selectedWaveDates = preferences.datesFromWave;
    const dashboardsData = dashboards.data;
    const userData = Login.userData;

    const dashboard = dashboardsData.find(({ waveId }) => waveId === wave?.id);
    let totalResponses = responses;

    if (group && wave) {
      const groupInHierarchy = getGroupFromHierarchy(wave.hierarchy, group);

      const groupIds = getChildrenGroupIds(groupInHierarchy);

      totalResponses = totalResponses.filter((response) =>
        response.groups.some((groupId) => groupIds.includes(groupId))
      );
    }

    if (selectedWaveDates) {
      const { startDate, endDate } = selectedWaveDates;
      totalResponses = totalResponses.filter(({ createdAt }) =>
        dateBetweenPeriod(createdAt, startDate, endDate)
      );
    }

    return {
      totalResponses,
      selectedDemographic,
      dashboard,
      userData,
    };
  }, shallowEqual);

  const [expandTable, setExpandTable] = useState(false);

  const [selectedDemographicTag, setSelectedDemographicTag] = useState(null);

  const userIsAdmin = checkUserIsDashboardAdmin(userData);

  const demographicsBreakdownData = useCallback(() => {
    const demographicsBreakdown = {};
    let demographicsData = demographics;
    let answeredResponses = 0;

    const overall = dashboard?.Scores?.Overall;

    if (selectedDemographic) {
      demographicsData = demographicsData.filter(
        ({ id }) => id === selectedDemographic.tagId
      );
    }

    if (selectedDemographicTag) {
      demographicsData = demographicsData.filter(
        ({ id }) => id === selectedDemographicTag.id
      );
    }

    demographicsData.forEach((demographic) => {
      totalResponses.forEach((response) => {
        let dashboardResponse = false;

        if (overall) {
          dashboardResponse = Object.entries(
            overall?.groups
          ).find(([groupId]) =>
            response?.groups.find(
              (responseGroupId) => groupId === responseGroupId
            )
          )?.[1];
        }

        let responseWithDemographic = null;

        if (dashboardResponse?.demographics) {
          const dashboardResponseDemographicId = Object.keys(
            dashboardResponse.demographics
          ).find(
            (responseDemographicId) => responseDemographicId === demographic.id
          );

          if (dashboardResponseDemographicId) {
            responseWithDemographic = response.demographics?.find(
              (responseDemographic) =>
                responseDemographic?.demographicId ===
                dashboardResponseDemographicId
            );
          }
        }

        if (responseWithDemographic) {
          responseWithDemographic.name.forEach((option) => {
            demographicsBreakdown[option] = demographicsBreakdown[option]
              ? demographicsBreakdown[option] + 1
              : 1;
          });
        }
      });
    });

    if (selectedDemographic?.choice) {
      Object.keys(demographicsBreakdown).forEach((option) => {
        if (option !== selectedDemographic?.choice) {
          delete demographicsBreakdown[option];
        }
      });
    }

    const orderedDemographics = [];

    demographicsData.forEach(({ content }) => {
      if (content) {
        content.forEach((choice) => {
          const demographicChoice = demographicsBreakdown[choice];

          if (!userIsAdmin && demographicChoice >= 10) {
            answeredResponses += demographicChoice;
            orderedDemographics.push([choice, demographicChoice]);
          }

          if (userIsAdmin && demographicChoice) {
            answeredResponses += demographicChoice;
            orderedDemographics.push([choice, demographicChoice]);
          }
        });
      }
    });

    const otherResponses = totalResponses.length - answeredResponses;

    if (otherResponses > 0) {
      orderedDemographics.push(['Other Responses', otherResponses]);
    }

    orderedDemographics.push(['Overall', totalResponses.length]);

    return orderedDemographics;
  }, [
    dashboard,
    userIsAdmin,
    demographics,
    totalResponses,
    selectedDemographic,
    selectedDemographicTag,
  ]);

  const componentRef = useRef();

  const DemographicsBreakdownChart = forwardRef((_, ref) => (
    <div
      ref={ref}
      className="table-responsive"
      style={{ height: `${tableHeight}rem` }}
    >
      <Table id="demographics-table" className="table table-nowrap mb-0">
        <thead>
          <tr>
            <th>{t('demographicsBreakdownTable.region')}</th>
            <th>{t('demographicsBreakdownTable.responses')}</th>
            <th>{t('demographicsBreakdownTable.rate')}</th>
          </tr>
        </thead>
        <tbody>
          {demographicsBreakdownData().map(([region, responses]) => (
            <tr key={`${region}-${responses}`}>
              <td className={classes.region}>{region}</td>
              <td>{responses}</td>
              <td>
                {totalResponses.length !== 0
                  ? parseFloat(
                      (responses * 100) / totalResponses.length
                    ).toFixed(1)
                  : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ));

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('demographicsBreakdownTable.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const generateCSVData = useMemo(
    () =>
      demographicsBreakdownData().map(([region, responses]) => ({
        region,
        responses,
        rate:
          totalResponses.length !== 0
            ? parseFloat((responses * 100) / totalResponses.length).toFixed(1)
            : '-',
      })),
    [demographicsBreakdownData, totalResponses.length]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        { label: t('demographicsBreakdownTable.region'), key: 'region' },
        { label: t('demographicsBreakdownTable.responses'), key: 'responses' },
        { label: t('demographicsBreakdownTable.rate'), key: 'rate' },
      ],
      data: generateCSVData,
      fileName: `${downloadChartFileName}.csv`,
    }),
    [t, downloadChartFileName, generateCSVData]
  );

  const onChangeExpandTable = useCallback(() => {
    setExpandTable(!expandTable);
  }, [expandTable]);

  // 1 is counting the header of the table as an extra row
  // 3 is the amount of rem needed per row
  const tableHeight = useMemo(() => {
    const defaultHeight = TablesHeight.TABLE;
    const adjustedHeight = (demographicsBreakdownData().length + 1) * 3;

    if (expandTable) {
      return adjustedHeight > defaultHeight ? adjustedHeight : defaultHeight;
    }

    return defaultHeight;
  }, [expandTable, demographicsBreakdownData]);

  // 13 is the amount of px needed per row
  const bodyHeight = useMemo(() => {
    const defaultHeight = TablesHeight.CARD_BODY;
    const adjustedHeight =
      TablesHeight.CARD_BODY + (tableHeight - TablesHeight.TABLE) * 13;

    if (expandTable) {
      return adjustedHeight > defaultHeight ? adjustedHeight : defaultHeight;
    }

    return defaultHeight;
  }, [expandTable, tableHeight]);

  const tooltipText = useMemo(() => (expandTable ? 'Shrink' : 'Expand'), [
    expandTable,
  ]);

  const canDownloadChart = useMemo(() => {
    const fetchedData = demographicsBreakdownData();

    let canDownload = false;

    if (demographics.length > 0) {
      canDownload = !fetchedData.every(([_, value]) => value === 0);
    }

    return canDownload;
  }, [demographics.length, demographicsBreakdownData]);

  const dropdownOptions = useMemo(
    () =>
      selectedDemographic && demographics.length > 0
        ? demographics?.filter(({ id }) => id === selectedDemographic.tagId)
        : demographics,
    [selectedDemographic, demographics]
  );

  return (
    <Card>
      <CardBody style={{ height: `${bodyHeight}px` }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-baseline">
            <div
              className={classnames(
                'd-flex flex-column',
                classes['cursor-help'],
                classes['demographics-title']
              )}
              id={Popovers.DEMOGRAPHICSBREAKDOWN}
            >
              <span className="font-size-20">
                {t('demographicsBreakdownTable.title')}
              </span>
              <span
                className={classnames('font-size-12', classes['opacity-75'])}
              >
                {t('demographicsBreakdownTable.subTitle')}
              </span>
            </div>
            {expandTable ? (
              <i
                className="fa fas fa-compress-alt font-size-16 ml-2 text-secondary cursor-pointer"
                data-tip={tooltipText}
                onClick={onChangeExpandTable}
              />
            ) : (
              <i
                className="fa fas fa-expand-alt font-size-16 ml-2 text-secondary cursor-pointer"
                data-tip={tooltipText}
                onClick={onChangeExpandTable}
              />
            )}
          </div>
          <DemographicsDropdown
            demographics={dropdownOptions}
            selectedDemographicTag={selectedDemographicTag}
            setSelectedDemographicTag={setSelectedDemographicTag}
          />
        </div>
        {demographics.length > 0 && (
          <DemographicsBreakdownChart ref={componentRef} />
        )}
        {demographics.length === 0 && (
          <div className={classes['empty-demographics']}>
            There are no demographic tags for this wave
          </div>
        )}
        <div className={classes['download-icon']}>
          <DownloadChartDropdown
            t={t}
            canDownloadChart={canDownloadChart}
            PNGData={PNGData}
            CSVData={CSVData}
            optionsEnabled={optionsEnabled}
          />
        </div>
        <ReactTooltip />
      </CardBody>
      <Popover
        target={Popovers.DEMOGRAPHICSBREAKDOWN}
        header={t('popoverDemographicsBreakdown.Header')}
        body={t('popoverDemographicsBreakdown.Body')}
      />
    </Card>
  );
};

export default withNamespaces()(DemographicsBreakdown);
