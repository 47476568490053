import { createReducer } from 'redux-act';

import {
  DEMOGRAPHICS_CLEAR_STATE,
  DEMOGRAPHICS_FETCH_FAIL,
  DEMOGRAPHICS_FETCH_INIT,
  DEMOGRAPHICS_FETCH_SUCCESS,
} from '../../actions/demographics';

const initialState = {
  data: [],
  loading: false,
  error: null,
  fetched: false,
};

const demographicsReducer = createReducer(
  {
    [DEMOGRAPHICS_FETCH_INIT]: (state) => ({
      ...state,
      data: [],
      loading: true,
      fetched: false,
    }),
    [DEMOGRAPHICS_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      data: payload.demographics,
    }),
    [DEMOGRAPHICS_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: payload.error,
    }),
    [DEMOGRAPHICS_CLEAR_STATE]: () => initialState,
  },
  initialState
);

export default demographicsReducer;
