import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRoute = ({ path, component: Component, layout: Layout }) => (
  <Route
    path={path}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

PublicRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  layout: PropTypes.element.isRequired,
};

export default PublicRoute;
