import React, { useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';

import {
  dateBetweenPeriod,
  getChildrenGroupIds,
  getGroupFromHierarchy,
} from '../../../helpers/functions/functions_helper';
import {
  DownloadOptions,
  getDownloadChartFileName,
} from '../../../helpers/utils';
import ChartTypes from '../../../helpers/enums/chartTypes';
import ChartNames from '../../../helpers/enums/chartNames';
import Popovers from '../../../helpers/enums/popovers';
import Popover from '../../../components/Popover';
import ApexChart from '../../../components/ApexChart';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import classes from './ResponseRate.module.scss';

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const ResponseRate = ({ t, wave, responses }) => {
  const { group, selectedDemographic, selectedWaveDates } = useSelector(
    (state) => ({
      group: state.preferences.group,
      selectedDemographic: state.preferences.demographic,
      selectedWaveDates: state.preferences.datesFromWave,
    }),
    shallowEqual
  );

  const [pieSeries, setPieSeries] = useState([]);
  const [radialSeries, setRadialSeries] = useState([]);
  const [radialLabels, setRadialLabels] = useState([]);
  const [responseRateType, setResponseRateType] = useState(
    ChartTypes[ChartNames.RADIAL]
  );

  useEffect(() => {
    let totalResponses = wave ? responses : [];
    const expectedResponses = wave?.expectedResponses || totalResponses.length;

    if (group && wave) {
      const groupInHierarchy = getGroupFromHierarchy(wave.hierarchy, group);

      const groupIds = getChildrenGroupIds(groupInHierarchy);

      totalResponses = totalResponses.filter((response) =>
        response.groups.some((groupId) => groupIds.includes(groupId))
      );
    }

    if (selectedWaveDates) {
      const { startDate, endDate } = selectedWaveDates;
      totalResponses = totalResponses.filter(({ createdAt }) =>
        dateBetweenPeriod(createdAt, startDate, endDate)
      );
    }

    if (selectedDemographic?.tagId && selectedDemographic?.choice) {
      totalResponses = totalResponses.filter((response) =>
        response.demographics?.some(
          ({ demographicId, name }) =>
            demographicId === selectedDemographic.tagId &&
            name.includes(selectedDemographic.choice)
        )
      );
    }

    setPieSeries([
      totalResponses.length,
      expectedResponses - totalResponses.length,
    ]);

    setRadialLabels([`${totalResponses.length}/${expectedResponses}`]);

    setRadialSeries([
      expectedResponses === 0
        ? expectedResponses
        : parseInt((totalResponses.length * 100) / expectedResponses),
    ]);
  }, [wave, responses, group, selectedDemographic, selectedWaveDates]);

  const pieLabels = useMemo(
    () => [t('dashboard.totalResponses'), t('dashboard.expectedResponses')],
    [t]
  );

  const radialBarOptions = useMemo(
    () => ({
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '13px',
              color: void 0,
              offsetY: 60,
            },
            value: {
              offsetY: 15,
              fontSize: '70px',
              color: void 0,
              formatter(e) {
                return e === 'N/A' ? e : `${e}%`;
              },
            },
          },
        },
      },
      colors: ['#556ee6'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: !1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: radialLabels,
    }),
    [radialLabels]
  );

  const pieOptions = useMemo(
    () => ({
      labels: pieLabels,
      legend: {
        position: 'bottom',
      },
      colors: ['#34c38f', '#b5b5b5', '#d6e026'],
      dataLabels: {
        style: {
          fontSize: '24px',
          fontFamily: 'Poppins, sans-serif',
        },
      },
      fill: {
        type: 'gradient',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
          },
        },
      },
    }),
    [pieLabels]
  );

  const componentRef = useRef();

  const ResponseRateChart = forwardRef((_, ref) => (
    <div className="h-100" ref={ref}>
      {responseRateType === ChartTypes[ChartNames.PIE] && (
        <ApexChart
          series={pieSeries}
          options={pieOptions}
          type={ChartTypes[ChartNames.PIE]}
          ref={ref}
        />
      )}
      {responseRateType === ChartTypes[ChartNames.RADIAL] && (
        <ApexChart
          series={radialSeries}
          labels={radialLabels}
          options={radialBarOptions}
          chartClasses={classes['respose-rate-height']}
          type={ChartTypes[ChartNames.RADIAL]}
          ref={ref}
        />
      )}
    </div>
  ));

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('responseRateGraph.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        { label: t('responseRateGraph.title'), key: 'responseRate' },
        { label: t('dashboard.totalResponses'), key: 'totalResponses' },
        { label: 'Remaining Responses', key: 'remainingResponses' },
        { label: t('dashboard.expectedResponses'), key: 'expectedResponses' },
      ],
      data: [
        {
          responseRate: radialSeries[0],
          totalResponses: pieSeries[0],
          remainingResponses: pieSeries[1],
          expectedResponses: pieSeries.reduce((a, b) => a + b, 0),
        },
      ],
      fileName: `${downloadChartFileName}.csv`,
    }),
    [t, pieSeries, radialSeries, downloadChartFileName]
  );

  const canDownloadChart = useMemo(() => {
    const canDownload = radialSeries[0] > 0 || pieSeries[0] > 0;

    return canDownload;
  }, [radialSeries, pieSeries]);

  return (
    <>
      <Card className={classes['card-height']}>
        <CardBody className="h-100">
          <div className="d-flex flex-column h-100">
            <div className="d-flex flex-column h-100 position-relative">
              <div
                className={classnames(classes['respose-rate-header'], {
                  [classes['response-rate-pie']]:
                    responseRateType === ChartTypes[ChartNames.PIE],
                })}
              >
                <div
                  id={Popovers.RESPONSERATE}
                  className={classnames(
                    'd-flex flex-column',
                    classes['cursor-help']
                  )}
                >
                  <span className="font-size-20">
                    {t('responseRateGraph.title')}
                  </span>
                  <span
                    className={classnames(
                      'font-size-12',
                      classes['opacity-75']
                    )}
                  >
                    {t('responseRateGraph.subTitle')}
                  </span>
                </div>
                <ul className="nav nav-pills mt-2 mt-sm-0">
                  <li className="nav-item">
                    <span
                      className={classnames(
                        'nav-link',
                        classes['cursor-pointer'],
                        {
                          active:
                            responseRateType === ChartTypes[ChartNames.RADIAL],
                        }
                      )}
                      onClick={() =>
                        setResponseRateType(ChartTypes[ChartNames.RADIAL])
                      }
                    >
                      {ChartNames.RADIAL}
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={classnames(
                        'nav-link',
                        classes['cursor-pointer'],
                        {
                          active:
                            responseRateType === ChartTypes[ChartNames.PIE],
                        }
                      )}
                      onClick={() =>
                        setResponseRateType(ChartTypes[ChartNames.PIE])
                      }
                    >
                      {ChartNames.PIE}
                    </span>
                  </li>
                </ul>
              </div>
              <ResponseRateChart ref={componentRef} />
              <div className={classes['download-icon']}>
                <DownloadChartDropdown
                  t={t}
                  canDownloadChart={canDownloadChart}
                  PNGData={PNGData}
                  CSVData={CSVData}
                  optionsEnabled={optionsEnabled}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Popover
        target={Popovers.RESPONSERATE}
        header={t('popoverResponseRate.Header')}
        body={t('popoverResponseRate.Body')}
      />
    </>
  );
};

export default withNamespaces()(ResponseRate);
