import firebase from 'firebase/compat/app';
import Cookies from 'js-cookie';
import * as qs from 'query-string';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      if (window.location.hostname === 'localhost') {
        const params = qs.parse(window.location.search);
        if (params.emulator === 'true') {
          Cookies.set('emulator', true);
        }
        if (params.emulator === 'false') {
          Cookies.remove('emulator');
        }

        if (Cookies.get('emulator')) {
          const db = firebase.firestore();
          db.settings({
            host: 'localhost:8080',
            ssl: false,
          });
        }
      }

      onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
        } else {
          localStorage.removeItem('authUser');
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(getAuth(), email, password).then(
        (user) => {
          resolve(getAuth().currentUser);
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(getAuth(), email, password).then(
        (user) => {
          resolve(getAuth().currentUser);
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), email, password).then(
        async (params) => {
          const { uid, emailVerified } = getAuth().currentUser;
          if (!emailVerified) {
            reject(this._handleError({ message: 'Email is not verified' }));
          }
          let user = await firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .get();
          resolve({
            id: uid,
            ...user.data(),
          });
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(getAuth(), email, {
        url: `${window.location.protocol}//${window.location.host}/login`,
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(getAuth())
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) return null;
    return JSON.parse(localStorage.getItem('authUser'));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    const code = error.code;
    const message = error.message;
    return { code, message };
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, firebase };
