import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

// Import scss
import './assets/scss/theme.scss';

// Import Firebase Configuration file
import { initFirebaseBackend } from './helpers/firebase_helper';

import i18n from './i18n';

import Swal from 'sweetalert2';
import { cleanUpToast, setLanguage } from './store/actions';
import Router from './routes/Router';
import { browserLocale } from './helpers/utils/index';
import { LanguagesISOCodes } from './helpers/enums/languages';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

// sweetalert toast
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const App = () => {
  const { icon, title, language } = useSelector(
    (state) => ({
      title: state.toast.title,
      icon: state.toast.icon,
      language: state.preferences.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (title && icon) {
      Toast.fire({
        icon,
        title,
      });
      dispatch(cleanUpToast());
    }
  }, [title, icon, dispatch]);

  useEffect(() => {
    if (!language) {
      const newLanguage = browserLocale || LanguagesISOCodes.EN;
      dispatch(setLanguage(newLanguage));
    }
  }, [dispatch, language]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return <Router />;
};

export default App;
