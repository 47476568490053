const Popovers = {
  RESPONSERATE: 'responseRate',
  TOTALRESPONSES: 'totalResponses',
  EXPECTEDRESPONSES: 'expectedResponses',
  PROCESSINGTHRESHOLD: 'processingThreshold',
  PROCESSEDRESPONSES: 'processedResponses',
  INCOMPLETEORINVALID: 'incompleteOrInvalid',
  RESPONSESDAY: 'responsesDay',
  DEMOGRAPHICSBREAKDOWN: 'demographicsBreakdown',
  SEGMENTATION: 'segmentation',
  PERSONALSAFETYKNOWLEDGE: 'personalSafetyKnowledge',
  SITUATIONALAWARENESS: 'situationalAwareness',
  SAFETYCONSCIOUSNESS: 'safetyConsciousness',
  SAFETYAWARENESS: 'safetyAwareness',
  HIGHRISKGROUP: 'highRiskGroup',
};

export default Popovers;
