import { createReducer } from 'redux-act';

import {
  SURVEYS_FETCH_DEPLOYMENTS_INIT,
  SURVEYS_FETCH_DEPLOYMENTS_SUCCESS,
  SURVEYS_FETCH_DEPLOYMENTS_FAIL,
  SURVEYS_CLEAR_STATE,
} from '../../actions/surveys';

const initialState = {
  data: [],
  loading: false,
  error: null,
  fetched: false,
};

const surveysReducer = createReducer(
  {
    [SURVEYS_FETCH_DEPLOYMENTS_INIT]: (state) => ({
      ...state,
      data: [],
      loading: true,
      fetched: false,
    }),
    [SURVEYS_FETCH_DEPLOYMENTS_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      data: payload.deployments,
    }),
    [SURVEYS_FETCH_DEPLOYMENTS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: payload.error,
    }),
    [SURVEYS_CLEAR_STATE]: () => initialState,
  },
  initialState
);

export default surveysReducer;
