const LanguagesISOCodes = {
  ES: 'es',
  GR: 'gr',
  ZH: 'zh',
  IT: 'it',
  EN: 'en',
  PT: 'pt',
  KO: 'ko',
  YUE: 'yue',
  FR: 'fr',
};

const LanguagesNames = {
  SPANISH: 'Español',
  GERMAN: 'Deutsch',
  CHINESE: '中文简体',
  ITALIAN: 'Italiano',
  ENGLISH: 'English',
  PORTUGUESE: 'Português',
  KOREAN: '한국어',
  CANTONESE: '中文繁體',
  FRENCH: 'Français',
};

export { LanguagesISOCodes, LanguagesNames };
