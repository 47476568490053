import ChartNames from './chartNames';

const ChartTypes = Object.freeze({
  [ChartNames.LINE]: 'line',
  [ChartNames.BAR]: 'bar',
  [ChartNames.AREA]: 'area',
  [ChartNames.RADAR]: 'radar',
  [ChartNames.RADIAL]: 'radialBar',
  [ChartNames.HEATMAP]: 'heatmap',
  [ChartNames.PIE]: 'pie',
  [ChartNames.GAUGE]: 'gauge',
  [ChartNames.ANGLE_CIRCLE]: 'angleCircle',
  [ChartNames.TREEMAP]: 'treemap',
});

export default ChartTypes;
