import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { fetchWaves } from '../../store/actions';

const animatedComponents = makeAnimated();

const WavesDropdown = ({
  wavesToCompare,
  setWavesToCompare,
  cleanSelectedData,
}) => {
  const { wavesOptions, organization } = useSelector(
    (state) => ({
      wavesOptions: state.waves.data.map((wave, index) => ({
        label: wave.name || `wave ${wave.index || index + 1}`,
        value: wave,
      })),
      organization: state.preferences.organization,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      cleanSelectedData();
      dispatch(fetchWaves(organization.id));
    }
  }, [dispatch, cleanSelectedData, organization]);

  const onChangeWaves = useCallback((waves) => setWavesToCompare(waves), [
    setWavesToCompare,
  ]);

  const getSelectValues = useCallback(() => {
    const selectedOptions = [];

    wavesOptions.forEach((waveOption) => {
      wavesToCompare.forEach((wave) => {
        if (waveOption.value.id === wave.value.id) {
          selectedOptions.push(waveOption);
        }
      });
    });

    return selectedOptions;
  }, [wavesToCompare, wavesOptions]);

  return (
    <Select
      isMulti
      name="wavesToCompare"
      className="basic-multi-select"
      classNamePrefix="select"
      components={animatedComponents}
      options={wavesOptions}
      onChange={onChangeWaves}
      value={getSelectValues()}
    />
  );
};

export default WavesDropdown;
