import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import Paths from './paths';
import PrivateRoute from './middleware/PrivateRoute';
import PublicRoute from './middleware/PublicRoute';
import NonAuthLayout from '../components/NonAuthLayout';
import HorizontalLayout from '../components/HorizontalLayout';

// public routes
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import Register from '../pages/Authentication/Register';

// private routes
import Dashboard from '../pages/Dashboard/index';
import Heatmap from '../pages/Heatmap';
import Glossary from '../pages/Glossary';
import UserProfile from '../pages/Authentication/user-profile';
import CompareWaves from '../pages/CompareWaves';

const RouterComponent = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute
        exact
        path={Paths.LOGIN}
        component={Login}
        layout={NonAuthLayout}
      />
      <PublicRoute
        exact
        path={Paths.LOGOUT}
        component={Logout}
        layout={NonAuthLayout}
      />
      <PublicRoute
        exact
        path={Paths.FORGOT_PASSWORD}
        component={ForgetPwd}
        layout={NonAuthLayout}
      />
      <PublicRoute
        exact
        path={Paths.REGISTER}
        component={Register}
        layout={NonAuthLayout}
      />
      <PrivateRoute
        exact
        path={Paths.ROOT}
        component={Dashboard}
        layout={HorizontalLayout}
      />
      <PrivateRoute
        exact
        path={Paths.COMPARE_WAVES}
        component={CompareWaves}
        layout={HorizontalLayout}
      />
      <PrivateRoute
        exact
        path={Paths.GLOSSARY}
        component={Glossary}
        layout={HorizontalLayout}
      />
      <PrivateRoute
        exact
        path={Paths.HEATMAP}
        component={Heatmap}
        layout={HorizontalLayout}
      />
      <PrivateRoute
        exact
        path={Paths.PROFILE}
        component={UserProfile}
        layout={HorizontalLayout}
      />
      <PrivateRoute
        exact
        path={Paths.WAVE_ID}
        component={Dashboard}
        layout={HorizontalLayout}
      />
    </Switch>
  </BrowserRouter>
);

export default RouterComponent;
