import React, {
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useRef,
} from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  fetchGroups,
  fetchResponses,
  fetchWaves,
  setOrganization,
  setWave,
  showToast,
  fetchDeployments,
  fetchDemographics,
  fetchDashboards,
  fetchTags,
  tagsCleanUp,
} from '../../store/actions';
import Paths from '../../routes/paths';
import ToastIcons from '../../helpers/enums/toastIcons';
import TagType from '../../helpers/enums/tagTypes';
import ChartsIds from '../../helpers/enums/chartsIds';
import SurveyResponses from '../AllCharts/SurveyResponses';
import ResponseRate from '../AllCharts/ResponseRate';
import SafetyAwarenessBreakdown from '../AllCharts/SafetyAwarenessBreakdown';
import SafetyAwareness from '../AllCharts/SafetyAwareness';
import BelowThresholdGroup from '../AllCharts/BelowThresholdGroup';
import AreasOfRisks from '../AllCharts/AreasOfRisks';
import DemographicsBreakdown from '../AllCharts/DemographicsBreakdown';
import Segmentation from '../AllCharts/Segmentation';
import Reports from '../AllCharts/Reports';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import WaveDatesSlider from '../../components/WaveDatesSlider';
import classes from './dashboard.module.scss';
import './dashboard.scss';

const Dashboard = ({ t }) => {
  const {
    organizations,
    organization,
    waves,
    wave,
    emptyWaves,
    emptyOrganizations,
    wavesError,
    responses,
    demographics,
    layout,
    surveysHits,
  } = useSelector(
    ({
      preferences,
      demographics,
      organizations,
      waves,
      responses,
      surveys,
    }) => ({
      organization: preferences.organization,
      layout: preferences.organization?.layout,
      wave: preferences.wave,
      organizations: organizations.data,
      emptyOrganizations: organizations.data.length === 0,
      demographics: demographics.data,
      waves: waves.data,
      emptyWaves: waves.data.length === 0,
      wavesError: waves.error,
      responses: responses.data,
      surveysHits: surveys.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { waveId } = useParams();
  const history = useHistory();

  const waveStateId = wave?.id;

  // Sets url param to "/" when waveId is equal with the wave id on state
  useEffect(() => {
    if (waveId === waveStateId) {
      history.push(Paths.ROOT);
    }
  }, [history, waveId, waveStateId]);

  // Fetch wave if the url has waveId
  useEffect(() => {
    if (waveId) {
      if (emptyWaves) {
        dispatch(fetchWaves(null, waveId));
      }

      if (!emptyWaves && waves[0].id !== waveId) {
        const waveExistInCurrentWaves = waves.some(
          (wave) => wave.id === waveId
        );

        if (!waveExistInCurrentWaves) {
          dispatch(fetchWaves(null, waveId));
        }
      }
    }
  }, [dispatch, waveId, waves, emptyWaves]);

  // Show toast if the waveId does not exists and clear the waveId from the url
  useEffect(() => {
    if (waveId) {
      if (wavesError) {
        dispatch(showToast(ToastIcons.ERROR, wavesError));
        history.push(Paths.ROOT);
      }
    }
  }, [waveId, wavesError, history, dispatch]);

  // Set the organization that correspond to the waveId in the url once the wave was fetched
  useEffect(() => {
    if (waveId) {
      if (!emptyWaves && waves[0].id === waveId) {
        if (organization && organization.id !== waves[0].organizationId) {
          const newOrganization = organizations.filter(
            (org) => org.id === waves[0].organizationId
          );
          dispatch(setOrganization(newOrganization[0]));
        }

        if (!organization && !emptyOrganizations) {
          const newOrganization = organizations.filter(
            (org) => org.id === waves[0].organizationId
          );
          dispatch(setOrganization(newOrganization[0]));
        }
      }
    }
  }, [
    dispatch,
    waveId,
    organization,
    organizations,
    waves,
    emptyWaves,
    emptyOrganizations,
  ]);

  // Set the wave from the url into the store->preferences->wave
  useEffect(() => {
    if (waveId) {
      if (!emptyWaves) {
        const waveExistsInCurrentWaves = waves.filter(
          (wave) => wave.id === waveId
        );

        const newWave =
          waveExistsInCurrentWaves.length > 0
            ? waveExistsInCurrentWaves[0]
            : waves[0];

        if (!wave) {
          dispatch(setWave(newWave));
        }

        if (wave && wave.id !== waveId) {
          dispatch(setWave(newWave));
        }
      }
    }
  }, [dispatch, waves, waveId, wave, emptyWaves]);

  // Fetch the rest of the waves from the organization
  useEffect(() => {
    if (waveId) {
      if (wave && wave.id === waveId) {
        if (organization) {
          dispatch(fetchWaves(organization.id));
        }
      }
    }
  }, [dispatch, wave, waveId, organization]);

  useEffect(() => {
    if (!waveId) {
      if (!emptyOrganizations && !organization) {
        dispatch(setOrganization(organizations[0]));
      }
    }
  }, [organizations, organization, dispatch, waveId, emptyOrganizations]);

  useEffect(() => {
    if (!waveId) {
      if (organization) {
        dispatch(fetchWaves(organization.id));
      }
    }
  }, [dispatch, organization, waveId]);

  useEffect(() => {
    if (organization) {
      const filterInactives = true;
      dispatch(fetchGroups(filterInactives, organization.displayName));
      dispatch(tagsCleanUp());
      dispatch(
        fetchTags({
          filterByTypes: [TagType.CONSTRUCT, TagType.DEMOGRAPHIC],
          filterByOrganizations: [organization.displayName, 'global'],
        })
      );
    }
  }, [dispatch, organization]);

  useEffect(() => {
    if (!waveId) {
      if (organization && !emptyWaves && !wave) {
        const currentWave = waves.filter(
          (wave) => wave.index === organization.currentWave
        );
        dispatch(setWave(currentWave[0]));
      }
    }
  }, [dispatch, wave, waves, organization, waveId, emptyWaves]);

  useEffect(() => {
    if (emptyWaves && wave) {
      dispatch(setWave(null));
    }
  }, [dispatch, emptyWaves, wave]);

  useEffect(() => {
    if (wave) {
      dispatch(fetchDeployments([wave.id]));
      dispatch(fetchResponses([wave.id]));
      dispatch(fetchDemographics([wave.id]));
      dispatch(fetchDashboards([wave.id]));
    }
  }, [dispatch, wave]);

  const charts = useMemo(
    () => ({
      [ChartsIds.FILTER_RESPONSES_BY_DATE]: (
        <WaveDatesSlider key="filter-responses-by-date" />
      ),
      [ChartsIds.RESPONSE_RATE]: (
        <ResponseRate wave={wave} responses={responses} />
      ),
      [ChartsIds.SURVEY_RESPONSES]: (
        <SurveyResponses wave={wave} hits={surveysHits} responses={responses} />
      ),
      reports: <Reports wave={wave} key="reports" />,
      segmentation: <Segmentation wave={wave} responses={responses} />,
      [ChartsIds.DEMOGRAPHICS_BREAKDOWN]: (
        <DemographicsBreakdown
          wave={wave}
          responses={responses}
          demographics={demographics}
        />
      ),

      [ChartsIds.SAFETY_AWARENESS_BREAKDOWN]: (
        <SafetyAwarenessBreakdown wave={wave} />
      ),
      [ChartsIds.SAFETY_AWARENESS]: <SafetyAwareness wave={wave} />,
      [ChartsIds.BELOW_THRESHOLD_GROUP]: <BelowThresholdGroup wave={wave} />,
      [ChartsIds.AREAS_OF_RISKS]: <AreasOfRisks wave={wave} />,
    }),
    [demographics, responses, wave, surveysHits]
  );

  const adjustChartsWidth = useCallback(
    (columnId, remainingSpace, rowCharts) => {
      const numberOfCharts = rowCharts.length;
      const spaceForEachChart = remainingSpace / numberOfCharts;

      if (Number.isInteger(spaceForEachChart)) {
        return rowCharts.map((chart) => ({
          [chart.id]: (
            <Col key={chart.id} xl={chart.weight + spaceForEachChart}>
              {charts[chart.id]}
            </Col>
          ),
        }));
      }

      const spaceForBiggestChart = Math.ceil(spaceForEachChart);
      const biggestChart = rowCharts.reduce((a, b) =>
        a.weight > b.weight ? a : b
      );
      const leftSpace = remainingSpace - spaceForBiggestChart;
      const leftCharts = rowCharts.filter(({ id }) => id !== biggestChart.id);

      const bigChart = {
        [biggestChart.id]: (
          <Col
            key={biggestChart.id}
            xl={biggestChart.weight + spaceForBiggestChart}
          >
            {charts[biggestChart.id]}
          </Col>
        ),
      };

      return [bigChart, ...adjustChartsWidth(columnId, leftSpace, leftCharts)];
    },
    [charts]
  );

  const renderCharts = useCallback(
    (columnId) => {
      const rowCharts = layout.columns[columnId].chartsIds.map(
        (chartId) => layout.charts[chartId]
      );
      const totalSpace = 12;
      const remainingSpace =
        totalSpace - rowCharts.reduce((a, b) => a + b.weight, 0);

      if (remainingSpace > 0) {
        const adjustedCharts = adjustChartsWidth(
          columnId,
          remainingSpace,
          rowCharts
        );

        const sortedCharts = layout.columns[columnId].chartsIds.map((chartId) =>
          adjustedCharts.map((chart) => chart[chartId])
        );

        return sortedCharts;
      }

      return rowCharts.map((chart) =>
        chart.id === ChartsIds.FILTER_RESPONSES_BY_DATE ||
        chart.id === ChartsIds.REPORTS ? (
          charts[chart.id]
        ) : (
          <Col key={chart.id} xl={chart.weight}>
            {charts[chart.id]}
          </Col>
        )
      );
    },
    [layout, charts, adjustChartsWidth]
  );

  const componentRef = useRef();

  const DashboardCharts = useMemo(
    () =>
      forwardRef((_, ref) => (
        <div id="dashboard" className="page-content" ref={ref}>
          <Container fluid>
            <Breadcrumbs
              title={t('breadcrumb.dashboard')}
              componentRef={componentRef}
            />
            {layout?.columnOrder.map(
              (columnId) =>
                columnId !== 'storage' &&
                layout.columns[columnId].chartsIds.length > 0 && (
                  <Row
                    key={columnId}
                    className={classNames({
                      [classes['wave-dates-slider']]:
                        layout.columns[columnId].chartsIds[0] ===
                        ChartsIds.FILTER_RESPONSES_BY_DATE,
                    })}
                  >
                    {renderCharts(columnId)}
                  </Row>
                )
            )}
          </Container>
        </div>
      )),
    [layout, renderCharts, t]
  );

  return <DashboardCharts ref={componentRef} />;
};

export default withNamespaces()(Dashboard);
