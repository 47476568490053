const DashboardCharts = {
  RESPONSE_RATE: 'Response Rate',
  SURVEY_RESPONSES: 'Survey Responses',
  REPORTS: 'Reports',
  DEMOGRAPHICS_BREAKDOWN: 'Demographics Breakdown',
  SEGMENTATION: 'Segmentation',
  SAFETY_AWARENESS_BREAKDOWN: 'Safety Awareness Breakdown',
  SAFETY_AWARENESS: 'Safety Awareness',
  BELOW_THRESHOLD_GROUP: 'Below Threshold Group',
  AREAS_OF_RISKS: 'Areas of Risk',
};

export default DashboardCharts;
