import { createSelector } from 'reselect';

import { dateBetweenPeriod } from '../../helpers/functions/functions_helper';
import { checkUserIsDashboardAdmin } from '../../helpers/utils';

const selectGroupState = (state) => state.groups;
const selectPreferencesState = (state) => state.preferences;
const selectDashboardsState = (state) => state.dashboards;
const selectLoginState = (state) => state.Login;

export const segmentationsSelector = createSelector(
  selectGroupState,
  selectPreferencesState,
  selectDashboardsState,
  selectLoginState,
  (_, noStoreExtraState) => noStoreExtraState,
  (
    { data: allGroups },
    {
      demographic: selectedDemographic,
      group: selectedGroup,
      datesFromWave: selectedWaveDates,
    },
    { data: dashboards },
    { userData },
    { responses, wave }
  ) => {
    const dashboard = dashboards?.find(({ waveId }) => waveId === wave?.id);

    if (selectedWaveDates) {
      const { startDate, endDate } = selectedWaveDates;
      responses = responses.filter(({ createdAt }) =>
        dateBetweenPeriod(createdAt, startDate, endDate)
      );
    }

    const userIsAdmin = checkUserIsDashboardAdmin(userData);

    const { hierarchy } = wave ?? [];

    const segmentations = getSegmentations(
      allGroups,
      responses,
      selectedDemographic,
      selectedGroup,
      dashboard,
      hierarchy,
      userIsAdmin
    );

    return {
      segmentations,
    };
  }
);

const getSegmentations = (
  allGroups,
  responses,
  selectedDemographic,
  selectedGroup,
  dashboard,
  hierarchy,
  userIsAdmin
) => {
  const Overall = dashboard?.Scores?.Overall;
  const segmentations = [];
  let responsesWithGroups = 0;
  let allGroupsExpectedResponses = 0;
  let allGroupsCleanResponses = 0;
  let responsesWithDemographic = 0;
  let groups = allGroups;

  const groupsWithResponses = allGroups.filter((group) =>
    responses.some(({ groups }) => groups.includes(group.id))
  );

  groups = groupsWithResponses;

  // Related with hierarchy
  // if (selectedGroup && selectedWave) {
  //   const groupInHierarchy = getGroupFromHierarchy(
  //     selectedWave.hierarchy,
  //     selectedGroup
  //   );

  //   const groupIds = getChildrenGroupIds(groupInHierarchy);

  //   const groupsIncludedInHierarchy = groups.filter(({ id }) =>
  //     groupIds.includes(id)
  //   );

  //   groups = groupsIncludedInHierarchy;
  // }

  if (selectedGroup) {
    const group = allGroups.find(({ id }) => id === selectedGroup?.id);

    groups = [group];
  }

  if (hierarchy?.length) {
    hierarchy.forEach((parentHierarchy) => {
      const { groupId: hierarchyGroupId, children } = parentHierarchy;

      if (Overall) {
        const groupData = Object.entries(Overall.groups).find(
          ([groupId]) => groupId === hierarchyGroupId
        )?.[1];

        if (groupData) {
          const group = groups.find(({ id }) => id === hierarchyGroupId);

          const groupResponses = [];
          let groupValidResponses = 0;

          if (!group) {
            return;
          }

          if (children?.length && responses?.length) {
            const childrenResponses = getChildrenResponses(children, responses);

            groupResponses.push(...childrenResponses);
          }

          responses.forEach((response) => {
            const { groups, valid } = response;

            const responseIsFromGroup = groups.includes(group.id);

            if (responseIsFromGroup) {
              groupResponses.push(response);
            }

            if (responseIsFromGroup && valid) {
              groupValidResponses += 1;
            }
          });

          if (!userIsAdmin && groupResponses.length < 10) {
            return;
          }

          const clean =
            groupResponses.length !== 0
              ? parseFloat(
                  (groupValidResponses * 100) / groupResponses.length
                ).toFixed(1)
              : '-';

          const rate =
            responses.length !== 0
              ? parseFloat(
                  (groupResponses.length * 100) / responses.length
                ).toFixed(1)
              : '-';

          const expected = group?.expectedCount || 0;

          allGroupsExpectedResponses += expected;
          allGroupsCleanResponses += groupValidResponses;
          responsesWithGroups += groupResponses.length;

          if (selectedDemographic?.tagId) {
            const responsesHaveDemographic = groupResponses.filter(
              (response) =>
                response.demographics &&
                response.demographics.some(
                  ({ demographicId, name }) =>
                    demographicId === selectedDemographic.tagId &&
                    name.includes(selectedDemographic.choice)
                )
            );

            if (responsesHaveDemographic.length > 0) {
              responsesWithDemographic += responsesHaveDemographic.length;
              const segmentation = {
                id: group.id,
                group: group.displayName,
                responses: responsesHaveDemographic.length,
                expected,
                clean,
                rate,
              };

              segmentations.push(segmentation);
            }
          } else {
            const segmentation = {
              id: group.id,
              group: group.displayName,
              responses: groupResponses.length,
              expected,
              clean,
              rate,
            };

            segmentations.push(segmentation);
          }
        }
      }
    });
  }

  const responsesWithoutGroups = {
    group: 'Other Responses',
    responses:
      responses.length - (responsesWithDemographic || responsesWithGroups),
    expected: '-',
    clean: '-',
    rate:
      responses.length !== 0
        ? parseFloat(
            ((responses.length - responsesWithGroups) * 100) / responses.length
          ).toFixed(1)
        : '-',
  };

  const overall = {
    group: 'Overall',
    responses: responses.length,
    expected: allGroupsExpectedResponses,
    clean:
      responsesWithGroups !== 0
        ? parseFloat(
            (allGroupsCleanResponses * 100) / responsesWithGroups
          ).toFixed(1)
        : '-',
    rate:
      responses.length !== 0
        ? parseFloat(
            (responsesWithoutGroups.rate !== '-'
              ? Number(responsesWithoutGroups.rate)
              : 0) +
              (responsesWithGroups * 100) / responses.length
          ).toFixed(1)
        : '-',
  };

  const segmentationsResponsesWithoutGroups =
    responsesWithoutGroups.responses > 0 ? [responsesWithoutGroups] : [];

  segmentations.push(...segmentationsResponsesWithoutGroups, overall);

  return segmentations;
};

const getChildrenResponses = (currentHierarchy, responses, acc = []) => {
  // eslint-disable-next-line consistent-return
  currentHierarchy.forEach((hierarchy) => {
    const { children, groupId } = hierarchy;

    const responsesWithGroupId = responses.filter(({ groups }) =>
      groups.includes(groupId)
    );

    acc.push(...responsesWithGroupId);

    if (!children?.length) {
      return acc;
    }

    children.forEach((hierarchy) => {
      getChildrenResponses(hierarchy, responses, acc);
    });
  });

  return acc;
};
