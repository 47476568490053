import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import classes from './tooltip.module.scss';

const railStyle = {
  position: 'absolute',
  width: '100%',
  transform: 'translate(0%, -50%)',
  height: 40,
  cursor: 'pointer',
  zIndex: 3,
};

const railCenterStyle = {
  position: 'absolute',
  width: '100%',
  transform: 'translate(0%, -50%)',
  height: 14,
  borderRadius: 7,
  cursor: 'pointer',
  pointerEvents: 'none',
  backgroundColor: '#dcdcdc',
};

const TooltipRail = ({
  activeHandleID,
  getRailProps,
  waveDates,
  getEventData,
}) => {
  const [state, setState] = useState({
    value: null,
    percent: null,
  });

  const onMouseMove = useCallback(
    (e) => {
      if (activeHandleID) {
        setState({ value: null, percent: null });
      } else {
        setState(getEventData(e));
      }
    },
    [getEventData]
  );

  const onMouseEnter = useCallback(() => {
    document.addEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);

  const onMouseLeave = useCallback(() => {
    setState({ value: null, percent: null });
    document.removeEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);

  const date = waveDates.length > 0 ? waveDates[state.value - 1] : null;
  const dateString = date?.toDateString();

  return (
    <>
      {!activeHandleID && state.value && (
        <div
          style={{
            left: `${state.percent}%`,
            position: 'absolute',
            marginLeft: '-11px',
            marginTop: '-35px',
          }}
        >
          <div className={classes.tooltip}>
            <span className={classes.tooltiptext}>{dateString}</span>
          </div>
        </div>
      )}
      <div
        style={railStyle}
        {...getRailProps({
          onMouseEnter,
          onMouseLeave,
        })}
      />
      <div style={railCenterStyle} />
    </>
  );
};

TooltipRail.propTypes = {
  getEventData: PropTypes.func,
  activeHandleID: PropTypes.string,
  getRailProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TooltipRail.defaultProps = {
  disabled: false,
};

export default TooltipRail;
