import { firebase } from '../firebase_helper';
import DASHBOARDS_FIELDS from '../enums/dashboardFields';
import Roles from '../enums/roles';

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const createDashboardData = (waveId, groupId) => async () => {
  const dashboardData = {
    Group: {
      [groupId]: {
        Aa: null,
        AfA: 0,
        BelowThresholdGroup: 0,
        Ca: null,
        Count: 30,
        CrA: null,
        Ea: 0,
        Fa: 0,
        Ha: 0,
        PersonalSafetyKnowledge: 0,
        Ra: null,
        SafetyAwareness: 0,
        SafetyConsciousness: 0,
        SituationalAwareness: 0,
        Ta: null,
        dtype: 'float64',
      },
      Overall: {
        Aa: null,
        AfA: 15,
        BelowThresholdGroup: 20,
        Ca: null,
        Count: 30,
        CrA: null,
        Ea: 25,
        Fa: 40,
        Ha: 55,
        PersonalSafetyKnowledge: 67,
        Ra: null,
        SafetyAwareness: 14,
        SafetyConsciousness: 44,
        SituationalAwareness: 31,
        Ta: null,
        dtype: 'float64',
      },
    },
    createTime: firebase.firestore.FieldValue.serverTimestamp(),
    waveId,
  };

  try {
    await firebase
      .firestore()
      .collection('dashboards')
      .doc(waveId)
      .set(dashboardData);
  } catch (error) {
    console.log(error);
  }
};

export const getDownloadChartFileName = (
  chartName,
  waveName = '',
  organizationName = ''
) => {
  const chart = chartName.trim().toLocaleLowerCase().replace(/\s+/g, '_');
  const wave = waveName.trim().toLocaleLowerCase().replace(/\s+/g, '_');
  const organization = organizationName
    .trim()
    .toLocaleLowerCase()
    .replace(/\s+/g, '_');

  return `${chart}_${wave}_${organization}`;
};

export const TablesHeight = {
  TABLE: 22,
  CARD_BODY: 490,
};

export const DownloadOptions = {
  PNG: 'PNG',
  CSV: 'CSV',
  PDF: 'PDF',
};

export const getResumedNamesFromDashboardGroup = (data) => {
  const returnData = {};

  Object.entries(DASHBOARDS_FIELDS).forEach(([key, value]) => {
    returnData[key] = data[value] ? Math.ceil(Number(data[value])) : 0;
  });

  return returnData;
};

export const isFloat = (value) =>
  typeof value === 'number' && !isNaN(value) && value % 1 !== 0;

export const sortGroupsArrayAlphanumeric = (a, b) => {
  const { name: aName } = a;
  const { name: bName } = b;

  const aIsFirst = -1;
  const bIsFirst = 1;
  const staysEqual = 0;

  if (aName < bName) {
    return bIsFirst;
  }

  if (aName > bName) {
    return aIsFirst;
  }

  return staysEqual;
};

export const checkUserIsDashboardAdmin = (userData) =>
  userData.role === Roles.ADMIN || userData.role === Roles.PORTALADMIN;
