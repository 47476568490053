import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import Handle from './SliderComponents/Handle';
import Tick from './SliderComponents/Tick';
import Track from './SliderComponents/Track';
import TooltipRail from './SliderComponents/TooltipRail';
import { getDaysBetweenDates } from '../../helpers/functions/functions_helper';
import { cleanUpDatesFromWaves, setDatesFromWave } from '../../store/actions';
import { useWindowSize } from '../../hooks/index';
import classes from './WaveDatesSlider.module.scss';

const sliderStyle = {
  position: 'relative',
  width: '90%',
};

const WaveDatesSlider = () => {
  const { wave } = useSelector(
    (state) => ({
      wave: state.preferences.wave,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const size = useWindowSize();

  const [waveDates, setWaveDates] = useState([]);

  const domain = useMemo(() => [1, waveDates.length], [waveDates]);
  const defaultValues = useMemo(() => [1, waveDates.length], [waveDates]);

  const getDatesFromWave = useCallback(() => {
    const datesArray = [];

    if (wave) {
      const { startingOn, until } = wave;
      const today = new Date();
      let daysBetweenDates = 0;

      if (!until || until > today) {
        daysBetweenDates = getDaysBetweenDates(new Date(startingOn), today);
      } else {
        daysBetweenDates = getDaysBetweenDates(
          new Date(startingOn),
          new Date(until)
        );
      }

      datesArray.push(new Date(wave.startingOn));

      for (let i = 0; i < daysBetweenDates; i++) {
        let nextDay = new Date(datesArray[datesArray.length - 1]);
        nextDay.setDate(nextDay.getDate() + 1);
        datesArray.push(nextDay);
      }
    }

    setWaveDates(datesArray);
  }, [wave]);

  useEffect(() => {
    if (wave) {
      getDatesFromWave();
    }
  }, [wave, getDatesFromWave]);

  const onChange = useCallback(
    (dates) => {
      const start = dates[0] === 0 ? 0 : dates[0] - 1;
      const end = dates[1] === 0 ? 0 : dates[1] - 1;

      if (waveDates.length > 0) {
        const datesFromWave = {
          startDate: waveDates[start],
          endDate: waveDates[end],
        };

        if (datesFromWave.startDate && datesFromWave.endDate) {
          dispatch(setDatesFromWave(datesFromWave));
        }
      }
    },
    [dispatch, waveDates]
  );

  useEffect(() => {
    return () => {
      dispatch(cleanUpDatesFromWaves());
    };
  }, [dispatch]);

  const ticksNumber = useMemo(() => (size.width < 768 ? 4 : 10), [size.width]);

  return (
    <div className={classes['slider-container']}>
      <div className={classes.title}>Filter responses by date</div>
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={onChange}
        values={defaultValues}
      >
        <Rail>
          {(railProps) => <TooltipRail {...railProps} waveDates={waveDates} />}
        </Rail>
        <Handles>
          {({ handles, activeHandleID, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  isActive={handle.id === activeHandleID}
                  getHandleProps={getHandleProps}
                  waveDates={waveDates}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={ticksNumber}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  waveDates={waveDates}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};

export default WaveDatesSlider;
