import ChartNames from './chartNames';

const categories = [
  'Instruments and Equipment',
  'Knowing Procedures',
  'Knowing Limits',
  'Relying on Experience',
  'Gut Feeling',
  'Seeing Threats',
  'Adjusting to Threats',
  'Keeping Eachother Safe',
  'Company Support',
];

const categoriesMobile = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

const initialRadarOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radar: {
      offsetY: -80,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
    categories,
  },
  yaxis: {
    labels: { formatter: (value) => (value === null ? 'N/A' : value) },
  },
  colors: ['#556ee6'],
  responsive: [
    {
      breakpoint: 321,
      options: {
        plotOptions: {
          radar: {
            offsetY: -220,
          },
        },
      },
    },
    {
      breakpoint: 415,
      options: {
        plotOptions: {
          radar: {
            offsetY: -195,
          },
        },
      },
    },
    {
      breakpoint: 541,
      options: {
        plotOptions: {
          radar: {
            offsetY: -130,
          },
        },
        xaxis: {
          categories: categoriesMobile,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
      },
    },
  ],
});

const initialBarOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  colors: ['#556ee6'],
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories,
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 165,
      maxWidth: 165,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
});

const initialColumnOptions = Object.freeze({
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
    },
  },
  colors: ['#556ee6'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
  },
  grid: {
    row: {
      colors: ['#fff', '#f2f2f2'],
    },
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 80,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
  xaxis: {
    labels: {
      rotate: -45,
      show: true,
      hideOverlappingLabels: false,
      rotateAlways: true,
      trim: false,
      minHeight: 150,
    },
    categories,
  },
  responsive: [
    {
      breakpoint: 540,
      options: {
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: false,
            rotateAlways: true,
            trim: false,
            minHeight: 180,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
      },
    },
  ],
});

const initialLineOptions = Object.freeze({
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  colors: ['#556ee6'],
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -45,
      hideOverlappingLabels: false,
      rotateAlways: true,
      trim: false,
      minHeight: 150,
    },
    categories,
  },
  yaxis: {
    labels: {
      show: true,
      minWidth: 110,
      maxWidth: 110,
      formatter: (value) => (value === null ? 'N/A' : value),
    },
  },
  responsive: [
    {
      breakpoint: 540,
      options: {
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => (value === null ? 'N/A' : value),
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: false,
            rotateAlways: true,
            trim: false,
            minHeight: 180,
          },
        },
      },
    },
  ],
});

export const initialChartsOptions = Object.freeze({
  [ChartNames.RADAR]: initialRadarOptions,
  [ChartNames.BAR]: initialBarOptions,
  [ChartNames.COLUMN]: initialColumnOptions,
  [ChartNames.LINE]: initialLineOptions,
});
