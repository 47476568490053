import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';
import { loginUser } from '../../auth/login/actions';

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const setPassword = (email, password, url, history) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_SET_PASSWORD_INIT());

    try {
      await firebase.auth().signInWithEmailLink(email, url);
    } catch (error) {
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error }));
    }

    const user = firebase.auth().currentUser;

    try {
      await user.updatePassword(password);
    } catch (error) {
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error }));
    }

    dispatch(AUTH_SET_PASSWORD_SUCCESS());

    return dispatch(loginUser({ email, password }, history));
  };
};
