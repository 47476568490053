import { createReducer } from 'redux-act';

import {
  RESPONSES_CLEAR_STATE,
  RESPONSES_FETCH_FAIL,
  RESPONSES_FETCH_INIT,
  RESPONSES_FETCH_SUCCESS,
} from '../../actions/responses';

const initialState = {
  data: [],
  loading: false,
  error: null,
  fetched: false,
};

const responsesReducer = createReducer(
  {
    [RESPONSES_FETCH_INIT]: (state) => ({
      ...state,
      data: [],
      loading: true,
      fetched: false,
    }),
    [RESPONSES_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      data: payload.responses,
    }),
    [RESPONSES_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      fetched: true,
      error: payload.error,
    }),
    [RESPONSES_CLEAR_STATE]: () => initialState,
  },
  initialState
);

export default responsesReducer;
