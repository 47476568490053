import { createSelector } from 'reselect';

import { checkUserIsDashboardAdmin } from '../../helpers/utils';

const selectPreferencesState = (state) => state.preferences;
const selectResponsesState = (state) => state.responses;
const selectLoginState = (state) => state.Login;

export const selectTopHierarchyGroupsWithResponses = createSelector(
  selectPreferencesState,
  selectResponsesState,
  selectLoginState,
  ({ wave }, responses, { userData }) => {
    const { hierarchy } = wave ?? {};

    if (!hierarchy?.length) {
      return {
        groups: [],
      };
    }
    const userIsAdmin = checkUserIsDashboardAdmin(userData);

    const parentGroups = hierarchy.map(({ groupId, groupName }) => ({
      groupId,
      groupName,
    }));

    const groupsWithResponses = parentGroups.filter(({ groupId }) => {
      const groupResponses = responses?.data.filter(({ groups }) =>
        groups.includes(groupId)
      );

      return userIsAdmin || groupResponses?.length >= 10;
    });

    return {
      groups: groupsWithResponses,
    };
  }
);
