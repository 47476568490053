import moment from 'moment';

const getDaysBetweenDates = (startingDate, untilDate) => {
  const start = moment(startingDate).startOf('day');
  const until = moment(untilDate).startOf('day');
  return until.diff(start, 'days');
};

const getGroupFromHierarchy = (hierarchies, group) => {
  let groupFromHierarchy = [];

  hierarchies &&
    Array.isArray(hierarchies) &&
    hierarchies.forEach((hierarchy) => {
      const groupFound = findGroup(hierarchy, group);

      if (groupFound.length > 0) {
        groupFromHierarchy = groupFound;
      }
    });

  return groupFromHierarchy;
};

const findGroup = (hierarchy, group) => {
  if (group?.id === hierarchy.groupId) {
    return [hierarchy];
  }

  return getGroupFromHierarchy(hierarchy.children, group);
};

const getChildrenGroupIds = (groupChildren) => {
  const groupsIds = [];

  groupChildren.forEach((hierarchy) => {
    const groupIds = getGroupId(hierarchy);
    groupsIds.push(...groupIds);
  });

  return groupsIds;
};

const getGroupId = (hierarchy) => {
  const { children, groupId } = hierarchy;

  if (children.length > 0) {
    const groupIds = getGroupId(children[0]);

    return [groupId, ...groupIds];
  }

  return [groupId];
};

const dateBetweenPeriod = (date, startDate, endDate) =>
  new Date(date).setHours(0, 0, 0, 0) >=
    new Date(startDate).setHours(0, 0, 0, 0) &&
  new Date(date).setHours(0, 0, 0, 0) <= new Date(endDate).setHours(0, 0, 0, 0);

export {
  getDaysBetweenDates,
  getGroupFromHierarchy,
  getChildrenGroupIds,
  dateBetweenPeriod,
};
