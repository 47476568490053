import { createReducer } from 'redux-act';

import {
  AUTH_SET_PASSWORD_INIT,
  AUTH_SET_PASSWORD_SUCCESS,
  AUTH_SET_PASSWORD_FAIL,
} from '../../actions/auth';

const initialState = {
  loading: false,
  error: null,
};

const authReducer = createReducer(
  {
    [AUTH_SET_PASSWORD_INIT]: (state) => ({ ...state, loading: true }),
    [AUTH_SET_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
    [AUTH_SET_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);

export default authReducer;
