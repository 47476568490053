import { createAction } from 'redux-act';

export const PREFERENCES_SET_ORGANIZATION = createAction(
  'PREFERENCES_SET_ORGANIZATION'
);
export const PREFERENCES_SET_WAVE = createAction('PREFERENCES_SET_WAVE');
export const PREFERENCES_SET_GROUP = createAction('PREFERENCES_SET_GROUP');
export const PREFERENCES_SET_DEMOGRAPHIC = createAction(
  'PREFERENCES_SET_DEMOGRAPHIC'
);
export const PREFERENCES_CLEAN_UP = createAction('PREFERENCES_CLEAN_UP');
export const PREFERENCES_SET_LANGUAGE = createAction(
  'PREFERENCES_SET_LANGUAGE'
);
export const PREFERENCES_SET_DATES_FROM_WAVES = createAction(
  'PREFERENCES_SET_DATES_FROM_WAVES'
);
export const PREFERENCES_CLEAN_UP_DATES_FROM_WAVES = createAction(
  'PREFERENCES_CLEAN_UP_DATES_FROM_WAVES'
);
export const PREFERENCES_CLEAN_UP_DEMOGRAPHIC = createAction(
  'PREFERENCES_CLEAN_UP_DEMOGRAPHIC'
);

export const setOrganization = (organization) => (dispatch) => {
  return dispatch(PREFERENCES_SET_ORGANIZATION({ organization }));
};

export const setWave = (wave) => (dispatch) => {
  return dispatch(PREFERENCES_SET_WAVE({ wave }));
};

export const setGroup = (group) => (dispatch) => {
  return dispatch(PREFERENCES_SET_GROUP({ group }));
};

export const setDemographic = (demographic) => (dispatch) => {
  return dispatch(PREFERENCES_SET_DEMOGRAPHIC({ demographic }));
};

export const preferencesCleanUp = () => (dispatch) =>
  dispatch(PREFERENCES_CLEAN_UP());

export const setLanguage = (language) => (dispatch) => {
  return dispatch(PREFERENCES_SET_LANGUAGE({ language }));
};

export const setDatesFromWave = (datesFromWave) => (dispatch) => {
  return dispatch(PREFERENCES_SET_DATES_FROM_WAVES({ datesFromWave }));
};

export const cleanUpDatesFromWaves = () => (dispatch) =>
  dispatch(PREFERENCES_CLEAN_UP_DATES_FROM_WAVES());

export const cleanUpDemographic = () => (dispatch) =>
  dispatch(PREFERENCES_CLEAN_UP_DEMOGRAPHIC());
