const Paths = {
  WAVE_ID: '/:waveId',
  HEATMAP: '/heatmap',
  PROFILE: '/profile',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/register',
  ROOT: '/',
  GLOSSARY: '/glossary',
  COMPARE_WAVES: '/compare-waves',
};

export default Paths;
