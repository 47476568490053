import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import defaultLogo from '../../../assets/images/user-default-logo.svg';

const ProfileMenu = (props) => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.Login.userData,
    }),
    shallowEqual
  );

  const [menu, setMenu] = useState(false);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userData.logoUrl || defaultLogo}
            alt=""
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">
            {`${userData.firstName} ${userData.lastName}`}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/profile" className="dropdown-item">
            {' '}
            <i className="bx bx-user font-size-16 align-middle mr-1" />
            {props.t('Profile')}{' '}
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withRouter(withNamespaces()(ProfileMenu));
