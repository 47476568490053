import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Collapse, Spinner } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import isEqual from 'lodash.isequal';
import classnames from 'classnames';
import classNames from 'classnames';

import {
  setGroup,
  setWave,
  setOrganization,
  wavesCleanUp,
  groupsCleanUp,
  showToast,
  fetchDemographics,
  setDemographic,
  cleanUpDatesFromWaves,
  cleanUpDemographic,
  demographicsClearState,
} from '../../store/actions';
import ToastIcons from '../../helpers/enums/toastIcons';
import { checkUserIsDashboardAdmin } from '../../helpers/utils';
import Roles from '../../helpers/enums/roles';
import Paths from '../../routes/paths';

import classes from './Navbar.module.scss';
import { selectTopHierarchyGroupsWithResponses } from '../../store/selectors/hierarchy';

const getChoicesWithEnoughResponses = (demographicChoices, userIsAdmin) => {
  const choicesWithEnoughResponses = [];

  demographicChoices.forEach(([choice, choiceData]) => {
    if (!userIsAdmin && choiceData.count >= 10) {
      choicesWithEnoughResponses.push(choice);
    }

    if (userIsAdmin) {
      choicesWithEnoughResponses.push(choice);
    }
  });

  return choicesWithEnoughResponses;
};

const sortDemographicsByChoicesOrder = (
  formattedDemographics,
  surveyItems,
  language
) => {
  const selectedSurveyItem = surveyItems.find(
    (item) =>
      item.question?.demographicsId?.includes(formattedDemographics.tagId) ||
      item.questionGroup?.demographicsId?.includes(formattedDemographics.tagId)
  );

  formattedDemographics.choices.sort((a, b) => {
    const aChoice = (
      selectedSurveyItem.question || selectedSurveyItem.questionGroup
    )?.choices.find(
      (choice) => (choice.option?.[language] || choice.option.en) === a
    );
    const bChoice = (
      selectedSurveyItem.question || selectedSurveyItem.questionGroup
    )?.choices.find(
      (choice) => (choice.option?.[language] || choice.option.en) === b
    );

    if (aChoice === undefined || bChoice === undefined) {
      return 0;
    }

    return aChoice.sortOrder - bChoice.sortOrder;
  });
};

const Navbar = ({ t }) => {
  const {
    leftMenu,
    waves,
    groups,
    loadingWaves,
    loadingGroups,
    selectedWave,
    selectedGroup,
    organization,
    organizations,
    loadingOrganizations,
    userData,
    demographics,
    loadingDemographics,
    selectedDemographic,
    responsesAreProcessed,
  } = useSelector((state) => {
    const {
      language: selectedLanguage,
      group: selectedGroup,
      wave: selectedWave,
    } = state.preferences;
    const demographicsData = state.demographics.data;
    const surveyItems = Object.values(state?.surveys?.data[0]?.items ?? {});
    const dashboards = state.dashboards.data;
    const groups = state.groups.data;
    const userData = state.Login.userData;

    const userIsAdmin = checkUserIsDashboardAdmin(userData);

    const dashboard = selectedWave
      ? dashboards.find(({ waveId }) => waveId === selectedWave.id)
      : null;
    const currentWaveGroups = groups?.filter(({ waves }) =>
      waves.find((waveId) => waveId === selectedWave?.id)
    );
    const demographics = [];
    const dashboardsGroups = [];

    let responsesAreProcessed = true;

    if (dashboard) {
      const { Scores } = dashboard || {
        Scores: { Overall: { groups: [], demographics: {} } },
      };

      const {
        Overall: overall,
        Overall: { groups },
      } = Scores || { Overall: { groups: [], demographics: {} } };

      if (groups && Object.keys(groups)?.length > 0) {
        Object.keys(groups).forEach((groupId) => {
          const group = currentWaveGroups.find(({ id }) => id === groupId);

          if (group) {
            dashboardsGroups.push(group);
          }
        });
      }

      if (selectedGroup) {
        const group = groups?.[selectedGroup.id];

        if (group && group?.demographics) {
          Object.entries(group.demographics).forEach(
            ([key, demographicChoices]) => {
              const demographicsFound = demographicsData.find(
                ({ id }) => id === key
              );

              if (demographicsFound) {
                const choicesWithEnoughResponses = getChoicesWithEnoughResponses(
                  Object.entries(demographicChoices),
                  userIsAdmin
                );

                if (choicesWithEnoughResponses.length > 0) {
                  const formattedDemographics = {
                    tagId: key,
                    tagName: demographicsFound.tagName,
                    choices: choicesWithEnoughResponses,
                  };

                  sortDemographicsByChoicesOrder(
                    formattedDemographics,
                    surveyItems,
                    selectedLanguage
                  );

                  demographics.push(formattedDemographics);
                }
              }
            }
          );
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        Object.entries(overall.demographics)?.forEach(
          ([key, demographicChoices]) => {
            const demographicsFound = demographicsData.find(
              ({ id }) => id === key
            );

            if (demographicsFound) {
              const choicesWithEnoughResponses = getChoicesWithEnoughResponses(
                Object.entries(demographicChoices),
                userIsAdmin
              );

              if (choicesWithEnoughResponses.length) {
                const formattedDemographics = {
                  tagId: key,
                  tagName: demographicsFound.tagName,
                  choices: choicesWithEnoughResponses,
                };

                sortDemographicsByChoicesOrder(
                  formattedDemographics,
                  surveyItems,
                  selectedLanguage
                );

                demographics.push(formattedDemographics);
              }
            }
          }
        );
      }
    } else {
      responsesAreProcessed = false;
    }

    return {
      groups: dashboardsGroups,
      leftMenu: state.Layout.leftMenu,
      waves: state.waves.data,
      loadingWaves: state.waves.loading,
      loadingGroups: state.groups.groups,
      organization: state.preferences.organization,
      organizations: state.organizations.data,
      loadingOrganizations: state.organizations.loading,
      userData,
      loadingDemographics: state.demographics.loading,
      selectedDemographic: state.preferences.demographic,
      selectedGroup,
      selectedWave,
      demographics,
      responsesAreProcessed,
    };
  }, shallowEqual);

  const { groups: hierarchyGroups } = useSelector(
    selectTopHierarchyGroupsWithResponses,
    shallowEqual
  );

  const location = useLocation();
  const dispatch = useDispatch();

  const [reportOption, setReportOption] = useState(false);
  const [waveOption, setWaveOption] = useState(false);
  const [groupOption, setGroupOption] = useState(false);
  const [organizationOption, setOrganizationOption] = useState(false);
  const [demographicOption, setDemographicOption] = useState(false);
  // Related with renderDropdownMenuFromGroupHierarchy
  // const [groupsOption, setGroupsOption] = useState({});

  const wavesDependency = JSON.stringify(waves);
  const selectedWaveDependency = JSON.stringify(selectedWave);

  useEffect(() => {
    if (selectedWave && selectedWaveDependency) {
      dispatch(fetchDemographics([selectedWave.id]));
    }
  }, [dispatch, selectedWaveDependency, selectedWave]);

  const updatedWave = useMemo(
    () =>
      waves && wavesDependency && selectedWaveDependency && selectedWave?.id
        ? waves.find((wave) => wave?.id === selectedWave.id)
        : [],
    [wavesDependency, waves, selectedWaveDependency, selectedWave]
  );

  const updatedWaveDependency = JSON.stringify(updatedWave);

  const hasWaveChanged = useMemo(
    () =>
      updatedWaveDependency &&
      selectedWaveDependency &&
      !!updatedWave &&
      !!selectedWave &&
      !isEqual(updatedWave.hierarchy, selectedWave.hierarchy),
    [updatedWaveDependency, selectedWaveDependency, updatedWave, selectedWave]
  );

  useEffect(() => {
    if (hasWaveChanged && updatedWaveDependency) {
      dispatch(setWave(updatedWave));
    }
  }, [dispatch, hasWaveChanged, updatedWaveDependency, updatedWave]);

  useEffect(() => {
    if (!leftMenu) {
      setWaveOption(false);
      setGroupOption(false);
      setOrganizationOption(false);
      setDemographicOption(false);
    }
  }, [leftMenu]);

  const clearWavesGroupsAndDemographics = useCallback(() => {
    dispatch(setWave(null));
    dispatch(wavesCleanUp());
    dispatch(setGroup(null));
    dispatch(groupsCleanUp());
    dispatch(setDemographic(null));
    dispatch(cleanUpDemographic());
    dispatch(demographicsClearState());
    dispatch(cleanUpDatesFromWaves());
  }, [dispatch]);

  const setSelectedGroup = useCallback(
    (groupId) => {
      const groupData = groups.find(({ id }) => id === groupId);

      dispatch(setGroup(groupData));

      const title = `${t('navbar.group')} ${groupData?.displayName} ${t(
        'navbar.toastSuccess'
      )}`;

      dispatch(showToast(ToastIcons.SUCCESS, title));
    },
    [dispatch, groups, t]
  );

  // Commented until hierarchy is implemented in dashboards collection
  // const renderDropdownMenuFromGroupHierarchy = useCallback(
  //   (hierarchies) => {
  //     const groupHierarchyMenu = [];

  //     hierarchies &&
  //       hierarchies.forEach(({ children, groupId, groupName }) => {
  //         if (children.length === 0) {
  //           const group = (
  //             <span
  //               key={groupId}
  //               className={classnames('dropdown-item cursor-pointer', {
  //                 active: selectedGroup && groupId === selectedGroup.id,
  //               })}
  //               onClick={() => {
  //                 dispatch(setDemographic(null));
  //                 setSelectedGroup(groupId);
  //               }}
  //             >
  //               {groupName}
  //             </span>
  //           );

  //           groupHierarchyMenu.push(group);
  //         }

  //         if (children.length > 0) {
  //           const group = (
  //             <div key={groupId} className="dropdown cursor-pointer">
  //               <span
  //                 className="d-none d-lg-block dropdown-item dropdown-toggle arrow-none"
  //                 onClick={() => {
  //                   dispatch(setDemographic(null));
  //                   setSelectedGroup(groupId);
  //                 }}
  //               >
  //                 {groupName} <div className="arrow-down" />
  //               </span>
  //               <span
  //                 className="d-lg-none nav-link dropdown-toggle arrow-none font-size-13 px-4 py-2"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   dispatch(setDemographic(null));
  //                   setSelectedGroup(groupId);
  //                   setGroupsOption((prevState) => ({
  //                     ...prevState,
  //                     [groupName]: !groupsOption[groupName],
  //                   }));
  //                 }}
  //               >
  //                 {groupName}
  //                 <div className="arrow-down" />
  //               </span>
  //               <div
  //                 className={classnames('dropdown-menu cursor-pointer', {
  //                   show: groupsOption[groupName],
  //                 })}
  //               >
  //                 {renderDropdownMenuFromGroupHierarchy(children)}
  //               </div>
  //             </div>
  //           );

  //           groupHierarchyMenu.push(group);
  //         }
  //       });

  //     return groupHierarchyMenu;
  //   },
  //   [dispatch, setSelectedGroup, selectedGroup, groupsOption]
  // );

  const onClickOrganizationFromDropdown = useCallback(
    (selectedOrganization) => {
      if (selectedOrganization.displayName !== organization.displayName) {
        clearWavesGroupsAndDemographics();
        dispatch(setOrganization(selectedOrganization));
      }
    },
    [clearWavesGroupsAndDemographics, dispatch, organization]
  );

  const reports = useMemo(
    () => [
      {
        name: t('navbar.heatmap'),
        icon: 'fa fas fa-chess-board',
        path: Paths.HEATMAP,
      },
    ],
    [t]
  );

  const disableSelectsInSpecificRoute = useMemo(
    () => location.pathname === Paths.COMPARE_WAVES,
    [location.pathname]
  );

  const DemographicsOptions = useMemo(
    () =>
      demographics.map((demographic) => {
        const { tagId, tagName, choices } = demographic;

        return (
          <>
            <div key={demographic.tagId} className="dropdown cursor-pointer">
              <span className="d-none d-lg-block dropdown-item dropdown-toggle arrow-none">
                {tagName} <div className="arrow-down" />
              </span>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {choices.map((choice) => (
                  <span
                    key={`${tagId}-${choice}`}
                    className={classNames('dropdown-item', {
                      active:
                        selectedDemographic &&
                        tagId === selectedDemographic?.tagId &&
                        choice === selectedDemographic?.choice,
                    })}
                    onClick={() => {
                      dispatch(setDemographic({ tagId, tagName, choice }));
                      const title = `${t('navbar.demographic')} ${choice} ${t(
                        'navbar.toastSuccess'
                      )}`;
                      dispatch(showToast(ToastIcons.SUCCESS, title));
                    }}
                  >
                    {choice}
                  </span>
                ))}
              </div>
            </div>
          </>
        );
      }),
    [dispatch, t, demographics, selectedDemographic]
  );

  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {userData.role === Roles.ADMIN && (
                  <li className="d-block d-sm-none nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault();
                        setOrganizationOption(!organizationOption);
                      }}
                    >
                      <i className="fa fas fa-building mr-2" />
                      {organization && organization.displayName}
                      <div className="arrow-down" />
                    </span>
                    <div
                      className={classnames('dropdown-menu', {
                        show: organizationOption,
                      })}
                    >
                      {loadingOrganizations && (
                        <span className="dropdown-item d-flex justify-content-center">
                          <Spinner
                            className={classnames(classes.spinner)}
                            color="primary"
                          />
                        </span>
                      )}

                      {organizations.map((org) => (
                        <span
                          key={org.name}
                          className={classnames('dropdown-item', {
                            active: organization && org.id === organization.id,
                          })}
                          onClick={() => onClickOrganizationFromDropdown(org)}
                        >
                          <i className="fa fas fa-map-marker-alt font-size-16 align-middle mr-2 text-secondary" />
                          {org.displayName}
                        </span>
                      ))}
                    </div>
                  </li>
                )}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to={Paths.ROOT}
                  >
                    <i className="mdi mdi-view-dashboard-outline mr-2" />
                    {t('navbar.dashboard')}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <span className="d-none d-lg-block nav-link dropdown-toggle arrow-none cursor-pointer">
                    <i className="fa fas fa-chart-line mr-2" />
                    {t('navbar.reports')}
                    <div className="arrow-down" />
                  </span>
                  <span
                    className="d-lg-none nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setReportOption(!reportOption);
                    }}
                  >
                    <i className="mdi mdi-chart-line mr-2" />
                    {t('navbar.reports')}
                    <div className="arrow-down" />
                  </span>
                  <div
                    className={classnames('dropdown-menu', {
                      show: reportOption,
                    })}
                  >
                    {reports.map((report, index) => (
                      <Link
                        key={index}
                        to={report.path}
                        className={classnames(
                          'dropdown-item',
                          classes['cursor-pointer']
                        )}
                      >
                        <i className={classnames('mr-2', report.icon)} />
                        {report.name}
                      </Link>
                    ))}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <span className="d-none d-lg-block nav-link dropdown-toggle arrow-none cursor-pointer">
                    <i className="mdi mdi-waves mr-2" />
                    {t('navbar.waves')}
                    <div className="arrow-down" />
                  </span>
                  <span
                    className="d-lg-none nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setWaveOption(!waveOption);
                    }}
                  >
                    <i className="mdi mdi-waves mr-2" />
                    {t('navbar.waves')}
                    <div className="arrow-down" />
                  </span>
                  <div
                    className={classnames('dropdown-menu', {
                      show: waveOption,
                    })}
                  >
                    {disableSelectsInSpecificRoute && (
                      <span
                        key="no-organization"
                        className={classnames(
                          'dropdown-item text-dark',
                          classes['spinner-extra-styles']
                        )}
                      >
                        {t('navbar.disabledOnCompareWaves')}
                        <i className="mdi mdi-emoticon-sad-outline ml-1" />
                      </span>
                    )}
                    {!disableSelectsInSpecificRoute && (
                      <>
                        {loadingWaves && (
                          <span className="dropdown-item d-flex justify-content-center">
                            <Spinner
                              className={classnames(classes.spinner)}
                              color="primary"
                            />
                          </span>
                        )}

                        {!loadingWaves && waves.length === 0 && (
                          <span
                            key="no-organization"
                            className={classnames(
                              'dropdown-item text-dark',
                              classes['spinner-extra-styles']
                            )}
                          >
                            {t('navbar.emptyWaves')}
                            <i className="mdi mdi-emoticon-sad-outline ml-1" />
                          </span>
                        )}

                        {!loadingWaves &&
                          waves.length > 0 &&
                          waves.map((wave) => (
                            <span
                              key={wave.id}
                              className={classnames(
                                'dropdown-item',
                                classes['cursor-pointer'],
                                {
                                  active:
                                    selectedWave && wave.id === selectedWave.id,
                                }
                              )}
                              onClick={() => {
                                dispatch(setGroup(null));
                                dispatch(setWave(wave));
                                dispatch(cleanUpDatesFromWaves());
                                const title = `${t('navbar.wave')} ${
                                  wave.index
                                } ${t('navbar.toastSuccess')}`;
                                dispatch(showToast(ToastIcons.SUCCESS, title));
                              }}
                            >
                              {`${t('navbar.wave')} ${wave.index}`}
                            </span>
                          ))}
                      </>
                    )}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <span className="d-none d-lg-block nav-link dropdown-toggle arrow-none cursor-pointer">
                    <i className="mdi mdi-account-group mr-2" />
                    {t('navbar.groups')}
                    <div className="arrow-down" />
                  </span>
                  <span
                    className="d-lg-none nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setGroupOption(!groupOption);
                    }}
                  >
                    <i className="mdi mdi-account-group mr-2" />
                    {t('navbar.groups')}
                    <div className="arrow-down" />
                  </span>
                  <div
                    className={classnames('dropdown-menu', {
                      show: groupOption,
                    })}
                  >
                    {disableSelectsInSpecificRoute && (
                      <span
                        key="no-organization"
                        className={classnames(
                          'dropdown-item text-dark',
                          classes['empty-groups']
                        )}
                      >
                        {t('navbar.disabledOnCompareWaves')}
                        <i className="mdi mdi-emoticon-sad-outline ml-1" />
                      </span>
                    )}
                    {!disableSelectsInSpecificRoute && (
                      <>
                        {loadingGroups && (
                          <span className="dropdown-item d-flex justify-content-center">
                            <Spinner
                              className={classnames(classes.spinner)}
                              color="primary"
                            />
                          </span>
                        )}

                        {!responsesAreProcessed &&
                          !loadingGroups &&
                          groups.length === 0 && (
                            <span
                              key="no-organization"
                              className={classnames(
                                'dropdown-item text-dark',
                                classes['empty-groups']
                              )}
                            >
                              {t('navbar.responsesNotProcessed')}
                              <i className="mdi mdi-emoticon-sad-outline ml-1" />
                            </span>
                          )}

                        {responsesAreProcessed &&
                          !loadingGroups &&
                          groups.length === 0 && (
                            <span
                              key="no-organization"
                              className={classnames(
                                'dropdown-item text-dark',
                                classes['empty-groups']
                              )}
                            >
                              {t('navbar.emptyGroups')}
                              <i className="mdi mdi-emoticon-sad-outline ml-1" />
                            </span>
                          )}

                        {responsesAreProcessed &&
                          !loadingGroups &&
                          groups.length > 0 && (
                            <>
                              <span
                                key="default"
                                className={classnames(
                                  'dropdown-item cursor-pointer',
                                  {
                                    active: !selectedGroup,
                                  }
                                )}
                                onClick={() => {
                                  dispatch(setGroup(null));
                                  const title = `${t('navbar.overall')} ${t(
                                    'navbar.toastSuccess'
                                  )}`;
                                  dispatch(
                                    showToast(ToastIcons.SUCCESS, title)
                                  );
                                }}
                              >
                                {t('navbar.overall')}
                              </span>
                            </>
                          )}
                        {!loadingGroups &&
                          groups.length > 0 &&
                          hierarchyGroups?.length > 0 &&
                          hierarchyGroups.map(({ groupId, groupName }) => (
                            <span
                              key={groupId}
                              className={classnames(
                                'dropdown-item cursor-pointer',
                                {
                                  active:
                                    selectedGroup &&
                                    groupId === selectedGroup.id,
                                }
                              )}
                              onClick={() => {
                                dispatch(setDemographic(null));
                                setSelectedGroup(groupId);
                              }}
                            >
                              {groupName}
                            </span>
                          ))}
                      </>
                    )}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <span className="d-none d-lg-block nav-link dropdown-toggle arrow-none cursor-pointer">
                    <i className="mdi mdi-tag mr-2" />
                    {t('navbar.demographics')}
                    <div className="arrow-down" />
                  </span>
                  <span
                    className="d-lg-none nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setDemographicOption(!demographicOption);
                    }}
                  >
                    <i className="mdi mdi-tag mr-2" />
                    {t('navbar.demographics')}
                    <div className="arrow-down" />
                  </span>
                  <div
                    className={classnames('dropdown-menu', {
                      show: demographicOption,
                    })}
                  >
                    {disableSelectsInSpecificRoute && (
                      <span
                        key="no-organization"
                        className={classnames(
                          'dropdown-item text-dark',
                          classes['spinner-extra-styles']
                        )}
                      >
                        {t('navbar.disabledOnCompareWaves')}
                        <i className="mdi mdi-emoticon-sad-outline ml-1" />
                      </span>
                    )}
                    {!disableSelectsInSpecificRoute && (
                      <>
                        {loadingDemographics && (
                          <span className="dropdown-item d-flex justify-content-center">
                            <Spinner
                              className={classnames(classes.spinner)}
                              color="primary"
                            />
                          </span>
                        )}

                        {!responsesAreProcessed &&
                          !loadingDemographics &&
                          demographics.length === 0 && (
                            <span
                              key="no-organization"
                              className={classnames(
                                'dropdown-item text-dark',
                                classes['spinner-extra-styles']
                              )}
                            >
                              {t('navbar.responsesNotProcessed')}
                              <i className="mdi mdi-emoticon-sad-outline ml-1" />
                            </span>
                          )}

                        {responsesAreProcessed &&
                          !loadingDemographics &&
                          demographics.length === 0 && (
                            <span
                              key="no-organization"
                              className={classnames(
                                'dropdown-item text-dark',
                                classes['spinner-extra-styles']
                              )}
                            >
                              {t('navbar.emptyDemographics')}
                              <i className="mdi mdi-emoticon-sad-outline ml-1" />
                            </span>
                          )}

                        {responsesAreProcessed &&
                          !loadingDemographics &&
                          demographics.length > 0 && (
                            <span
                              key="default"
                              className={classnames(
                                'dropdown-item cursor-pointer',
                                {
                                  active: !selectedDemographic,
                                }
                              )}
                              onClick={() => {
                                dispatch(setDemographic(null));
                                const title = `${t('navbar.overall')} ${t(
                                  'navbar.toastSuccess'
                                )}`;
                                dispatch(showToast(ToastIcons.SUCCESS, title));
                              }}
                            >
                              {t('navbar.overall')}
                            </span>
                          )}

                        {responsesAreProcessed &&
                          !loadingDemographics &&
                          demographics.length > 0 &&
                          DemographicsOptions}
                      </>
                    )}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to={Paths.GLOSSARY}
                  >
                    <i className="mdi mdi-book-open-page-variant mr-2" />
                    {t('navbar.glossary')}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to={Paths.COMPARE_WAVES}
                  >
                    <i className="mdi mdi-compare mr-2" />
                    {t('navbar.compareWaves')}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
};

export default withRouter(withNamespaces()(Navbar));
