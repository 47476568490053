import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';
import classnames from 'classnames';

import Popover from '../../../../components/Popover';
import classes from './MiniStatReport.module.scss';

const MiniStatReport = ({ report }) => (
  <>
    <Card
      className={classnames('mini-stats-wid', classes['cursor-pointer'])}
      id={report.popoverTarget}
    >
      <CardBody>
        <Media>
          <Media body>
            <p className="text-muted font-weight-medium font-size-16">
              {report.title}
            </p>
            <h4 className="mb-0 font-size-24">{report.description}</h4>
          </Media>
          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
            <span className="avatar-title">
              <i className={`bx ${report.iconClass} font-size-24`} />
            </span>
          </div>
        </Media>
      </CardBody>
    </Card>
    <Popover
      target={report.popoverTarget}
      header={report.popoverHeader}
      body={report.popoverBody}
    />
  </>
);

export default MiniStatReport;
