import { createReducer } from 'redux-act';

import {
  TAGS_CLEAN_UP,
  TAGS_FETCH_FAIL,
  TAGS_FETCH_INIT,
  TAGS_FETCH_SUCCESS,
} from '../../actions/tags';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const tagsReducer = createReducer(
  {
    [TAGS_FETCH_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAGS_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.tags,
      loading: false,
      error: null,
    }),
    [TAGS_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAGS_CLEAN_UP]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default tagsReducer;
