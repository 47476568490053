import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Paths from '../paths';

const PrivateRoute = ({ path, component: Component, layout: Layout }) => {
  return (
    <Route
      path={path}
      render={(props) =>
        localStorage.getItem('authUser') ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: Paths.LOGIN, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  layout: PropTypes.element.isRequired,
};

export default withRouter(PrivateRoute);
