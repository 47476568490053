import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import Layout from './layout/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';
import loginReducer from './auth/login/reducer';
import preferencesReducer from './thunk/reducers/preferences/index';
import organizationsReducer from './thunk/reducers/organizations/index';
import wavesReducer from './thunk/reducers/waves/index';
import groupsReducer from './thunk/reducers/groups/index';
import tagsReducer from './thunk/reducers/tags/index';
import toastReducer from './thunk/reducers/toast/index';
import reportsReducer from './thunk/reducers/reports/index';
import surveysReducer from './thunk/reducers/surveys/index';
import responsesReducer from './thunk/reducers/responses/index';
import demographicsReducer from './thunk/reducers/demographics/index';
import auth from './thunk/reducers/auth/index';
import dashboardsReducer from './thunk/reducers/dashboards/index';

const rootReducer = combineReducers({
  Login: persistReducer(
    {
      key: 'Login',
      storage,
      blacklist: ['error', 'loading'],
    },
    loginReducer
  ),
  preferences: persistReducer(
    {
      key: 'preferences',
      storage,
    },
    preferencesReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  waves: persistReducer(
    {
      key: 'waves',
      storage,
      blacklist: ['error', 'loading'],
    },
    wavesReducer
  ),
  groups: persistReducer(
    {
      key: 'groups',
      storage,
      blacklist: ['error', 'loading'],
    },
    groupsReducer
  ),
  tags: persistReducer(
    {
      key: 'tags',
      storage,
      blacklist: ['error', 'loading'],
    },
    tagsReducer
  ),
  toast: persistReducer(
    {
      key: 'toast',
      storage,
    },
    toastReducer
  ),
  reports: persistReducer(
    {
      key: 'reports',
      storage,
      blacklist: ['error', 'loading'],
    },
    reportsReducer
  ),
  surveys: persistReducer(
    {
      key: 'surveys',
      storage,
      blacklist: ['error', 'loading', 'fetched'],
    },
    surveysReducer
  ),
  responses: persistReducer(
    {
      key: 'responses',
      storage,
      blacklist: ['error', 'loading', 'fetched'],
    },
    responsesReducer
  ),
  demographics: persistReducer(
    {
      key: 'demographics',
      storage,
      blacklist: ['error', 'loading', 'fetched'],
    },
    demographicsReducer
  ),
  dashboards: persistReducer(
    {
      key: 'dashboards',
      storage,
      blacklist: ['error', 'loading', 'fetched'],
    },
    dashboardsReducer
  ),
  auth,
  Layout,
  Account,
  ForgetPassword,
  Profile,
});

export default rootReducer;
