import { createAction } from 'redux-act';

export const SHOW_TOAST = createAction('SHOW_TOAST');
export const CLEAN_UP_TOAST = createAction('CLEAN_UP_TOAST');

export const showToast = (icon, title) => (dispatch) => {
  return dispatch(SHOW_TOAST({ icon, title }));
};

export const cleanUpToast = () => (dispatch) => dispatch(CLEAN_UP_TOAST());
