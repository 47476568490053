import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
} from 'react';
import { withNamespaces } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { useSelector, shallowEqual } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import {
  dateBetweenPeriod,
  getChildrenGroupIds,
  getDaysBetweenDates,
  getGroupFromHierarchy,
} from '../../../helpers/functions/functions_helper';
import {
  DownloadOptions,
  getDownloadChartFileName,
} from '../../../helpers/utils';
import ChartNames from '../../../helpers/enums/chartNames';
import ChartTypes from '../../../helpers/enums/chartTypes';
import ApexChart from '../../../components/ApexChart';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import classes from './SurveyResponses.module.scss';
import './SurveyResponses.scss';

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const SurveyResponsesChart = forwardRef(
  (
    {
      surveyResponsesType,
      options,
      series,
      lineColumnOptions,
      lineColumnSeries,
    },
    ref
  ) => (
    <div ref={ref}>
      {surveyResponsesType === ChartTypes[ChartNames.AREA] && (
        <ApexChart
          containerClasses={classes['survey-responses-height']}
          chartClasses="mt-sm-4"
          options={options}
          series={series}
          type={ChartTypes[ChartNames.AREA]}
        />
      )}
      {surveyResponsesType === ChartTypes[ChartNames.LINE] && (
        <ApexChart
          containerClasses={classes['survey-responses-height']}
          chartClasses="mt-sm-4"
          options={lineColumnOptions}
          series={lineColumnSeries}
          type={ChartTypes[ChartNames.LINE]}
        />
      )}
    </div>
  )
);

const SurveyResponses = ({ t, responses, hits, wave }) => {
  const { group, demographic, selectedWaveDates } = useSelector(
    (state) => ({
      group: state.preferences.group,
      demographic: state.preferences.demographic,
      selectedWaveDates: state.preferences.datesFromWave,
    }),
    shallowEqual
  );

  const [datesRange, setDatesRage] = useState([]);
  const [series, setSeries] = useState([]);
  const [lineColumnSeries, setLineColumnSeries] = useState([]);
  const [surveyResponsesType, setSurveyResponsesType] = useState(
    ChartTypes[ChartNames.AREA]
  );
  const [surveyResponses, setSurveyResponses] = useState({
    hits: [],
    responses: [],
    invalid: [],
  });

  const clearSurveyResponses = useCallback(() => {
    setSurveyResponses({
      hits: [],
      responses: [],
      invalid: [],
    });
  }, []);

  const getSurveyResponses = useCallback(() => {
    clearSurveyResponses();

    datesRange.forEach((date) => {
      let totalResponses = [];
      let totalHits = 0;

      totalResponses = responses.filter(
        (response) => moment(response.createdAt).format('DD/MM/YYYY') === date
      );

      if (group) {
        const groupInHierarchy = getGroupFromHierarchy(wave.hierarchy, group);

        const groupIds = getChildrenGroupIds(groupInHierarchy);

        totalResponses = totalResponses.filter((response) =>
          response.groups.some((groupId) => groupIds.includes(groupId))
        );
      }

      if (selectedWaveDates) {
        const { startDate, endDate } = selectedWaveDates;
        totalResponses = totalResponses.filter(({ createdAt }) =>
          dateBetweenPeriod(createdAt, startDate, endDate)
        );

        const [day, month, year] = date.split('/');

        if (hits) {
          hits.forEach(({ waveId, hits }) => {
            if (waveId === wave.id && hits && hits[date]) {
              totalHits +=
                dateBetweenPeriod(
                  [month, day, year].join('/'),
                  startDate,
                  endDate
                ) && hits[date];
            }
          });
        }
      }

      if (demographic?.tagId && demographic?.choice) {
        totalResponses = totalResponses.filter((response) => {
          return (
            response.demographics &&
            response.demographics.some(
              ({ demographicId, name }) =>
                demographicId === demographic.tagId &&
                name.includes(demographic.choice)
            )
          );
        });
      }

      if (!selectedWaveDates && hits) {
        hits.forEach(({ waveId, hits }) => {
          if (waveId === wave.id && hits && hits[date]) {
            totalHits += hits[date];
          }
        });
      }

      const invalidResponses = totalResponses.filter(
        (response) => !response.valid
      );

      setSurveyResponses((prevState) => ({
        hits: [...prevState.hits, totalHits],
        responses: [...prevState.responses, totalResponses.length],
        invalid: [...prevState.invalid, invalidResponses.length],
      }));
    });
  }, [
    datesRange,
    responses,
    hits,
    group,
    demographic,
    wave,
    selectedWaveDates,
    clearSurveyResponses,
  ]);

  const getDatesRange = useCallback(() => {
    let datesArray = [];
    let stringDateArray = [];

    if (wave) {
      const { startingOn, until } = wave;
      const today = new Date();
      let daysBetweenDates = 0;

      if (!until || until > today) {
        daysBetweenDates = getDaysBetweenDates(new Date(startingOn), today);
      } else {
        daysBetweenDates = getDaysBetweenDates(
          new Date(startingOn),
          new Date(until)
        );
      }

      const startingOnDate = new Date(wave.startingOn);
      const prevDate = new Date(startingOnDate);
      prevDate.setDate(prevDate.getDate() - 1);

      datesArray.push(prevDate);
      datesArray.push(startingOnDate);

      for (let i = 0; i < daysBetweenDates; i++) {
        let nextDay = new Date(datesArray[datesArray.length - 1]);
        nextDay.setDate(nextDay.getDate() + 1);
        datesArray.push(nextDay);
      }

      stringDateArray = datesArray.map((date) =>
        moment(date).format('DD/MM/YYYY')
      );
    }

    setDatesRage(stringDateArray);
  }, [wave]);

  useEffect(() => {
    if (wave) {
      getDatesRange();
    }
  }, [wave, getDatesRange]);

  useEffect(() => {
    getSurveyResponses();
  }, [getSurveyResponses]);

  useEffect(() => {
    setSeries([
      {
        name: t('surveyResponsesGraph.responses'),
        data: surveyResponses.responses,
      },
      {
        name: t('surveyResponsesGraph.hits'),
        data: surveyResponses.hits,
      },
      // {
      //   name: t('surveyResponsesGraph.invalid'),
      //   data: surveyResponses.invalid,
      // },
    ]);

    setLineColumnSeries([
      {
        name: t('surveyResponsesGraph.responses'),
        data: surveyResponses.responses,
        type: 'area',
      },
      {
        name: t('surveyResponsesGraph.hits'),
        data: surveyResponses.hits,
        type: 'line',
      },
      // {
      //   name: t('surveyResponsesGraph.invalid'),
      //   data: surveyResponses.invalid,
      //   type: 'column',
      // },
    ]);
  }, [surveyResponses, t]);

  const options = useMemo(
    () => ({
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      colors: ['#556ee6', '#34c38f', '#f46a6a'],
      xaxis: {
        type: 'category',
        categories: datesRange,
        tickAmount: 15,
        tickPlacement: 'on',
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
        },
      },
      yaxis: [
        {
          title: {
            text: 'Responses',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Hits',
          },
        },
      ],
      grid: {
        borderColor: '#f1f1f1',
      },
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
      },
    }),
    [datesRange]
  );

  const lineColumnOptions = useMemo(
    () => ({
      ...options,
      stroke: {
        curve: 'smooth',
        width: [2, 5, 0],
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      fill: {
        opacity: [0.25, 1, 0.85],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
    }),
    [options]
  );

  const componentRef = useRef();

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('surveyResponsesGraph.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const generateCSVData = useMemo(
    () =>
      datesRange.map((date, index) => ({
        responses: surveyResponses.responses[index],
        hits: surveyResponses.hits[index],
        invalid: surveyResponses.invalid[index],
        date,
      })),
    [datesRange, surveyResponses]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        { label: t('surveyResponsesGraph.responses'), key: 'responses' },
        { label: t('surveyResponsesGraph.hits'), key: 'hits' },
        { label: t('surveyResponsesGraph.invalid'), key: 'invalid' },
        { label: 'Date', key: 'date' },
      ],
      data: generateCSVData,
      fileName: `${downloadChartFileName}.csv`,
    }),
    [t, downloadChartFileName, generateCSVData]
  );

  const canDownloadChart = useMemo(() => {
    const hasResponses =
      surveyResponses.responses.filter((responses) => responses > 0).length > 0;

    const hasHits = surveyResponses.hits.filter((hits) => hits > 0).length > 0;

    const hasInvalidResponses =
      surveyResponses.invalid.filter((invalidResponse) => invalidResponse > 0)
        .length > 0;

    const canDownload = hasResponses || hasInvalidResponses || hasHits;

    return canDownload;
  }, [surveyResponses]);

  return (
    <Card className={classes['card-height']}>
      <CardBody>
        <div className="clearfix" />
        <span className="font-size-20">{t('surveyResponsesGraph.title')}</span>
        <div className="float-sm-right">
          <ul className="nav nav-pills mt-2 mt-md-0">
            <li className="nav-item">
              <span
                className={classnames('nav-link', classes['cursor-pointer'], {
                  active: surveyResponsesType === ChartTypes[ChartNames.AREA],
                })}
                onClick={() =>
                  setSurveyResponsesType(ChartTypes[ChartNames.AREA])
                }
              >
                {ChartNames.AREA}
              </span>
            </li>
            <li className="nav-item">
              <span
                className={classnames('nav-link', classes['cursor-pointer'], {
                  active: surveyResponsesType === ChartTypes[ChartNames.LINE],
                })}
                onClick={() =>
                  setSurveyResponsesType(ChartTypes[ChartNames.LINE])
                }
              >
                {ChartNames.LINE_COLUMN}
              </span>
            </li>
          </ul>
        </div>
        <SurveyResponsesChart
          ref={componentRef}
          surveyResponsesType={surveyResponsesType}
          options={options}
          series={series}
          lineColumnOptions={lineColumnOptions}
          lineColumnSeries={lineColumnSeries}
        />
        <div className={classes['download-icon']}>
          <DownloadChartDropdown
            t={t}
            canDownloadChart={canDownloadChart}
            PNGData={PNGData}
            CSVData={CSVData}
            optionsEnabled={optionsEnabled}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(SurveyResponses);
