import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { exportComponentAsPNG } from 'react-component-export-image';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { CSVLink } from 'react-csv';
import classnames from 'classnames';

import { showToast } from '../../store/actions';
import ToastIcons from '../../helpers/enums/toastIcons';
import classes from './DownloadChartDropdown.module.scss';

const DownloadChartDropdown = ({
  t,
  PNGData,
  CSVData,
  optionsEnabled,
  canDownloadChart = true,
}) => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [downloadPNG, setDownloadPNG] = useState(false);
  const { PNG, CSV } = optionsEnabled;

  useEffect(() => {
    if (downloadPNG && !menu) {
      exportComponentAsPNG(PNGData.componentRef, {
        fileName: PNGData.fileName,
      });
      setDownloadPNG(false);
    }
  }, [downloadPNG, menu, PNGData]);

  const emptyChartErrorHandler = useCallback(
    () =>
      dispatch(
        showToast(ToastIcons.ERROR, t('downloadChartDropDown.cannotDownload'))
      ),
    [dispatch, t]
  );

  const downloadOptions = useMemo(
    () => ({
      ...(PNG && {
        [PNG]: (
          <span
            key={PNG}
            className={classnames('dropdown-item', classes['cursor-pointer'])}
            onClick={() => {
              setMenu(!menu);
              canDownloadChart
                ? setDownloadPNG(true)
                : emptyChartErrorHandler();
            }}
          >
            <i className="fa far fa-file-image font-size-16 align-middle mr-2 text-secondary" />
            {`Download as ${PNG}`}
          </span>
        ),
      }),
      ...(CSV && {
        [CSV]: (
          <span
            key={CSV}
            className={classnames('dropdown-item', classes['cursor-pointer'])}
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <i className="fa fas fa-file-csv font-size-16 align-middle mr-2 text-secondary" />
            <CSVLink
              data={CSVData.data}
              headers={CSVData.headers}
              filename={CSVData.fileName}
              className={classes.csv}
              onClick={() => {
                if (!canDownloadChart) {
                  emptyChartErrorHandler();
                }

                return canDownloadChart;
              }}
            >
              {`Download as ${CSV}`}
            </CSVLink>
          </span>
        ),
      }),
    }),
    [CSVData, canDownloadChart, PNG, CSV, menu, emptyChartErrorHandler]
  );

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        <i className="fa fas fa-cloud-download-alt font-size-16 align-middle" />
      </DropdownToggle>
      <DropdownMenu right>
        {Object.values(downloadOptions).map((value) => value)}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DownloadChartDropdown;
