import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { userForgetPassword } from '../../store/actions';
import profile from '../../assets/images/presage/presage_logo2.png';
import logo from '../../assets/images/presage/presage_logo4.png';

const ForgetPasswordPage = (props) => {
  const { forgetError, forgetSuccessMsg } = useSelector(
    (state) => ({
      forgetError: state.ForgetPassword.forgetError,
      forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleValidSubmit = (event, values) => {
    dispatch(userForgetPassword(values, props.history));
  };

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-secondary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-seconday p-4">
                        <h5 className="text-body">
                          {props.t('forgetPassword.title')}
                        </h5>
                        <p>{props.t('forgetPassword.subTitle')}</p>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img
                        src={profile}
                        style={{ width: '90%' }}
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label={props.t('forgetPassword.inputLabel')}
                          className="form-control"
                          placeholder={props.t(
                            'forgetPassword.inputPlaceholder'
                          )}
                          type="email"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {props.t('forgetPassword.resetButton')}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t('forgetPassword.goBack')}{' '}
                  <Link to="login" className="font-weight-medium text-primary">
                    {props.t('forgetPassword.loginLink')}
                  </Link>
                </p>
                <p>
                  <p>© {new Date().getFullYear()} Presage.</p>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(withRouter(ForgetPasswordPage));
