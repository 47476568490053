import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  cleanUpDatesFromWaves,
  cleanUpDemographic,
  demographicsClearState,
  fetchOrganizations,
  groupsCleanUp,
  setDemographic,
  setGroup,
  setOrganization,
  setWave,
  wavesCleanUp,
} from '../../../store/actions';
import './OrganizationDropdown.scss';

const OrganizationDropdown = () => {
  const { organization, organizations } = useSelector(
    (state) => ({
      organization: state.preferences.organization,
      organizations: state.organizations.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const filterInactives = true;
    dispatch(fetchOrganizations(filterInactives));
  }, [dispatch]);

  const [menu, setMenu] = useState(false);

  const clearWavesGroupsAndDemographics = useCallback(() => {
    dispatch(setWave(null));
    dispatch(wavesCleanUp());
    dispatch(setGroup(null));
    dispatch(groupsCleanUp());
    dispatch(setDemographic(null));
    dispatch(cleanUpDemographic());
    dispatch(demographicsClearState());
    dispatch(cleanUpDatesFromWaves());
  }, [dispatch]);

  const onClickOrganizationFromDropdown = useCallback(
    (selectedOrganization) => {
      if (selectedOrganization.displayName !== organization.displayName) {
        clearWavesGroupsAndDemographics();
        dispatch(setOrganization(selectedOrganization));
      }
      setMenu(!menu);
    },
    [clearWavesGroupsAndDemographics, dispatch, menu, organization]
  );

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        <i className="fa fas fa-building font-size-16 align-middle mr-1" />
        <span className="d-none d-xl-inline-block ml-2 mr-1">
          {organization && organization.displayName}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="organization-dropdown" right>
        {organizations.map((organization) => (
          <span
            key={organization.name}
            className="dropdown-item cursor-pointer"
            onClick={() => onClickOrganizationFromDropdown(organization)}
          >
            <i className="fa fas fa-map-marker-alt font-size-16 align-middle mr-2 text-secondary" />
            {organization.displayName}
          </span>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OrganizationDropdown;
