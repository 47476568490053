import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// i18n
import i18n from '../../../i18n';

// falgs
import usFlag from '../../../assets/images/flags/us.jpg';
import spain from '../../../assets/images/flags/spain.jpg';
import germany from '../../../assets/images/flags/germany.jpg';
import italy from '../../../assets/images/flags/italy.jpg';
import french from '../../../assets/images/flags/french.jpg';
import china from '../../../assets/images/flags/china.jpg';
import korean from '../../../assets/images/flags/korean.jpg';
import portugal from '../../../assets/images/flags/portugal.jpg';
import { setLanguage } from '../../../store/thunk/actions/preferences';
import {
  LanguagesISOCodes,
  LanguagesNames,
} from '../../../helpers/enums/languages';

const LanguageDropdown = () => {
  const { language } = useSelector(
    (state) => ({
      language: state.preferences.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [flag, setFlag] = useState(usFlag);
  const [lng, setLng] = useState(LanguagesNames.ENGLISH);

  useEffect(() => {
    i18n.changeLanguage(language);

    switch (language) {
      case LanguagesISOCodes.ES:
        setFlag(spain);
        setLng(LanguagesNames.SPANISH);
        break;
      case LanguagesISOCodes.GR:
        setFlag(germany);
        setLng(LanguagesNames.GERMAN);
        break;
      case LanguagesISOCodes.ZH:
        setFlag(china);
        setLng(LanguagesNames.CHINESE);
        break;
      case LanguagesISOCodes.IT:
        setFlag(italy);
        setLng(LanguagesNames.ITALIAN);
        break;
      case LanguagesISOCodes.EN:
        setFlag(usFlag);
        setLng(LanguagesNames.ENGLISH);
        break;
      case LanguagesISOCodes.PT:
        setFlag(portugal);
        setLng(LanguagesNames.PORTUGUESE);
        break;
      case LanguagesISOCodes.KO:
        setFlag(korean);
        setLng(LanguagesNames.KOREAN);
        break;
      case LanguagesISOCodes.YUE:
        setFlag(china);
        setLng(LanguagesNames.CANTONESE);
        break;
      case LanguagesISOCodes.FR:
        setFlag(french);
        setLng(LanguagesNames.FRENCH);
        break;
      default:
        setFlag(usFlag);
        setLng(LanguagesNames.ENGLISH);
        break;
    }
  }, [language]);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img src={flag} alt="flag" height="16" className="mr-1" />
          <span className="d-none d-sm-inline-block align-middle">{lng}</span>
        </DropdownToggle>
        <DropdownMenu className="language-switch" right>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.YUE))}
            className="notify-item"
          >
            <img
              src={china}
              alt="cantonese flag"
              className="mr-1"
              height="12"
            />
            <span className="align-middle">{LanguagesNames.CANTONESE}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.ZH))}
            className="notify-item"
          >
            <img src={china} alt="chinese flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.CHINESE}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.EN))}
            className="notify-item"
          >
            <img src={usFlag} alt="english flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.ENGLISH}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.FR))}
            className="notify-item"
          >
            <img src={french} alt="french flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.FRENCH}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.GR))}
            className="notify-item"
          >
            <img src={germany} alt="german flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.GERMAN}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.IT))}
            className="notify-item"
          >
            <img src={italy} alt="italian flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.ITALIAN}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.KO))}
            className="notify-item"
          >
            <img src={korean} alt="korean flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.KOREAN}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.PT))}
            className="notify-item"
          >
            <img
              src={portugal}
              alt="portuguese flag"
              className="mr-1"
              height="12"
            />
            <span className="align-middle">{LanguagesNames.PORTUGUESE}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(setLanguage(LanguagesISOCodes.ES))}
            className="notify-item"
          >
            <img src={spain} alt="spanish flag" className="mr-1" height="12" />
            <span className="align-middle">{LanguagesNames.SPANISH}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default LanguageDropdown;
