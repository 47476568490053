import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';

import noData from '../../../assets/images/noData.png';
import Popovers from '../../../helpers/enums/popovers';
import ChartTypes from '../../../helpers/enums/chartTypes';
import ChartNames from '../../../helpers/enums/chartNames';
import {
  DownloadOptions,
  getDownloadChartFileName,
  getResumedNamesFromDashboardGroup,
  isFloat,
} from '../../../helpers/utils';
import ApexChart from '../../../components/ApexChart';
import Popover from '../../../components/Popover';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import classes from './SafetyAwarenessBreakdown.module.scss';

const displayOptions = [
  {
    chartType: ChartTypes[ChartNames.RADIAL],
    chartName: ChartNames.RADIAL,
  },
  {
    chartType: ChartTypes[ChartNames.BAR],
    chartName: ChartNames.COLUMN,
  },
];

const chartOptions = {
  responsive: [
    {
      breakpoint: 1450,
      options: {
        chart: {
          height: '200',
        },
      },
    },
    {
      breakpoint: 8000,
      options: {
        chart: {
          height: '250',
        },
      },
    },
  ],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: '55%',
      },
      dataLabels: {
        showOn: 'always',
        value: {
          offsetY: 0,
          color: '#111',
          fontSize: '40px',
          show: true,
          formatter: (val) => val,
        },
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#34c38f'],
  },
  stroke: {
    lineCap: 'round',
    color: '#111',
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  labels: [''],
};

const chartOptionsEmpty = {
  ...chartOptions,
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: '55%',
        image: noData,
        imageWidth: 64,
        imageHeight: 64,
        imageClipped: false,
        imageOffsetY: -12,
      },
      dataLabels: {
        value: {
          offsetY: 22,
          color: '#111',
          fontSize: '18px',
          show: true,
        },
      },
    },
  },
};

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const SafetyAwarenessBreakdown = ({ t, wave }) => {
  const {
    personalSafetyKnowledge,
    situationalAwareness,
    safetyConsciousness,
  } = useSelector((state) => {
    const dashboard =
      wave && state.dashboards.data.find(({ waveId }) => waveId === wave.id);
    const group = state.preferences.group;
    const selectedDemographic = state.preferences.demographic;
    const groupId = group && group.id;

    let personalSafetyKnowledge = 0;
    let situationalAwareness = 0;
    let safetyConsciousness = 0;

    if (dashboard) {
      const { Scores } = dashboard || {
        Scores: { Overall: { groups: [], demographics: {} } },
      };

      const {
        Overall: overall,
        Overall: { groups },
      } = Scores || { Overall: { groups: [], demographics: {} } };

      if (group) {
        const groupData = groups?.[groupId];

        if (groupData) {
          const groupSelectedDemographic =
            groupData?.demographics?.[selectedDemographic?.choice];

          const {
            personalSafetyKnowledge: personalSafetyKnowledgeData,
            situationalAwareness: situationalAwarenessData,
            safetyConsciousness: safetyConsciousnessData,
          } = groupSelectedDemographic
            ? getResumedNamesFromDashboardGroup(groupSelectedDemographic)
            : getResumedNamesFromDashboardGroup(groupData);

          personalSafetyKnowledge = personalSafetyKnowledgeData;
          situationalAwareness = situationalAwarenessData;
          safetyConsciousness = safetyConsciousnessData;
        }
      } else {
        const overallSelectedDemographic =
          overall?.demographics?.[selectedDemographic?.choice];

        const {
          personalSafetyKnowledge: personalSafetyKnowledgeData,
          situationalAwareness: situationalAwarenessData,
          safetyConsciousness: safetyConsciousnessData,
        } = overallSelectedDemographic
          ? getResumedNamesFromDashboardGroup(overallSelectedDemographic)
          : getResumedNamesFromDashboardGroup(overall);

        personalSafetyKnowledge = personalSafetyKnowledgeData;
        situationalAwareness = situationalAwarenessData;
        safetyConsciousness = safetyConsciousnessData;
      }
    }

    return {
      personalSafetyKnowledge,
      situationalAwareness,
      safetyConsciousness,
    };
  }, shallowEqual);

  const [chartSelected, setChartSelected] = useState(ChartNames.RADIAL);

  const reports = useMemo(
    () => [
      {
        name: t('safetyAwarenessBreakdownGraphs.personalSafetyKnowledge'),
        value: personalSafetyKnowledge,
        popoverTarget: Popovers.PERSONALSAFETYKNOWLEDGE,
        popoverHeader: t('popoverPersonalSafetyKnowledge.Header'),
        popoverBody: t('popoverPersonalSafetyKnowledge.Body'),
      },
      {
        name: t('safetyAwarenessBreakdownGraphs.situationalAwareness'),
        value: situationalAwareness,
        popoverTarget: Popovers.SITUATIONALAWARENESS,
        popoverHeader: t('popoverSituationalAwareness.Header'),
        popoverBody: t('popoverSituationalAwareness.Body'),
      },
      {
        name: t('safetyAwarenessBreakdownGraphs.safetyConsciousness'),
        value: safetyConsciousness,
        popoverTarget: Popovers.SAFETYCONSCIOUSNESS,
        popoverHeader: t('popoverSafetyConsciousness.Header'),
        popoverBody: t('popoverSafetyConsciousness.Body'),
      },
    ],
    [t, safetyConsciousness, situationalAwareness, personalSafetyKnowledge]
  );

  const allSafetyAwarenessNames = useMemo(
    () => reports.map((report) => report.name),
    [reports]
  );

  const allSafetyAwarenessValues = useMemo(
    () => reports.map((report) => report.value),
    [reports]
  );

  const columnSeries = useMemo(
    () => [
      {
        name: t('safetyAwarenessBreakdownGraphs.title'),
        data: allSafetyAwarenessValues,
      },
    ],
    [allSafetyAwarenessValues, t]
  );

  const columnOptions = useMemo(
    () => ({
      plotOptions: {
        bar: {
          columnWidth: '40%',
        },
      },
      colors: ['#34c38f'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2'],
        },
      },
      yaxis: {
        labels: {
          show: true,
          minWidth: 50,
        },
      },
      xaxis: {
        labels: {
          show: true,
          hideOverlappingLabels: false,
          trim: false,
          minHeight: 150,
          style: {
            whiteSpace: 'pre-line',
          },
          formatter: (label) => label.split(' '),
        },
        categories: allSafetyAwarenessNames,
      },
      responsive: [
        {
          breakpoint: 540,
          options: {
            xaxis: {
              labels: {
                show: true,
                rotate: -90,
                hideOverlappingLabels: false,
                rotateAlways: true,
                trim: false,
                minHeight: 180,
              },
            },
            yaxis: {
              labels: {
                show: true,
              },
            },
          },
        },
      ],
    }),
    [allSafetyAwarenessNames]
  );

  const isRadialSelected = useMemo(() => chartSelected === ChartNames.RADIAL, [
    chartSelected,
  ]);
  const isColumnSelected = useMemo(() => chartSelected === ChartNames.COLUMN, [
    chartSelected,
  ]);

  const reportsDependency = JSON.stringify(reports);

  const charts = useMemo(
    () =>
      reports.map((report, key) => {
        let currentChartOptions = chartOptions;

        if (reportsDependency && isFloat(report?.value)) {
          currentChartOptions = {
            ...currentChartOptions,
            plotOptions: {
              ...currentChartOptions.plotOptions,
              radialBar: {
                ...currentChartOptions.plotOptions.radialBar,
                dataLabels: {
                  ...currentChartOptions.plotOptions.radialBar.dataLabels,
                  value: {
                    ...currentChartOptions.plotOptions.radialBar.dataLabels
                      .value,
                    fontSize: '33px',
                  },
                },
              },
            },
          };
        }

        const series = [report.value];

        return (
          <Col xl="4" lg="4" md="4" key={key}>
            <div
              className={classnames('text-center', classes['cursor-pointer'])}
              id={report.popoverTarget}
            >
              {report.value !== null && (
                <>
                  <div
                    className={classnames({
                      'mt-3': isRadialSelected,
                    })}
                  >
                    <ApexChart
                      series={series}
                      type={ChartTypes[ChartNames.RADIAL]}
                      options={currentChartOptions}
                    />
                  </div>
                  <h5 className="font-size-12 mb-3">{report.name}</h5>
                </>
              )}
              {report.value === null && (
                <>
                  <div
                    className={classnames({
                      'mt-3': isRadialSelected,
                    })}
                  >
                    <ApexChart
                      series={['no data']}
                      type={ChartTypes[ChartNames.RADIAL]}
                      options={chartOptionsEmpty}
                    />
                  </div>
                  <h5 className="font-size-12 mb-3">{report.name}</h5>
                </>
              )}
            </div>
            <Popover
              target={report.popoverTarget}
              header={report.popoverHeader}
              body={report.popoverBody}
            />
          </Col>
        );
      }),
    [reportsDependency, reports, isRadialSelected]
  );

  const componentRef = useRef();

  const SafetyAwarenessBreakdownChart = forwardRef((_, ref) => (
    <div className="h-100" ref={ref}>
      <Row className="h-100">
        {isRadialSelected && charts}
        {isColumnSelected && (
          <Col className="pb-4" xl="12" lg="12" md="12">
            <ApexChart
              containerClasses={classes['column-chart-height']}
              chartClasses="mt-2"
              options={columnOptions}
              series={columnSeries}
              type={ChartTypes[ChartNames.BAR]}
            />
          </Col>
        )}
      </Row>
    </div>
  ));

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('safetyAwarenessBreakdownGraphs.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        {
          label: t('safetyAwarenessBreakdownGraphs.personalSafetyKnowledge'),
          key: 'personalSafetyKnowledge',
        },
        {
          label: t('safetyAwarenessBreakdownGraphs.situationalAwareness'),
          key: 'situationalAwareness',
        },
        {
          label: t('safetyAwarenessBreakdownGraphs.safetyConsciousness'),
          key: 'safetyConsciousness',
        },
      ],
      data: [
        {
          personalSafetyKnowledge,
          situationalAwareness,
          safetyConsciousness,
        },
      ],
      fileName: `${downloadChartFileName}.csv`,
    }),
    [
      t,
      safetyConsciousness,
      situationalAwareness,
      downloadChartFileName,
      personalSafetyKnowledge,
    ]
  );

  const canDownloadChart = useMemo(() => {
    const canDownload =
      personalSafetyKnowledge > 0 ||
      situationalAwareness > 0 ||
      safetyConsciousness > 0;

    return canDownload;
  }, [personalSafetyKnowledge, situationalAwareness, safetyConsciousness]);

  return (
    <Card className={classnames('mini-stats-wid', classes['card-height'])}>
      <CardBody className="h-100">
        <div className="d-flex flex-column flex-md-row justify-content-between mb-xl-5">
          <div className="d-flex flex-column mb-2 mb-md-0">
            <span className="font-size-18">
              {t('safetyAwarenessBreakdownGraphs.title')}
            </span>
            <span className={classnames('font-size-12', classes['opacity-75'])}>
              {t('safetyAwarenessBreakdownGraphs.subTitle')}
            </span>
          </div>
          <ul className="nav nav-pills">
            {displayOptions.map((option, key) => (
              <li className="nav-item" key={key}>
                <span
                  className={classnames('nav-link', classes['cursor-pointer'], {
                    active: chartSelected === option.chartName,
                  })}
                  onClick={() => setChartSelected(option.chartName)}
                >
                  {option.chartName}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <SafetyAwarenessBreakdownChart ref={componentRef} />
        <div className={classes['download-icon']}>
          <DownloadChartDropdown
            t={t}
            canDownloadChart={canDownloadChart}
            PNGData={PNGData}
            CSVData={CSVData}
            optionsEnabled={optionsEnabled}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(SafetyAwarenessBreakdown);
