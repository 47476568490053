import { createReducer } from 'redux-act';

import {
  REPORTS_CLEAN_UP,
  REPORTS_FETCH_FAIL,
  REPORTS_FETCH_INIT,
  REPORTS_FETCH_SUCCESS,
} from '../../actions/reports';

const initialState = {
  data: {},
  totalResponses: null,
  loading: false,
  error: null,
};

const reportsReducer = createReducer(
  {
    [REPORTS_FETCH_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [REPORTS_FETCH_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.reports,
      totalResponses: payload.totalResponses,
      loading: false,
      error: null,
    }),
    [REPORTS_FETCH_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REPORTS_CLEAN_UP]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reportsReducer;
