import { createReducer } from 'redux-act';

import {
  DASHBOARDS_CLEAR_STATE,
  DASHBOARDS_FETCH_FAIL,
  DASHBOARDS_FETCH_INIT,
  DASHBOARDS_FETCH_SUCCESS,
} from '../../actions/dashboards';

const initialState = {
  data: [],
  loading: false,
  error: null,
  fetched: false,
};

const dashboardsReducer = createReducer(
  {
    [DASHBOARDS_FETCH_INIT]: (state) => ({
      ...state,
      data: [],
      loading: true,
      fetched: false,
    }),
    [DASHBOARDS_FETCH_SUCCESS]: (state, { dashboards }) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      data: dashboards,
    }),
    [DASHBOARDS_FETCH_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      fetched: true,
      error,
    }),
    [DASHBOARDS_CLEAR_STATE]: () => initialState,
  },
  initialState
);

export default dashboardsReducer;
