import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './tooltip.module.scss';

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  isActive,
  disabled,
  getHandleProps,
  waveDates,
}) => {
  const date = waveDates.length > 0 ? waveDates[value - 1] : null;
  const dateString = date?.toDateString();

  const [mouseOver, setMouseOver] = useState(false);

  const onMouseEnter = () => {
    setMouseOver(true);
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };

  return (
    <>
      {(mouseOver || isActive) && !disabled ? (
        <div
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '-11px',
            marginTop: '-35px',
          }}
        >
          <div className={classes.tooltip}>
            <span className={classes.tooltiptext}>{dateString}</span>
          </div>
        </div>
      ) : null}
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 4,
          width: 26,
          height: 42,
          cursor: 'pointer',
          backgroundColor: 'none',
        }}
        {...getHandleProps(id, {
          onMouseEnter,
          onMouseLeave,
        })}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 3,
          width: 24,
          height: 24,
          border: 0,
          borderRadius: '50%',
          boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
          backgroundColor: disabled ? '#536de4' : '#536de4',
        }}
      />
    </>
  );
};

Handle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

Handle.defaultProps = {
  disabled: false,
};

export default Handle;
