import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const ORGANIZATIONS_FETCH_INIT = createAction(
  'ORGANIZATIONS_FETCH_INIT'
);
export const ORGANIZATIONS_FETCH_FAIL = createAction(
  'ORGANIZATIONS_FETCH_FAIL'
);
export const ORGANIZATIONS_FETCH_SUCCESS = createAction(
  'ORGANIZATIONS_FETCH_SUCCESS'
);
export const ORGANIZATIONS_CLEAN_UP = createAction('ORGANIZATIONS_CLEAN_UP');

export const fetchOrganizations = (filterInactives, filterByName = null) => {
  return async (dispatch) => {
    dispatch(ORGANIZATIONS_FETCH_INIT());

    const organizations = [];

    try {
      const baseQuery = firebase.firestore().collection('organizations');
      let queryResult;

      if (filterInactives) {
        queryResult = await baseQuery.where('active', '==', true).get();
      }
      if (filterByName) {
        const name = filterByName.trim().toLowerCase();
        queryResult = await baseQuery.where('name', '==', name).get();
      } else {
        queryResult = await baseQuery.orderBy('name').get();
      }
      queryResult.forEach((organization) => {
        const organizationData = {
          id: organization.id,
          ...organization.data(),
        };
        organizations.push({
          ...organizationData,
          createdAt: organizationData.createdAt.toDate(),
        });
      });
      organizations.sort((org1, org2) => (org1.name > org2.name ? 1 : -1));
    } catch (error) {
      return dispatch(ORGANIZATIONS_FETCH_FAIL({ error }));
    }

    return dispatch(
      ORGANIZATIONS_FETCH_SUCCESS({
        organizations,
      })
    );
  };
};

export const organizationsCleanUp = () => (dispatch) =>
  dispatch(ORGANIZATIONS_CLEAN_UP());
