import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import rootSaga from './sagas';

export const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null) || compose;

  const middlewares = [];
  middlewares.push(applyMiddleware(thunk));
  middlewares.push(applyMiddleware(sagaMiddleware));

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...middlewares)
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};
