const DASHBOARDS_FIELDS = Object.freeze({
  personalSafetyKnowledge: 'PersonalSafetyKnowledge',
  safetyConsciousness: 'SafetyConsciousness',
  situationalAwareness: 'SituationalAwareness',
  HighRisk: 'HighRisk',
  PresageIndex: 'PresageIndex',
  BelowThresholdGroup: 'BelowThresholdGroup',
  SafetyAwareness: 'SafetyAwareness',
});

export default DASHBOARDS_FIELDS;
