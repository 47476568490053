import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const GROUPS_FETCH_INIT = createAction('GROUPS_FETCH_INIT');
export const GROUPS_FETCH_FAIL = createAction('GROUPS_FETCH_FAIL');
export const GROUPS_FETCH_SUCCESS = createAction('GROUPS_FETCH_SUCCESS');
export const GROUPS_CLEAN_UP = createAction('GROUPS_CLEAN_UP');

export const fetchGroups = (filterInactives, filterByOrganization) => {
  return async (dispatch) => {
    dispatch(GROUPS_FETCH_INIT());

    const groups = [];

    try {
      let baseQuery = firebase.firestore().collection('groups');

      if (filterInactives) {
        baseQuery = baseQuery.where('active', '==', true);
      }

      if (filterByOrganization) {
        baseQuery = baseQuery.where(
          'organizationName',
          '==',
          filterByOrganization
        );
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((group) => {
        const groupData = { id: group.id, ...group.data() };
        groups.push({ ...groupData, createdAt: groupData.createdAt.toDate() });
      });
    } catch (error) {
      return dispatch(GROUPS_FETCH_FAIL({ error }));
    }

    return dispatch(
      GROUPS_FETCH_SUCCESS({
        groups,
      })
    );
  };
};

export const groupsCleanUp = () => (dispatch) => dispatch(GROUPS_CLEAN_UP());
