import React, { useEffect } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Spinner,
} from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { firebase } from '../../helpers/firebase_helper';
import { loginUser, showToast } from '../../store/actions';
import profile from '../../assets/images/presage/presage_logo2.png';
import logo from '../../assets/images/presage/presage_logo4.png';
import Paths from '../../routes/paths';
import { setPassword } from '../../store/thunk/actions/auth';
import ToastIcons from '../../helpers/enums/toastIcons';

const Login = (props) => {
  const { error, loading, authError, authLoading } = useSelector(
    (state) => ({
      error: state.Login.error,
      loading: state.Login.loading,
      authError: state.auth.error,
      authLoading: state.auth.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const isEmailLink = firebase
    .auth()
    .isSignInWithEmailLink(window.location.href);

  const handleValidSubmit = (event, values) => {
    const { from } = props.location.state || { from: { pathname: Paths.ROOT } };

    if (isEmailLink) {
      dispatch(
        setPassword(
          values.email,
          values.password,
          window.location.href,
          props.history
        )
      );
    } else {
      dispatch(loginUser(values, props.history, from));
    }
  };

  useEffect(() => {
    if (authError) {
      dispatch(showToast(ToastIcons.ERROR, props.t(`${authError.code}`)));
    }
  }, [dispatch, authError, props]);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-secondary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-secondary p-4">
                        <h5 className="text-body">{props.t('login.title')}</h5>
                        <p>
                          {isEmailLink
                            ? props.t('login.setPassword')
                            : props.t('login.signIn')}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img src={profile} style={{ width: '90%' }} alt="" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {error && error ? (
                        <Alert color="danger">{props.t(`${error}`)}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label={props.t('login.inputEmailLabel')}
                          className="form-control"
                          placeholder={props.t('login.inputEmailPlaceholder')}
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label={props.t('login.inputPasswordLabel')}
                          type="password"
                          required
                          placeholder={props.t(
                            'login.inputPasswordPlaceholder'
                          )}
                        />
                      </div>

                      {!isEmailLink && (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            {props.t('login.checkboxRememberMe')}
                          </label>
                        </div>
                      )}

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {loading || authLoading ? (
                            <Spinner
                              style={{ width: '19px', height: '19px' }}
                              className="mr-2"
                              color="light"
                            />
                          ) : isEmailLink ? (
                            props.t('login.setPasswordButton')
                          ) : (
                            props.t('login.loginButton')
                          )}
                        </button>
                      </div>

                      {!isEmailLink && (
                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1" />
                            {props.t('login.forgotPasswordLink')}
                          </Link>
                        </div>
                      )}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Presage.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(withRouter(Login));
