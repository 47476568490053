import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  forwardRef,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';
import { Table, Card, CardBody } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import { segmentationsSelector } from '../../../store/selectors';
import Popover from '../../../components/Popover';
import Popovers from '../../../helpers/enums/popovers';
import { fetchGroups } from '../../../store/actions';
import classes from './Segmentation.module.scss';
import './Segmentation.scss';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import {
  DownloadOptions,
  getDownloadChartFileName,
  TablesHeight,
} from '../../../helpers/utils';

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const Segmentation = ({ t, wave, responses }) => {
  const { segmentations } = useSelector(
    (state) => segmentationsSelector(state, { responses, wave }),
    shallowEqual
  );

  const { groups, organization } = useSelector(
    (state) => ({
      organization: state.preferences.organization,
      groups: state.groups.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      dispatch(fetchGroups(organization.displayName));
    }
  }, [dispatch, organization]);

  const [expandTable, setExpandTable] = useState(false);

  const componentRef = useRef();

  const SegmentationChart = forwardRef((_, ref) => (
    <div
      ref={ref}
      className="table-responsive"
      style={{ height: `${tableHeight}rem` }}
    >
      <Table id="segmentation-table" className="table table-nowrap mb-0">
        <thead>
          <tr>
            <th>{t('segmentationTable.group')}</th>
            <th>{t('segmentationTable.responses')}</th>
            <th>{t('segmentationTable.expected')}</th>
            <th>{t('segmentationTable.clean')}</th>
            <th>{t('segmentationTable.rate')}</th>
          </tr>
        </thead>
        <tbody>
          {segmentations.map((segmentation, index) => (
            <tr key={index}>
              <td className={classes.group}>{segmentation.group}</td>
              <td>{segmentation.responses}</td>
              <td>{segmentation.expected}</td>
              <td>{segmentation.clean}</td>
              <td>{segmentation.rate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ));

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('segmentationTable.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const generateCSVData = useMemo(
    () =>
      segmentations.map((segmentation) => ({
        group: segmentation.group,
        responses: segmentation.responses,
        expected: segmentation.expected,
        clean: segmentation.clean,
        rate: segmentation.rate,
      })),
    [segmentations]
  );

  const CSVData = useMemo(
    () => ({
      headers: [
        { label: t('segmentationTable.group'), key: 'group' },
        { label: t('segmentationTable.responses'), key: 'responses' },
        { label: t('segmentationTable.expected'), key: 'expected' },
        { label: t('segmentationTable.clean'), key: 'clean' },
        { label: t('segmentationTable.rate'), key: 'rate' },
      ],
      data: generateCSVData,
      fileName: `${downloadChartFileName}.csv`,
    }),
    [t, downloadChartFileName, generateCSVData]
  );

  const onChangeExpandTable = useCallback(() => {
    setExpandTable(!expandTable);
  }, [expandTable]);

  // 1 is counting the header of the table as an extra row
  // 3 is the amount of rem needed per row
  const tableHeight = useMemo(() => {
    const defaultHeight = TablesHeight.TABLE;
    const adjustedHeight = (segmentations.length + 1) * 3;

    if (expandTable) {
      return adjustedHeight > defaultHeight ? adjustedHeight : defaultHeight;
    }

    return defaultHeight;
  }, [expandTable, segmentations.length]);

  // 13 is the amount of px needed per row
  const bodyHeight = useMemo(() => {
    const defaultHeight = TablesHeight.CARD_BODY;
    const adjustedHeight =
      TablesHeight.CARD_BODY + (tableHeight - TablesHeight.TABLE) * 13;

    if (expandTable) {
      return adjustedHeight > defaultHeight ? adjustedHeight : defaultHeight;
    }

    return defaultHeight;
  }, [expandTable, tableHeight]);

  const tooltipText = useMemo(() => (expandTable ? 'Shrink' : 'Expand'), [
    expandTable,
  ]);

  const canDownloadChart = useMemo(() => {
    let canDownload = false;

    segmentations.forEach((segmentation) => {
      Object.keys(segmentation).forEach((key) => {
        if (
          segmentation[key] !== segmentation.group &&
          segmentation[key] !== 0 &&
          segmentation[key] !== '-'
        ) {
          canDownload = true;
        }
      });
    });

    return canDownload;
  }, [segmentations]);

  return (
    <Card>
      <CardBody style={{ height: `${bodyHeight}px` }}>
        <div
          className={classnames(
            'd-inline-flex flex-column',
            classes['cursor-help'],
            classes['segmentation-title']
          )}
          id={Popovers.SEGMENTATION}
        >
          <span className="font-size-20">{t('segmentationTable.title')}</span>
          <span className={classnames('font-size-12', classes['opacity-75'])}>
            {t('segmentationTable.subTitle')}
          </span>
        </div>
        {expandTable ? (
          <i
            className="fa fas fa-compress-alt font-size-16 ml-2 text-secondary cursor-pointer"
            data-tip={tooltipText}
            onClick={onChangeExpandTable}
          />
        ) : (
          <i
            className="fa fas fa-expand-alt font-size-16 ml-2 text-secondary cursor-pointer"
            data-tip={tooltipText}
            onClick={onChangeExpandTable}
          />
        )}
        {groups.length > 0 && <SegmentationChart ref={componentRef} />}
        {groups.length === 0 && (
          <div className={classes['empty-segmentation']}>
            There are no groups for this wave
          </div>
        )}
        <div className={classes['download-icon']}>
          <DownloadChartDropdown
            t={t}
            canDownloadChart={canDownloadChart}
            PNGData={PNGData}
            CSVData={CSVData}
            optionsEnabled={optionsEnabled}
          />
        </div>
        <ReactTooltip />
      </CardBody>
      <Popover
        target={Popovers.SEGMENTATION}
        header={t('popoverSegmentation.Header')}
        body={t('popoverSegmentation.Body')}
      />
    </Card>
  );
};

export default withNamespaces()(Segmentation);
