import React, { useState, useMemo, useRef, forwardRef, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import classnames from 'classnames';
import { Card, CardBody } from 'reactstrap';

import empty from '../../../assets/images/empty-box.svg';
import ChartTypes from '../../../helpers/enums/chartTypes';
import DownloadChartDropdown from '../../../components/DownloadChartDropdown';
import TagTypes from '../../../helpers/enums/tagTypes';
import {
  DownloadOptions,
  getDownloadChartFileName,
} from '../../../helpers/utils';
import ChartNames from '../../../helpers/enums/chartNames';
import { initialChartsOptions } from '../../../helpers/enums/chartsOptions';
import ApexChart from '../../../components/ApexChart';
import classes from './AreasOfRisks.module.scss';

const displayOptions = [
  {
    chartName: ChartNames.RADAR,
  },
  {
    chartName: ChartNames.COLUMN,
  },
  {
    chartName: ChartNames.LINE,
  },
  {
    chartName: ChartNames.BAR,
  },
];

const optionsEnabled = {
  PNG: DownloadOptions.PNG,
  CSV: DownloadOptions.CSV,
};

const AreasOfRisks = ({ t, wave }) => {
  const { tags, showAreasOfRisk } = useSelector((state) => {
    const tags = state.tags.data;
    const dashboard =
      wave && state.dashboards.data.find(({ waveId }) => waveId === wave.id);
    const group = state.preferences.group;
    const selectedDemographic = state.preferences.demographic;
    const groupId = group && group.id;

    const constructTags = tags.filter((tag) => tag.type === TagTypes.CONSTRUCT);
    const tagValues = [];

    if (dashboard) {
      const { Scores } = dashboard || {
        Scores: { Overall: { groups: [], demographics: {} } },
      };

      const {
        Overall: overall,
        Overall: { groups },
      } = Scores || { Overall: { groups: [], demographics: {} } };

      if (group) {
        const groupData = groups?.[groupId];

        const groupSelectedDemographic =
          groupData?.demographics?.[selectedDemographic?.choice];

        if (groupSelectedDemographic) {
          constructTags.forEach(({ name, displayName }) => {
            const tagValue =
              Number(groupSelectedDemographic[name]?.toFixed(1)) || null;

            tagValues.push({
              displayName,
              name,
              value: tagValue,
            });
          });
        } else {
          constructTags.forEach(({ name, displayName }) => {
            const tagValue = Number(groupData?.[name]?.toFixed(1)) || null;

            tagValues.push({
              displayName,
              name,
              value: tagValue,
            });
          });
        }
      } else {
        const overallSelectedDemographic =
          overall?.demographics?.[selectedDemographic?.choice];

        if (overallSelectedDemographic) {
          constructTags.forEach(({ name, displayName }) => {
            const tagValue =
              Number(overallSelectedDemographic?.[name]?.toFixed(1)) || null;

            tagValues.push({
              displayName,
              name,
              value: tagValue,
            });
          });
        } else {
          constructTags.forEach(({ name, displayName }) => {
            const tagValue = Number(overall?.[name]?.toFixed(1)) || null;

            tagValues.push({
              displayName,
              name,
              value: tagValue,
            });
          });
        }
      }
    }

    const data = tags.map(({ value }) =>
      Number.isInteger(value) ? value : null
    );

    const series = [{ name: 'Value', data }];

    return {
      tags: tagValues,
      showAreasOfRisk: tagValues.length > 0,
      series,
    };
  }, shallowEqual);

  const tagsDependency = JSON.stringify(tags);

  const [chartOptions, setChartOptions] = useState(
    initialChartsOptions[ChartNames.RADAR]
  );

  const [chartSelected, setChartSelected] = useState(ChartNames.RADAR);

  const isBarChartSelected = useMemo(() => chartSelected === ChartNames.BAR, [
    chartSelected,
  ]);

  const isLineChartSelected = useMemo(() => chartSelected === ChartNames.LINE, [
    chartSelected,
  ]);

  const isColumnChartSelected = useMemo(
    () => chartSelected === ChartNames.COLUMN,
    [chartSelected]
  );

  const isRadarChartSelected = useMemo(
    () => chartSelected === ChartNames.RADAR,
    [chartSelected]
  );

  const chartOptionsCategories = useMemo(() => chartOptions.xaxis.categories, [
    chartOptions.xaxis.categories,
  ]);

  const selectedChartInitialOptions = useMemo(
    () =>
      initialChartsOptions[chartSelected] ||
      initialChartsOptions[ChartNames.RADAR],
    [chartSelected]
  );

  const chartCategories = useMemo(() => {
    const categoriesToReturn = [];

    if (tags.length > 0 && tagsDependency) {
      tags.forEach(({ displayName }) => categoriesToReturn.push(displayName));
    }

    const categoriesAreDifferent =
      JSON.stringify(categoriesToReturn) !==
      JSON.stringify(chartOptionsCategories);

    if (categoriesAreDifferent && categoriesToReturn.length > 0) {
      return categoriesToReturn;
    }

    if (chartOptionsCategories.length > 0) {
      return chartOptionsCategories;
    }

    return selectedChartInitialOptions.xaxis.categories;
  }, [
    tagsDependency,
    tags,
    chartOptionsCategories,
    selectedChartInitialOptions.xaxis.categories,
  ]);

  const chartCategoriesMobile = useMemo(
    () => chartCategories.map((_, index) => index + 1),
    [chartCategories]
  );

  const categoriesList = useMemo(
    () => (
      <div className={classes['categories-mobile']}>
        {chartCategories.map((category, index) => (
          <div className={classes.category} key={`${index}-${category}`}>
            <span className={classes['category-number']}>{index + 1}</span>
            <span>{` - `}</span>
            <span>{category}</span>
          </div>
        ))}
      </div>
    ),
    [chartCategories]
  );

  useEffect(() => {
    const shouldUpdateCategories =
      JSON.stringify(chartCategories) !==
        JSON.stringify(chartOptions.xaxis.categories) &&
      chartCategories.length === chartCategoriesMobile.length;

    if (shouldUpdateCategories || selectedChartInitialOptions) {
      setChartOptions(() => {
        let finalUpdate = {
          ...selectedChartInitialOptions,
          xaxis: {
            ...selectedChartInitialOptions.xaxis,
            categories: chartCategories,
          },
        };

        const responsiveOptions = finalUpdate?.responsive;

        if (responsiveOptions) {
          responsiveOptions.forEach((responsiveOption, index) => {
            const haveXaxisCategories =
              responsiveOption.options?.xaxis?.categories;

            if (haveXaxisCategories) {
              finalUpdate.responsive[
                index
              ].options.xaxis.categories = chartCategoriesMobile;
            }
          });
        }

        return finalUpdate;
      });
    }
  }, [
    selectedChartInitialOptions,
    chartCategories,
    chartCategoriesMobile,
    chartOptions.xaxis.categories,
  ]);

  const series = useMemo(() => {
    const data = tags.map(({ value }) => value);
    return [{ name: 'Value', data }];
  }, [tags]);

  const componentRef = useRef();

  const AreasOfRisksChart = forwardRef((_, ref) => (
    <div className="h-100" ref={ref}>
      {isRadarChartSelected && (
        <div className={classnames('h-100', classes['radar-chart-mobile'])}>
          <ApexChart
            options={chartOptions}
            series={series}
            height="125%"
            type={ChartTypes[ChartNames.RADAR]}
          />
          {categoriesList}
        </div>
      )}
      {isBarChartSelected && (
        <ApexChart
          containerClasses={classes['chart-mobile']}
          chartClasses="mt-sm-4"
          options={chartOptions}
          series={series}
          type={ChartTypes[ChartNames.BAR]}
        />
      )}
      {isLineChartSelected && (
        <ApexChart
          options={chartOptions}
          series={series}
          chartClasses="mt-sm-4"
          containerClasses={classes['chart-mobile']}
          type={ChartTypes[ChartNames.LINE]}
        />
      )}
      {isColumnChartSelected && (
        <ApexChart
          containerClasses={classes['chart-mobile']}
          chartClasses="mt-sm-4"
          options={chartOptions}
          series={series}
          type={ChartTypes[ChartNames.BAR]}
        />
      )}
    </div>
  ));

  const downloadChartFileName = useMemo(
    () =>
      wave &&
      getDownloadChartFileName(
        t('areasOfRiskGraph.title'),
        wave.name,
        wave.organizationName
      ),
    [t, wave]
  );

  const PNGData = useMemo(
    () => ({
      componentRef,
      fileName: downloadChartFileName,
    }),
    [downloadChartFileName]
  );

  const CSVData = useMemo(() => {
    const headers = [];
    const dataObject = {};

    if (tagsDependency) {
      tags.forEach(({ name, displayName }, index) => {
        headers.push({ label: displayName, key: name });
        dataObject[name] = series[0].data[index];
      });
    }

    return {
      headers,
      data: [dataObject],
      fileName: `${downloadChartFileName}.csv`,
    };
  }, [downloadChartFileName, series, tagsDependency, tags]);

  const canDownloadChart = useMemo(() => {
    const canDownload = series[0].data.filter((area) => area > 0).length > 0;

    return canDownload;
  }, [series]);

  return (
    <Card
      className={classnames(
        'mini-stats-wid',
        classes['areas-of-risks-height'],
        {
          [classes['pb-6']]: !isRadarChartSelected,
        }
      )}
    >
      <CardBody className="h-100">
        <div className="clearfix" />
        <div className="d-inline-flex flex-column mb-2 mb-sm-0">
          <span className="font-size-20">{t('areasOfRiskGraph.title')}</span>
          <span className={classnames('font-size-12', classes['opacity-75'])}>
            {t('areasOfRiskGraph.subTitle')}
          </span>
        </div>
        {showAreasOfRisk && (
          <>
            <div className="float-sm-right">
              <ul className="nav nav-pills">
                {displayOptions.map(({ chartName }, key) => (
                  <li className="nav-item" key={key}>
                    <span
                      className={classnames(
                        'nav-link',
                        classes['cursor-pointer'],
                        {
                          active: chartSelected === chartName,
                        }
                      )}
                      onClick={() => setChartSelected(chartName)}
                    >
                      {chartName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <AreasOfRisksChart ref={componentRef} />
            <div className={classes['download-icon']}>
              <DownloadChartDropdown
                t={t}
                canDownloadChart={canDownloadChart}
                PNGData={PNGData}
                CSVData={CSVData}
                optionsEnabled={optionsEnabled}
              />
            </div>
          </>
        )}
        {!showAreasOfRisk && (
          <div
            className={classnames(
              'd-flex flex-column justify-content-center align-items-center mt-5 pt-5 h-100',
              classes['opacity-50']
            )}
          >
            <img
              src={empty}
              alt="empty heatmap"
              height="100"
              className={classes['opacity-75']}
            />
            <span className="font-size-18 mt-2">
              {t('areasOfRiskGraph.noTags')}
              <i className="mdi mdi-emoticon-sad-outline ml-1 font-size-20" />
            </span>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(AreasOfRisks);
