const ChartsIds = {
  FILTER_RESPONSES_BY_DATE: 'filter-responses-by-date',
  RESPONSE_RATE: 'response-rate',
  SURVEY_RESPONSES: 'survey-responses',
  REPORTS: 'reports',
  DEMOGRAPHICS_BREAKDOWN: 'demographics-breakdown',
  SEGMENTATION: 'segmentation',
  SAFETY_AWARENESS_BREAKDOWN: 'safety-awareness-breakdown',
  SAFETY_AWARENESS: 'safety-awareness',
  BELOW_THRESHOLD_GROUP: 'below-threshold-group',
  AREAS_OF_RISKS: 'areas-of-risks',
};

export default ChartsIds;
