import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

const ApexChart = ({
  series,
  options,
  containerClasses,
  chartClasses,
  height,
  type,
}) => (
  <div className={containerClasses || 'h-100'}>
    <ReactApexChart
      className={chartClasses}
      options={options}
      series={series}
      type={type}
      height={height || '100%'}
    />
  </div>
);

ApexChart.propTypes = {
  series: PropTypes.instanceOf(Array).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
  chartClasses: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ApexChart;
