import { createAction } from 'redux-act';
import { firebase } from '../../../helpers/firebase_helper';

export const WAVES_FETCH_INIT = createAction('WAVES_FETCH_INIT');
export const WAVES_FETCH_FAIL = createAction('WAVES_FETCH_FAIL');
export const WAVES_FETCH_SUCCESS = createAction('WAVES_FETCH_SUCCESS');
export const WAVES_CLEAN_UP = createAction('WAVES_CLEAN_UP');

const addWaveToArray = (array, wave) => {
  const waveData = { id: wave.id, ...wave.data() };
  array.push({
    ...waveData,
    name: waveData.name || '',
    createdAt: waveData.createdAt.toDate(),
    startingOn: waveData.startingOn.toDate(),
    until: waveData.until ? waveData.until.toDate() : null,
    organization: {
      label: waveData.organizationName,
      value: {
        displayName: waveData.organizationName,
        id: waveData.organizationId,
      },
    },
    expectedResponses:
      waveData.expectedResponses !== null ? waveData.expectedResponses : '',
  });
};

export const fetchWaves = (filterByOrganization, id = null) => {
  return async (dispatch) => {
    dispatch(WAVES_FETCH_INIT());

    const waves = [];

    try {
      let baseQuery = firebase.firestore().collection('waves');
      if (id) {
        const wave = await baseQuery.doc(id).get();
        if (!wave.exists) {
          return dispatch(
            WAVES_FETCH_FAIL({ error: 'Wave id does not exist!' })
          );
        }
        addWaveToArray(waves, wave);
      } else {
        if (filterByOrganization) {
          baseQuery = baseQuery.where(
            'organizationId',
            '==',
            filterByOrganization
          );
        }
        const allWaves = await baseQuery.get();
        allWaves.forEach((wave) => {
          addWaveToArray(waves, wave);
        });
      }
      waves.sort((wave1, wave2) => (wave1.index > wave2.index ? 1 : -1));
    } catch (error) {
      return dispatch(WAVES_FETCH_FAIL({ error }));
    }

    return dispatch(
      WAVES_FETCH_SUCCESS({
        waves,
      })
    );
  };
};

export const wavesCleanUp = () => (dispatch) => dispatch(WAVES_CLEAN_UP());
