import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Container, Spinner } from 'reactstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classNames from 'classnames';
import ReactApexChart from 'react-apexcharts';

import empty from '../../assets/images/empty-box.svg';
import { fetchTags, tagsCleanUp } from '../../store/thunk/actions/tags';
import {
  fetchDashboards,
  fetchGroups,
  fetchWaves,
  setWave,
} from '../../store/actions';
import ChartTypes from '../../helpers/enums/chartTypes';
import TagType from '../../helpers/enums/tagTypes';
import { sortGroupsArrayAlphanumeric } from '../../helpers/utils';
import initialChartsOptions from '../../helpers/enums/initialChartsOptions';
import ChartNames from '../../helpers/enums/chartNames';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import classes from './Heatmap.module.scss';
import './Heatmap.scss';

const Heatmap = (props) => {
  const dispatch = useDispatch();

  const {
    groups,
    organization,
    wave,
    waves,
    loadingTags,
    loadingGroups,
    emptyGroups,
    emptyTags,
    emptyWaves,
    fetchedDashboardData,
    loadingDashboardData,
    emptyDashboard,
  } = useSelector((state) => {
    const wave = state.preferences.wave;
    const tags = state.tags.data;
    const groupsData = state.groups.data;
    const dashboard =
      wave && state.dashboards.data.find(({ waveId }) => waveId === wave.id);

    const returnedGroupsData = [];
    if (dashboard) {
      const { Scores } = dashboard || { Group: { Overall: { groups: {} } } };
      const {
        Overall: overall,
        Overall: { groups },
      } = Scores || { Overall: { groups: {} } };

      if (overall) {
        const tagValues = [];

        tags.forEach(({ name, displayName }) => {
          const tagValue = Number(overall?.[name]?.toFixed(1)) || null;

          tagValues.push({
            displayName,
            name,
            value: tagValue,
          });
        });

        returnedGroupsData.push({
          displayName: 'Overall',
          name: 'overall',
          tagValues,
        });

        if (groups) {
          groupsData.forEach((group) => {
            const { id: groupId, displayName: groupDisplayName } = group;
            const tagValues = [];

            tags.forEach(({ name, displayName }) => {
              const tagValue =
                groups[groupId]?.[name] >= 0
                  ? Number(groups[groupId]?.[name]?.toFixed(1))
                  : null;

              tagValues.push({
                displayName,
                name,
                value: tagValue,
              });
            });
            returnedGroupsData.push({
              displayName: groupDisplayName,
              id: groupId,
              tagValues,
            });
          });
        }
      }
    }

    return {
      wave,
      tags,
      fetchedDashboardData: state.dashboards.fetched,
      loadingDashboardData: state.dashboards.loading,
      groups: returnedGroupsData || [],
      organization: state.preferences.organization,
      waves: state.waves.data,
      loadingTags: state.tags.loading,
      loadingGroups: state.groups.loading,
      emptyGroups: returnedGroupsData.length === 0,
      emptyTags: state.tags.data.length === 0,
      emptyWaves: state.waves.data.length === 0,
      emptyDashboard: !dashboard,
    };
  }, shallowEqual);

  const [options, setOptions] = useState(
    initialChartsOptions[ChartNames.HEATMAP]
  );

  useEffect(() => {
    if (organization) {
      dispatch(tagsCleanUp());
      dispatch(
        fetchTags({
          filterByActive: true,
          filterByType: TagType.CONSTRUCT,
          filterByOrganizations: [organization.displayName, 'global'],
        })
      );
    }
  }, [dispatch, organization]);

  useEffect(() => {
    if (organization) {
      dispatch(fetchWaves(organization.id));
    }
  }, [dispatch, organization]);

  useEffect(() => {
    if (organization) {
      const filterInactives = true;
      dispatch(fetchGroups(filterInactives, organization.displayName));
    }
  }, [dispatch, organization]);

  useEffect(() => {
    if (organization && !emptyWaves && !wave) {
      const currentWave = waves.filter(
        (wave) => wave.index === organization.currentWave
      );
      dispatch(setWave(currentWave[0]));
    }
  }, [dispatch, wave, waves, organization, emptyWaves]);

  useEffect(() => {
    if (wave?.id) {
      dispatch(fetchDashboards([wave.id]));
    }
  }, [dispatch, wave]);

  const onViewDetailsHandler = useCallback(
    () =>
      setOptions((prevState) => ({
        ...prevState,
        dataLabels: {
          ...prevState.dataLabels,
          showDetails: !prevState.dataLabels.showDetails,
        },
      })),
    []
  );

  const series = useMemo(() => {
    const chartSeries = [];

    if (emptyGroups) {
      return chartSeries;
    }

    groups.forEach(({ displayName, tagValues }) => {
      const data = [];

      tagValues.forEach(({ displayName: tagDisplayName, value }) => {
        data.push({
          x: tagDisplayName,
          y: value,
        });
      });

      chartSeries.push({
        name: displayName,
        data,
      });
    });

    const indexOfOverall = chartSeries.findIndex(
      (serie) => serie?.name === 'Overall'
    );

    const copyOfOverall = chartSeries.slice(
      indexOfOverall,
      indexOfOverall + 1
    )[0];

    chartSeries.splice(indexOfOverall, 1);

    const sortedSeries = chartSeries.sort(sortGroupsArrayAlphanumeric);

    sortedSeries.splice(0, 0, copyOfOverall);

    return sortedSeries;
  }, [groups, emptyGroups]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t('heatmap.breadcrumbTitle')}
          displayGroupSelected={false}
        />
        {(loadingGroups || loadingTags || loadingDashboardData) && (
          <div className="d-flex justify-content-center mt-5 pt-5">
            <Spinner
              style={{ width: '40px', height: '40px' }}
              color="primary"
            />
          </div>
        )}
        {!loadingGroups &&
          !loadingTags &&
          !loadingDashboardData &&
          (emptyGroups || emptyTags || emptyDashboard) && (
            <div
              className={classNames(
                'd-flex flex-column justify-content-center align-items-center mt-5 pt-5',
                classes['opacity-50']
              )}
            >
              {(emptyGroups || emptyTags) && (
                <>
                  <img
                    src={empty}
                    alt="empty heatmap"
                    height="100"
                    className={classes['opacity-75']}
                  />
                  <span className="font-size-18 mt-2">
                    {!emptyDashboard && props.t('heatmap.EmptyGroupsOrTags')}
                    {emptyDashboard && props.t('heatmap.NoDashboardInWave')}
                    <i className="mdi mdi-emoticon-sad-outline ml-1 font-size-20" />
                  </span>
                </>
              )}
            </div>
          )}
        {series.length > 0 && fetchedDashboardData && (
          <>
            <div className={classes['heatmap-information']}>
              <div
                className={classNames(
                  'd-flex align-items-center',
                  classes['cursor-pointer']
                )}
                onClick={onViewDetailsHandler}
              >
                <i
                  className={classNames(
                    'fas fa-info-circle',
                    classes['details-icon'],
                    {
                      [classes['info-active']]: options.dataLabels.showDetails,
                      [classes['info-inactive']]: !options.dataLabels
                        .showDetails,
                    }
                  )}
                />
                <span className={classes['show-details']}>
                  {props.t('heatmap.showDetails')}
                </span>
              </div>
              <div className={classes['awareness-container']}>
                <span
                  className={classNames('badge', classes['lower-awareness'])}
                >
                  {props.t('heatmap.lowerAwareness')}
                </span>
                <span className="mx-1">-</span>
                <span
                  className={classNames('badge', classes['higher-awareness'])}
                >
                  {props.t('heatmap.higherAwareness')}
                </span>
              </div>
            </div>
            <div className={classes['chart-wrapper']}>
              <ReactApexChart
                options={options}
                series={series}
                type={ChartTypes[ChartNames.HEATMAP]}
                height="100%"
              />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default withNamespaces()(Heatmap);
